import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, ResourceList, ResourceItem, Avatar, TextStyle } from '@shopify/polaris';

const propTypes = {
  items: PropTypes.array.isRequired,
}

const CouriersList = (props) => {
  return (
    <ResourceList
      resourceName={{ singular: I18n.t("activerecord.courier.one"), plural: I18n.t("activerecord.courier.other") }}
      items={ props.items }
      renderItem={(item) => {
        const { id, uid, user, editURL } = item;
        const media = <Avatar customer size="medium" name={user.name} />;

        return (
          <ResourceItem
            id={id}
            url={editURL}
            external
            media={media}
            accessibilityLabel={`View details for ${'user'}`}
            onClick={ () => props.onClick(item) }
          >
            <h3>
              <TextStyle variation="strong">{user.name}</TextStyle>
            </h3>
          </ResourceItem>
        );
      }}
    />
  );
}


CouriersList.propTypes = propTypes;

export default CouriersList;
