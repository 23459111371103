import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, DescriptionList, Badge, Layout } from '@shopify/polaris';

import { ta } from '@utils/index';
import { valueToBadgeStatus } from '@utils/columns';

const propTypes = {
  record: PropTypes.object.isRequired,
  breadcrumbs: PropTypes.array.isRequired,
}

const defaultProps = {

}

const ResourceTransferShow = (props) => {
  console.log("props", props);
  const record = props.record;

  return (
    <AppProvider>
      <Page 
        title={ I18n.t("activerecord.models.resource_transfer", { count: 1 }) }
        subtitle={(
          <Badge status={ valueToBadgeStatus[record.status] } >
            {I18n.t(`enum.status.${record.status}`)}
          </Badge>
        )}
        breadcrumbs={ props.breadcrumbs }
      >
        <Layout>
          <Layout.Section>
            <Card title="Información General" sectioned>
              <DescriptionList
                items={[
                  { term: ta("general.type"), description: I18n.t(`enum.polymorphic_type.${record.resource_type}`) },
                  { term: ta("resource_transfer.transfer_type"), description: I18n.t(`enum.transfer_type.${record.transfer_type}`) },
                  { term: ta("resource_transfer.created_at"), description: I18n.l("time.formats.date_only", record.created_at) },
                  { term: ta("resource_transfer.requested_by"), description: record.requested_by.name },
                  { term: ta("resource_transfer.resolved_by"), description: record.resolved_by && record.resolved_by.name },
                  { term: ta("general.notes"), description: record.notes },
                ]}
              />
            </Card>
          </Layout.Section>

          <Layout.Section secondary>
            <Card title="Información Orden" sectioned>
              <DescriptionList
                items={[
                  { term: ta("general.id"), description: record.resource.provider_id },
                  { term: ta("dispatch_order.order_number"), description: record.resource.order_number },
                  { term: I18n.t("activerecord.models.team", { count: 1 }), description: record.team_name },
                ]}
              />
            </Card>
          </Layout.Section>
        </Layout>
      </Page>
    </AppProvider>
  )
};

ResourceTransferShow.propTypes = propTypes;
ResourceTransferShow.defaultProps = defaultProps;

export default ResourceTransferShow;