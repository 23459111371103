import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@shopify/polaris';
import { capitalize } from 'lodash';

const propTypes = {
  handleChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,

  values: PropTypes.object.isRequired,
  errors: PropTypes.object,

  label: PropTypes.string,
  type: PropTypes.string,
};

const defaultProps = {
  type: "text"
}


const BasicTextField = ({ name, label, type, handleChange, value,  values, errors, placeholder, ...otherProps }) => (
  <TextField
    id={ name }
    name={ name }
    label={ label || capitalize(name) }
    type={ type }
    placeholder={ placeholder || I18n.t(`form.placeholder.${name}`) }

    onChange={handleChange}
    value={ value || values[name]}
    error={ errors && errors[name]}

    {...otherProps}
  />
);


BasicTextField.propTypes = propTypes;
BasicTextField.defaultProps = defaultProps;


export default BasicTextField;
