import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Button } from '@shopify/polaris';

const propTypes = {
  invoker: PropTypes.string.isRequired,
}

const Doppel = (props) => {
  const shapes = ["Wonki", "Ronko", "Smaq", ":NXl"]
  let [shapeIndex, setShape] = useState(0);

  const onChange = () => {
    setShape = (shapeIndex + 1) >= shapes.length ? setShape(0) : setShape(shapeIndex + 1)
  }

  return(
    <div>
      <p>
        { props.invoker } invoked { shapes[shapeIndex] }
      </p>

      <button onClick={ onChange }>
        Change
      </button>

    </div>
  )
}



Doppel.propTypes = propTypes;


export default Doppel;