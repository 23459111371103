import React from 'react';

import { teamColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';

const TeamsIndex = (props) => {
  console.log("T", props)
  const primaryAction = props.newUrl ? { content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.team.one") }), url: props.newUrl } : null
  return(
    <FilterTable
      pageProps={{
        title: I18n.t("activerecord.models.team.other"),
        primaryAction: primaryAction
      }}
      columnsInfo={ teamColumns }
      {...props}
    />
  )
}

export default withPagination(TeamsIndex, {
  mapPropsToInitialFilters: () => {},
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
})