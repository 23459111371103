import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Tooltip, Link, Icon, TextStyle, DescriptionList, Card } from '@shopify/polaris';
import { QuestionMarkMinor} from '@shopify/polaris-icons';

const propTypes = {
  package: PropTypes.shape({ // one order can have many packages
    name: PropTypes.string.isRequired,
    steps: PropTypes.arrayOf(PropTypes.shape({ // timeline
      title: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      date: PropTypes.string,
      date_human: PropTypes.string,
    })),

    
    delivery_order: PropTypes.shape({ // Deprecated. Change to direct values instead of deliery_order.xxx
      trackingNumber: PropTypes.string,
      recipientName: PropTypes.string,
      // courier
    }),
    courier_name: PropTypes.string,

    tracking_number: PropTypes.string, // if not from delivery order
    tracking_number_label: PropTypes.string,
    team_name: PropTypes.string,
    only_dispatch: PropTypes.bool,
    extra: PropTypes.string, // html mostly
    dispatch_control_name: PropTypes.string,
    recipient_subject: PropTypes.string, // enum
  }).isRequired,
  
  // Order, shared among packages/timelines
  order: PropTypes.shape({
    id: PropTypes.number,
    cancelled: PropTypes.bool,
    issue: PropTypes.object, // If there is a global issue with the order not package itself
    order_logs: PropTypes.array,
    delivery_estimated: PropTypes.string, // ISO8601
    delivery_estimated_display: PropTypes.string, // human readable
    delivery_estimated_message: PropTypes.string, // title for the estimated delivery, different in certain cases
    delivery_detail_pickup_code: PropTypes.string,

    // delivery_limit_date: PropTypes.string, // ISO8601
    delivery_limit_date_human: PropTypes.string, // human readable
    delivery_limit_date_reached: PropTypes.bool, // If delivery limit has been reached
  }).isRequired,
};

const defaultProps = {
}

// ONE timeline (from potential many)
const TimelinePackage = (props) => {
  const { package: orderPackage, order } = props;
  console.log("Timeline props", props);

  const buildDescriptionListData = () => {
    const canDisplayTrackingNumber = (orderPackage.only_dispatch && !orderPackage.extra) || !orderPackage.only_dispatch; // Special case. On only dispatches, tracking number is displayed on extra field directly, for all other cases display if it exists
    let items = [];

    if(!!orderPackage.team_name){ // Team name
      items = [ ...items, { term: I18n.t("activerecord.models.team.one"), description: orderPackage.team_name } ]
    }
    if(canDisplayTrackingNumber && !!orderPackage.tracking_number){ // Tracking number
      items = [ ...items, { term: orderPackage.tracking_number_label, description: orderPackage.tracking_number } ]
    }

    if(!!orderPackage.extra){ // Special HTML elements - For only_dispatch this contains the tracking number value
      items = [ ...items, { term: "Extra", description: <div dangerouslySetInnerHTML={{ __html: orderPackage.extra}} /> } ]
    }

    if(orderPackage.dispatch_control_name){ // Dispatch control (name)
      items = [ ...items, { term: "Control de Despacho", description: orderPackage.dispatch_control_name } ]
    }

    if(!!orderPackage.courier_name){ // Courier name
      items = [ ...items, { term: "Mensajero", description: orderPackage.courier_name } ]
    }

    // When delivery_order exists
    if(orderPackage.delivery_order &&  orderPackage.delivery_order.id){ // Recipient/Tracking
      const hasRecipientName = !!orderPackage.delivery_order.recipient_name; // When recipient is other than the customer directly
      let recipientNameDisplay = `${I18n.t(`enum.recipient_subject.${orderPackage.recipient_subject}.display`)}`;
      if(hasRecipientName){
        recipientNameDisplay = `${recipientNameDisplay} (${orderPackage.delivery_order.recipient_name})`;
      }
      items = [ ...items, { term: "Recibió", description: recipientNameDisplay } ];

      if(!!orderPackage.delivery_order.tracking_number){ // Tracking
        items = [ ...items, { term: "Guía", description: orderPackage.delivery_order.tracking_number } ]
      }
    }

    if(!!order.delivery_estimated){ // ETA hard value
      items = [ ...items, { term: "Entrega estimada exacta", description: I18n.l(`time.formats.app_datetime`, order.delivery_estimated) } ]
    }

    if(!!order.delivery_detail_pickup_code){ // Pickup code
      items = [ ...items, { term: I18n.t("activerecord.attributes.delivery_detail.pickup_code"), description: order.delivery_detail_pickup_code } ]
    }

    return items;
  };

  const deliveryInformationItems = buildDescriptionListData();

  return(
    <React.Fragment>
      <Card sectioned>
        <div className='timeline-package-wrapper'>
          {/* Basic ETA display info */}
          <div className="timeline-package-info mb32">
            { orderPackage.name &&
              <p>
                <TextStyle variation='strong'>
                  { orderPackage.name }
                </TextStyle>
              </p>
            }
            { !!order.delivery_estimated &&
              <div>
                <p>
                  <TextStyle variation='strong'>{ order.delivery_estimated_message }</TextStyle>
                </p>
                <p className='flex'>
                  <span className="mr4">
                    <TextStyle variation="positive">
                      <strong>{ order.delivery_estimated_display }</strong>
                    </TextStyle>
                  </span>
                  <Tooltip content={ "La entrega estimada es una predicción basada en cientos de órdenes similares a la tuya, sin embargo, no es una garantía de entrega." }>
                    <Icon source={ QuestionMarkMinor } />
                  </Tooltip>
                </p>
              </div>
            }
            { !!order.delivery_limit_date_human &&
              <TextStyle variation='subdued'>{ !order.delivery_limit_date_reached ?
                `Entrega máxima ${order.delivery_limit_date_human}` :
                "Perdón, esta orden se atraso, para un mejor estimado llamar al 4100-7616" }
              </TextStyle>
            }
          </div>
          {/* TIMELINE MAIN COMPONENT */}
          <div className={`orderTimeline flex alignItemsStart mt30 ${props?.order.cancelled ? 'cancelled' : ''}`}>
            { orderPackage.steps.map( (status, index) => (
              <Status
                key={index}
                first={ index == 0 }
                status={ status }
                displayWarningHint={ !order.issue }
              />
            ))}
          </div>
        </div>
      </Card>

      { deliveryInformationItems.length > 0 &&
        <Card title="Admin info" sectioned>
          <DescriptionList items={deliveryInformationItems} />
        </Card>
      }

    </React.Fragment>
  )
};


// Display tooltip if status is completed with a warning but there is not a orderIssue (since orderIssue does have general information that replaces small tooltip)
const Status = ({ status, ...props }) => (
  <div className={`dot-container flex alignItemsCenter ${status.completed && status.warning ? "warning": status.completed && "active"}`}>
    { !props.first &&
      <div className={`dot-line`}></div>
    }
    <div className={`dot`}></div>
    <p className="dot-text">
      { status.completed && status.warning && props.displayWarningHint ?
          <React.Fragment>
            {status.title}
            <Tooltip content={ I18n.t("shopify.order.timeline.hints.warning_step")} active={false} preferredPosition="below">
              <Link>
                <Icon
                  source={QuestionMarkMinor}
                />
              </Link>
            </Tooltip>
          </React.Fragment>
         :  status.title
      }

      { status.notes &&
        <p className='mb0'>
          <TextStyle variation="negative">
            { status.notes }
          </TextStyle>
        </p>
      }

      <span className='mb0 date-text display-block'>
        { !!status.date_human && status.date_human }
      </span>
    </p>
  </div>
);

TimelinePackage.propTypes = propTypes;
TimelinePackage.defaultProps = defaultProps;

export default TimelinePackage;