import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Card, DescriptionList, Link } from '@shopify/polaris';
import { polarisBuildListItems } from 'utils/index';

import snakeCase from 'lodash/snakeCase';

import CustomerTicketAttachments from './CustomerTicketAttachments';

const propTypes = {
  customerTicket: PropTypes.object.isRequired,
}

const defaultProps = {

}

const CustomerDataSection = (props) => {
  const { customerTicket } = props;

  const customerDataItems = polarisBuildListItems([
    "customerKind",
    { name: "providerName", accessor: (value) => I18n.t(`enum.provider_name.${snakeCase(value)}`) },
    "customerName",
    "customerEmail",
  ], customerTicket);

  const ticketDataItems = polarisBuildListItems([
    { name: "providerOrderId", accessor: (_value, record) => <Link url={record.providerOrderUrl} external>{ record.displayProviderOrderId }</Link> },
    "productTitle",
    "productSku",
    { name: "productOriginalTotal", accessor: (_value, record) => record.moneyProductOriginalTotal },
    { name: "ticketKind", accessor: (value) => I18n.t(`enum.ticket_kind.${snakeCase(value)}`) },
    { name: "reason", accessor: (value) => I18n.t(`enum.reason.${snakeCase(value)}`) },
    "description",
    { name: "resolutionPreference", accessor: (value) => I18n.t(`enum.resolution_preference.${snakeCase(value)}`) },
  ], customerTicket);

  const addressDataItems = polarisBuildListItems([
    { name: "retrievalMethod", accessor: (value) => I18n.t(`enum.retrieval_method.${snakeCase(value)}`) },
    { name: "retrievalDate", accessor: (value) => value && I18n.l("time.formats.date_only", value) },
    "address",
    { name: "retrievalNotes", accessor: (value) => value, hideIfEmpty: true },
  ], customerTicket);

  return (
    <>
      <Card.Section title="Customer Data">
        <DescriptionList
          items={customerDataItems}
        />
      </Card.Section>
      
      <Card.Section title="Ticket Data">
        <DescriptionList
          items={ticketDataItems}
        />
      </Card.Section>
      
      <Card.Section title="Address Data">
        <DescriptionList
          items={addressDataItems}
        />
      </Card.Section>

      { customerTicket.media.length > 0 &&
        <Card.Section title="Attachments">
          <CustomerTicketAttachments
            attachments={customerTicket.media}
          />
        </Card.Section>
      }
    </>
  )
};

CustomerDataSection.propTypes = propTypes;
CustomerDataSection.defaultProps = defaultProps;

export default CustomerDataSection;