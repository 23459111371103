import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // Date short or Hour
    count: PropTypes.number.isRequired,
  })),
}

const defaultProps = {

}

const AppBarChart = (props) => {
  return (
    <ResponsiveContainer width="100%" height={250}>
      <BarChart
        data={props.data}
        margin={{
          top: 12,
          right: 24,
          left: 24,
          bottom: 12
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" name="a" />
        <YAxis name="delivered" allowDecimals={false} />
        <Tooltip />
        <Legend />
        <Bar name="Ordenes entregadas" dataKey="count" fill="#7045FF" />
      </BarChart>
    </ResponsiveContainer>
  )
};

AppBarChart.propTypes = propTypes;
AppBarChart.defaultProps = defaultProps;

export default AppBarChart;