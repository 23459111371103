import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Select, FormLayout, TextStyle } from '@shopify/polaris';

import { ta } from 'utils';
import { selectOptionsProp, polarisFormProps } from 'utils/shapes';
import axios from 'utils/axios-rails';
import BasicTextField from '@shared/BasicTextField';
import DatePicker from '@shared/AppDatePicker';

const propTypes = {
  initialCouriers: selectOptionsProp.isRequired,
  queryCouriersURL: PropTypes.string.isRequired,

  teams: selectOptionsProp.isRequired,
  disabled: PropTypes.bool,

  // values: PropTypes.shape({
  //   team_id: PropTypes.number,
  //   courier_id: PropTypes.number,
  //   estimated_at: PropTypes.string,
  // }).isRequired,

  polarisFormProps: polarisFormProps.isRequired
}

const defaultProps = {
  disabled: false
}

const TaskDeliveryDetails = (props) => {
  // const [loading, setLoading] = useState(false);
  const [couriers, setCouriers] = useState(props.initialCouriers);
  
  const { teams, disabled, polarisFormProps } = props;
  const { values } = polarisFormProps;

  const handleTeamChange = async (val, name) => {
    // setLoading(true)
    polarisFormProps.handleChangeNumber(val, name)
    try {
      const response = await axios.post(props.queryCouriersURL, { query: { team_id: val } })
      const items = response.data.items;
      polarisFormProps.handleChange("", "courier_id");
      setCouriers(items)
    } catch (error) {
      
    }
    // setLoading(false)
  };

  return (
    <FormLayout>
        <FormLayout.Group>
          <Select
            id="team_id"
            disabled={ disabled }
            label={ta("general.team")}
            options={ teams}
            value={values.team_id}
            {...polarisFormProps}
            onChange={ handleTeamChange }
          />
  
          <Select
            id="courier_id"
            disabled={ disabled || couriers.length == 0 }
            label={ta("general.courier")}
            options={ couriers}
            value={values.courier_id}
            {...polarisFormProps}
            onChange={polarisFormProps.handleChangeNumber}
          />
        </FormLayout.Group>
  
        <BasicTextField
          name="notes"
          disabled={ disabled }
          label={ta("general.notes")}
          {...polarisFormProps}
        />

        <TextStyle>{ ta("task.estimated_at") }</TextStyle>

        <DatePicker
          initialDateStart={ values.estimated_at }
          initialDateEnd={ values.estimated_at }
          onChange={ (dates) => polarisFormProps.handleChange(dates.start.toISOString(), "estimated_at") }
        />

      </FormLayout>
  )
};

TaskDeliveryDetails.propTypes = propTypes;
TaskDeliveryDetails.defaultProps = defaultProps;

export default TaskDeliveryDetails;