import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { orderProviderDataShape } from 'utils/shapes';

const propTypes = {
  providerData: orderProviderDataShape,
}

const defaultProps = {

}

const OrderItemAddress = (props) => {
  console.log("XX", props);
  const { shipping_address, shipping_method, financial_status } = props.providerData;
  
  if(!shipping_address) return null;
  
  const { name, canton } = shipping_address;
  return (
    <div className="ml4">
      <p className="ml4">
        { name }
        <span>- { canton }</span>
      </p>
      <p className="ml4">
        { financial_status ? I18n.t(`order.financial_status.${financial_status}`) : "" } • { shipping_method }
      </p>
    </div>
  )
};

OrderItemAddress.propTypes = propTypes;
OrderItemAddress.defaultProps = defaultProps;

export default OrderItemAddress;