import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import {DataTable, Card, Page, AppProvider, TextStyle} from '@shopify/polaris';
import {calculateDays} from "utils";

const propTypes = {
  headings: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  dateFilter: PropTypes.shape({
    range: PropTypes.string.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
  }).isRequired
}

const DashboardTeamIndex=(props) => {
  console.log("P",props);

  const subtitle = props.dateFilter.range != "CUSTOM_RANGE" ?  I18n.t(`date.date_filter.${props.dateFilter.range}`) : calculateDays( props.dateFilter.start, props.dateFilter.end);
  const columnContentTypes = props.headings.map(() => 'text');

  return(
      <AppProvider>
        <Page
         {...props.page}
         subtitle={ subtitle }
        >
          <Card>
            <div id="team_data">
              <DataTable
                columnContentTypes={columnContentTypes}
                headings={props.headings}
                rows={props.records}
              />
            </div>

          </Card>
        </Page>
      </AppProvider>
  )

}
DashboardTeamIndex.propTypes = propTypes

export default DashboardTeamIndex;
