import React from 'react';
import PropTypes from 'prop-types';

import { dispatchControlColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';

const propTypes = {
  items: PropTypes.array.isRequired,
  newUrl: PropTypes.string,
}

const DispatchControlIndex = (props) => {
  const primaryAction = props.newUrl ? { content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.dispatch_control.one") }), url: props.newUrl } : null
  return (
    <FilterTable
      pageProps={{
        title: I18n.t("activerecord.models.dispatch_control.other"),
        primaryAction: primaryAction,
      }}
      searchName="search_query"
      // searchPlaceholder="ID de Orden"
      searchPlaceholder={`# de Control de Despacho, ID de Orden, Número de GON`}
      columnsInfo={dispatchControlColumns}
      teams={props.teams}
      {...props}
    />
  )
}

export default withPagination(DispatchControlIndex, {
  mapPropsToInitialFilters: () => ({
    search_query: "",
    team_id: "",

  }),
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "dispatch_control_filters",
  filterURL: "/admin/dispatch_controls/filter",
});