import { Filters } from '@shopify/polaris';
import esTranslations from '@shopify/polaris/locales/es.json';
const newTranslation = {
  Polaris: {
    ...esTranslations.Polaris,
    "Filters": {
      ...esTranslations.Polaris.Filters,
      "moreFilters": "Filtrar",
    }
  }
}

const json = JSON.stringify(newTranslation)

export default newTranslation;
