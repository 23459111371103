import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, ResourceList, ResourceItem, Avatar, TextStyle, Collapsible } from '@shopify/polaris';
import TaskItem from './TaskItem';

const propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool, // add skeleton
  admin: PropTypes.bool, // To send to OrderItem
  itemProps: PropTypes.shape({ 
    ...TaskItem.propTypes,
    task: PropTypes.object, // no required as it is build in on this component
  }),

  // filterControl
}

const defaultProps = {
  loading: false,
  itemProps: {},
}

const TaskResourceList = (props) => {
  return (
    <ResourceList
      loading={props.loading}
      resourceName={{ singular: I18n.t("activerecord.models.task.one"), plural: I18n.t("activerecord.models.task.other") }}
      items={props.items}
      renderItem={(item) => (
        <TaskItem
          task={ item }
          { ...props.itemProps }
        />
      )}
      filterControl={ props.filterControl }
    />
  );
}


TaskResourceList.propTypes = propTypes;
TaskResourceList.defaultProps = defaultProps;

export default TaskResourceList;
