import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Form, FormLayout, Select } from '@shopify/polaris';

import TaskNewForm from './components/TaskForm';
import TaskUpdateForm from './components/TaskUpdateForm';

import PolarisFormProvider from '@shared/PolarisFormProvider';
import FormPageActions from '@shared/FormPageActions';
import ResourceLogList from "components/views/orders/components/ResourceLogList";

import { taskCatalogs } from 'utils/shapes';

import merge from 'lodash/merge'

const propTypes = {
  task: PropTypes.object,
  queryBrightPearlURL: PropTypes.string.isRequired,
  queryCouriersURL: PropTypes.string.isRequired,

  catalogs: taskCatalogs.isRequired,
  update: PropTypes.bool,
  couriers: PropTypes.array.isRequired,

  readOnly: PropTypes.bool,
}

const defaultProps = {
  update: false,
  readOnly: false
}

const defaultValues = {
  team_id: "",
  courier_id: "",
  address: "",
  notes: "",
  customer_task: { order_id: "", item_ids: [] },
}

const TaskFormContainer = (props) => {

  const initialValues = useMemo(() => {
    return props.update ? merge(defaultValues, props.task) :  merge(props.task, defaultValues);
  }, []);

  const submitMethod = props.update ? "PUT" : "POST";

  const formProps = {
    readOnly: props.readOnly,
    queryBrightPearlURL: props.queryBrightPearlURL,
    queryCouriersURL: props.queryCouriersURL,
    initialCouriers: props.couriers,

    catalogs: props.catalogs ,
  };

  console.log("props", props);

  return (
    <PolarisFormProvider
      title={I18n.t("activerecord.models.task.one")}
      defaultValues={ initialValues }
      submitURL={ props.submitURL }
      submitMethod={ submitMethod }
      paramsName="task"
      pageProps={props.page}
    >
      {(renderProps) =>
        <div>
          <Form onSubmit={renderProps.handleSubmit}>
            { props.update ?
              <TaskUpdateForm renderProps={ renderProps } {...formProps} />
            :
              <TaskNewForm renderProps={ renderProps } {...formProps} />
            }
            <FormPageActions
              onPrimaryAction={renderProps.handleSubmit}
              deleteURL={props.deleteURL}
            />
          
          { props.task.task_logs &&
            <ResourceLogList logs={ props.task.task_logs } />
          }
          </Form>

        </div>
      }
    </PolarisFormProvider>
  )
};

TaskFormContainer.propTypes = propTypes;
TaskFormContainer.defaultProps = defaultProps;

export default TaskFormContainer;