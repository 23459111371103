import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, FormLayout, Select, Checkbox, TextStyle } from '@shopify/polaris';
import BasicTextField from '@shared/BasicTextField';
import { ta } from 'utils';

const propTypes = {
  formProps: PropTypes.object, // From polaris form provider
  users: PropTypes.array.isRequired,
}

const defaultProps = {

}

const TeamForm = (props) => {
  const { formProps, users } = props;
  return (
    <Form onSubmit={formProps.handleSubmit}>
      <FormLayout>
        <hr />
        <BasicTextField
          name="name"
          label={ta("team.name")}
          {...formProps}
        />
        {/* <BasicTextField
          name="migration_handle"
          label={ta("team.migration_handle")}
          {...formProps}
        /> */}
        <Checkbox
          id="only_dispatch"
          label="Solo despacho"
          checked={ formProps.values.only_dispatch }
          onChange={ formProps.handleChange }
        />
        <Checkbox
          id="uber"
          label="Uber"
          checked={ formProps.values.uber }
          onChange={ formProps.handleChange }
        />
        <Checkbox
          id="pickup"
          label="Pick up"
          checked={ formProps.values.pickup }
          onChange={ formProps.handleChange }
        />
        <Checkbox
          id="disabled"
          label="Desactivado"
          checked={ formProps.values.disabled }
          onChange={ formProps.handleChange }
        />
        <TextStyle variation="subdued">Si desactiva el team, todos los mensajeros seran desactivados y sus ordenes desasignadas</TextStyle>
        <Select
          id="user_id"
          name="user_id"
          label={ta("general.user")}
          options={ users }
          value={formProps.values.user_id}
          {...formProps}
          onChange={formProps.handleChangeNumber}
        />
      </FormLayout>

    </Form>
  )
};

TeamForm.propTypes = propTypes;
TeamForm.defaultProps = defaultProps;

export default TeamForm;