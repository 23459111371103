//import * as React from "react";
import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import { Spinner } from "@shopify/polaris";

// the source is from
// https://github.com/googlemaps/js-samples/blob/6bea459c1153ddd320d15fef0c0ab86edf0c9825/dist/samples/react-map/docs/index.jsx#L118-L125

const propTypes = {
  markerLoc: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  mapCenterLoc: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  moveMapCenter: PropTypes.bool.isRequired,
  onClick: PropTypes.func, // for marker selection
}

// states to check: loading or failure
// on success it renders the child components
const render = (status) => {
  switch (status) {
    case Status.LOADING:
      return <Spinner accessibilityLabel="Esperando a que cargue Google Maps" size="large" />
    default:
      return(
        <h1>Ha dado un error al cargar Google Maps.</h1>
      )
  }
};

////////////////////////////
// START maps_react_map_component_app_state

// Main component exported
const GoogleMapOneMarker = (props) => {

  const onClick = (e) => {
    const coordinates = e.latLng;
    const newLat = coordinates.lat();
    const newLng = coordinates.lng();

    if(props.onClick){ props.onClick(newLat, newLng); }
  };

  // const onIdle = (m) => {
  //   // it has an error of running all the time
  //   //// it is fixed with useDeepCompareEffectForMaps
  //   //// but it needs the import of "fast-equals" into yarn
  //   setZoom(m.getZoom());
  //   setCenter(m.getCenter().toJSON());
  // };

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper 
        apiKey="AIzaSyC4sRQX4xGyahHlB9KlGQ-Plrjvs2jQQQQ" 
        language="ES" region="CR" render={render}
        libraries={["places"]}
      >
        <Map
          center={props.mapCenterLoc}
          moveMapCenter={props.moveMapCenter}
          zoom={17}
          style={{ flexGrow: "1", height: "100%" }}
          onClick={onClick}
          //onIdle={onIdle}
        >
            <Marker
              position={props.markerLoc}
            />
        </Map>
      </Wrapper>
    </div>
  );
}

////////////////////////////
// [START maps_react_map_component_add_map_hooks]
//const Map = ({ onClick, onIdle, children, style, ...options }) => {
const Map = ({ onClick, children, style, ...options }) => {
  const mapRef = React.useRef(null);
  const [map, setMap] = React.useState();

  React.useEffect(() => {
    if (mapRef.current && !map) {
      // google variable is only available inside React.useEffect functions right now.
      setMap(new window.google.maps.Map(mapRef.current, {
        zoom: options.zoom,
        center: options.center,
      }));
    }
  }, [mapRef, map]);
  // [END maps_react_map_component_add_map_hooks]

  React.useEffect(() => {
    if (map && options.moveMapCenter){
      const mapCenter = map.getCenter().toJSON();
      if( mapCenter.lat != options.center.lat || mapCenter.lng != options.center.lng ) {
        // map.setOptions(options);
        map.setCenter(options.center);
        map.panTo(options.center);
      }
    }
  }, [map, options.center]);
  
  // [START maps_react_map_component_options_hook]
  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  // useDeepCompareEffectForMaps(() => {
  //   if (map) {
  //     map.setOptions(options);
  //   }
  // }, [map, options]);
  // [END maps_react_map_component_options_hook]
  
  // [START maps_react_map_component_event_hooks]
  React.useEffect(() => {
    if (map) {
      // ["click", "idle"].forEach((eventName) =>
      //   google.maps.event.clearListeners(map, eventName)
      // );

      google.maps.event.clearListeners(map, "click");
      
      if (onClick) {
        map.addListener("click", onClick);
      }

      // if (onIdle) {
      //   map.addListener("idle", () => onIdle(map));
      // }
    }
  }, [map, onClick]);
  // }, [map, onClick, onIdle]);
  // [END maps_react_map_component_event_hooks]

  // [START maps_react_map_component_return]
  return (
    <>
      <div ref={mapRef} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          // set the map prop on the child component
          return React.cloneElement(child, { map });
        }
      })}
    </>
  );
  // [END maps_react_map_component_return]
};

////////////////////////////
// [START maps_react_map_marker_component]
const Marker = (options) => {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (!marker) {
      setMarker(new google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  
  return null;
};
// [END maps_react_map_marker_component]

// const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a, b) => {
//   if (
//     isLatLngLiteral(a) ||
//     a instanceof google.maps.LatLng ||
//     isLatLngLiteral(b) ||
//     b instanceof google.maps.LatLng
//   ) {
//     return new google.maps.LatLng(a).equals(new google.maps.LatLng(b));
//   }
//   // TODO extend to other types
//   // use fast-equals for other objects
//   return deepEqual(a, b);
// });

// function useDeepCompareMemoize(value) {
//   const ref = React.useRef();

//   if (!deepCompareEqualsForMaps(value, ref.current)) {
//     ref.current = value;
//   }
//   return ref.current;
// }

// function useDeepCompareEffectForMaps(callback, dependencies) {
//   React.useEffect(callback, dependencies.map(useDeepCompareMemoize));
// }

GoogleMapOneMarker.propTypes = propTypes;
export default GoogleMapOneMarker;