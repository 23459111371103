import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, Form, TextField, FormLayout } from '@shopify/polaris';
import { ta } from 'utils';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,

  values: PropTypes.shape({
    uid: PropTypes.string,
    user_attributes: PropTypes.object,
  }),
  errors: PropTypes.object.isRequired,
  secondaryFooterActions: PropTypes.array,
}

const CourierForm = ({
  handleSubmit, values, errors, handleChange, ...otherProps
}) => (
  <Card
    sectioned
    title={ `Editando ${I18n.t("activerecord.models.courier.one")}` }
    primaryFooterAction={{
      content: I18n.t("actions.save"),
      onAction: () => handleSubmit()
    }}
    secondaryFooterActions={ otherProps.secondaryFooterActions }
  >
    <Form onSubmit={handleSubmit} >
      {/* user name */}
      <FormLayout>
        <TextField
          id="user_attributes.first_name"
          label={ta("general.name")}
          placeholder={I18n.t("form.placeholder.name")}
          value={values.user_attributes.first_name}
          onChange={handleChange}
          error={errors["user.first_name"]}
        />
        <TextField
          id="phone"
          type='tel'
          prefix='+506'
          label={ta("courier.phone")}
          placeholder='12345678'
          value={values.phone}
          onChange={handleChange}
          error={errors.phone}
        />
        <TextField
          id="national_id"
          label={ta("courier.national_id")}
          placeholder="---"
          value={values.national_id}
          onChange={handleChange}
          error={errors.national_id}
        />
        <TextField
          id="uid"
          label={ta("courier.uid")}
          placeholder="xxe3"
          minLength={4}
          maxLength={4}
          value={values.uid}
          onChange={handleChange}
          error={errors.uid}
        />
      </FormLayout>
    </Form>
  </Card>
)

CourierForm.propTypes = propTypes;

export default CourierForm;
