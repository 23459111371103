import React, { useState } from 'react';
import PropTypes from 'prop-types';

import WhatsappLogo from '@components/../../assets/images/icons/customer_tickets/whatsapp.svg';
import BellIcon from '@components/../../assets/images/icons/customer_tickets/bell.svg';
import AlertIcon from '@components/../../assets/images/icons/customer_tickets/alert.svg';

import { useFormikContext } from 'formik';

const propTypes = {

}

const defaultProps = {

}

const TicketSuccessSummary = (props) => {
  const formik = useFormikContext();

  const customerTicket = formik.values.customerTicket;

  return (
    <div>
      <div className="bg-neutral-gray-25 mb20 p20" style={{ borderRadius: 10 }}>
        <h3 className=" heading-3 mb12">
          Resumen
        </h3>

        <SummaryItem title="Número de solicitud:" value={ customerTicket.name } />
        <SummaryItem title="Tipo de solicitud:" value={ I18n.t(`enum.ticket_kind.${customerTicket.ticketKind.toLowerCase()}`) } />
        <SummaryItem title="Producto" value={ customerTicket.productTitle } />
      </div>

      <h4 className='heading-4 mb12'>
        Importante:
      </h4>

      <div className="summary-instructions">
        <div className='border-neutral-gray-50 p12 round-8 flex flexColumn justifyCenter alignItemsCenter mb4 lg:mb0 lg:mr8'>
          <img src={ WhatsappLogo } className='mb8' />
          <p className='text-neutral-gray-200 text-center'>
            Te mantendremos informado por WhatsApp
          </p>
        </div>
        <div className='border-neutral-gray-50 p12 round-8 flex flexColumn justifyCenter alignItemsCenter mb4 lg:mb0 lg:mr8'>
          <img src={ BellIcon } className='mb8' />
          <p className='text-neutral-gray-200 text-center'>
            Tendrás noticias de nosotros en menos de 48 horas
          </p>
        </div>
        <div className='border-neutral-gray-50 p12 round-8 flex flexColumn justifyCenter alignItemsCenter mb4 lg:mb0 lg:mr8'>
          <img src={ AlertIcon } className='mb8' />
          <p className='text-neutral-gray-200 text-center'>
            Aun NO debes enviar el producto (hasta que te avisemos)
          </p>
        </div>
      </div>
    </div>
  )
};

const SummaryItem = ({ title, value }) => (
  <div className="flex">
    <p className="text-neutral-gray-200 mr8">{ title }</p>
    <p className="font-semibold">{ value }</p>
  </div>
)

TicketSuccessSummary.propTypes = propTypes;
TicketSuccessSummary.defaultProps = defaultProps;

export default TicketSuccessSummary;