import React, { useState } from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';

import { Form, FormLayout, Select } from '@shopify/polaris';

import PolarisFormProvider from '@shared/PolarisFormProvider';
import FormPageActions from '@shared/FormPageActions';
import UserForm from './components/Form';


const propTypes = {
  record: PropTypes.object.isRequired,
  update: PropTypes.bool,
  submitURL: PropTypes.string.isRequired,
  deleteURL: PropTypes.string,
  roles: PropTypes.array.isRequired,
};

const defaultValues = {
  name: "",
  user_id: ""
}

const defaultProps = {
  update: false,
}

const UserNew = (props) => {
  const defaultValues = props.update ? props.record : merge(props.record, defaultValues);
  const method = props.update ? "PATCH" : "POST";
  let { submitURL, deleteURL } = props;
  console.log("Mongo", props);

  return (
    <PolarisFormProvider
      title={I18n.t("activerecord.models.user.one")}
      defaultValues={defaultValues}
      submitURL={submitURL}
      submitMethod={method}
      paramsName="user"
      {...props.page}
    >
      {(renderProps) =>
        <Form onSubmit={renderProps.handleSubmit}>
          <UserForm
            renderProps={renderProps}
            roles={props.roles}
          />
          <FormPageActions
            onPrimaryAction={renderProps.handleSubmit}
            deleteURL={props.deleteURL}
          />
        </Form>
      }
    </PolarisFormProvider>
  );
}


UserNew.propTypes = propTypes;
UserNew.defaultProps = defaultProps;


export default UserNew;
