import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { clsx } from 'clsx';
import { RadioButton, TextStyle } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';

const propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      value: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      className: PropTypes.string,
    })
  ).isRequired,

  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  // error: PropTypes.string,
}

const defaultProps = {

}

const SquareBoxRadioButtons = (props) => {
  return (
    <div className={clsx("square-box-radio-buttons-container", props.className)}>
      <div className="square-box-radio-buttons">
        {props.options.map((option, index) => {
          const handleClick = () => { props.onClick(option.value) };
          const active = props.value === option.value;
          return(
            <div key={option.value} className={clsx("square-box-option flex1 pv16 ph12 flex flexColumn alignItemsCenter justifyCenter relative mb8 lg:mb0 lg:mr8", active && "active" )} onClick={handleClick}>
              <div className={clsx("gradient-block mb12", option.className)}>
                <img src={ option.icon } alt="Icon" />
              </div>
              { option.title &&
                <p className='font-semibold text-center'>
                  { option.title }
                </p>
              }
              { option.description &&
                <p className="text-neutral-gray-200 text-center fs12" style={{ lineHeight: "16px" }}>
                  { option.description }
                </p>
              }
      
              <div className="radio-container">
                <RadioButton
                  checked={ active }
                  onChange={ handleClick }
                />
              </div>
            </div>
          )
        })}
      
      </div>
      { props.error &&
        <div className='text-center flex justifyCenter'>
          <div className='mr4'>
            <AlertMinor color="#bf0711" fill="#bf0711" height={20} width={20} />
          </div>
          <TextStyle variation='negative'>
            { props.error }
          </TextStyle>
        </div>
      }
    </div>
  )
};

SquareBoxRadioButtons.propTypes = propTypes;
SquareBoxRadioButtons.defaultProps = defaultProps;

export default SquareBoxRadioButtons;