import React, { useState, useCallback } from 'react';
import { DatePicker } from '@shopify/polaris';
import PropTypes from 'prop-types';

const propTypes = {
  initialDateStart: PropTypes.string, // ISO8601
  initialDateEnd: PropTypes.string, // ISO8601

  onChange: PropTypes.func.isRequired,
}

const defaultProps = {
}

const AppDatePicker = (props) => {
  const { onChange, initialDateStart, initialDateEnd, ...datePickerProps } = props;

  // Initialization of values
  const currentDate = new Date;
  let initialValues = null;
  let initialDatePickerFocus = { month: currentDate.getMonth(), year: currentDate.getFullYear() };

  if(props.initialDateStart || props.initialDateEnd){
    const startDate = props.initialDateStart ? new Date(props.initialDateStart) : currentDate;
    const endDate = props.initialDateEnd ? new Date(props.initialDateEnd) : currentDate;
    initialValues = {
      start: startDate,
      end: endDate,
    };

    initialDatePickerFocus = { month: startDate.getMonth(), year: startDate.getFullYear() };
  };
  // =======
  // console.log("M",initialDatePickerFocus);
  // console.log("Y",initialValues);

  const [{month, year}, setDate] = useState(initialDatePickerFocus);
  const [selectedDates, setSelectedDates] = useState(initialValues);

  const handleMonthChange = useCallback( (month, year) => {
    setDate({month, year}); // Move dates view
  },[]);

  const handleChange = useCallback( (dates) => {
    setSelectedDates(dates);
    props.onChange(dates);
  }, [props.onChange]);

  return (
    <DatePicker
      month={month}
      year={year}
      onChange={handleChange}
      onMonthChange={handleMonthChange}
      selected={selectedDates}
      {...datePickerProps}
    />
  )
};

AppDatePicker.propTypes = propTypes;
AppDatePicker.defaultProps = defaultProps;

export default AppDatePicker;