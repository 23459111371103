import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Modal, Form, FormLayout, TextField } from '@shopify/polaris';

const propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onDeliver: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  order: PropTypes.object,

  modalProps: PropTypes.shape({
    title: PropTypes.string,
    primaryAction: PropTypes.object,
    secondaryActions: PropTypes.array,
  }),
}

const defaultProps = {
  modalProps: {},
  errors: {},
}

const OrderDeliveryModal = (props) => {
  const [recipientName, setRecipientName] = useState("");

  const deliver = () => {
    props.onDeliver(recipientName)
  }

  return(
    <Modal
      large
      open={props.open}
      onClose={props.handleClose}
      title={ I18n.t("delivery_modal_title") }
      primaryAction={{
        content: I18n.t("actions.register"), onAction: deliver, loading: props.loading
      }}
      secondaryActions={[{
        content: I18n.t("actions.cancel"), onAction: props.handleClose, loading: props.loading
      }]}
    >
      <Modal.Section>
        <Form onSubmit={ deliver }>
          <FormLayout>
            <TextField
              id="recipient_name"
              label={ I18n.t("activerecord.attributes.order.recipient_name") }
              placeholder={ "Jose" }
              value={recipientName}
              onChange={ setRecipientName }
              error={ props.errors.recipient_name }
            />
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  )
}

OrderDeliveryModal.propTypes = propTypes;
OrderDeliveryModal.defaultProps = defaultProps;

export default OrderDeliveryModal;