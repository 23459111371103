import React, { useState } from 'react';
import PropTypes from 'prop-types';
import esTranslations from '@shopify/polaris/locales/es.json';
import { AppProvider, Page, Card, Frame, Layout, DisplayText, Button } from '@shopify/polaris';
import OrdersList from '@views/orders/components/OrdersList';
import axios from '@utils/axios-rails';
import CourierList from '@views/couriers/components/CouriersList';

import OrdersListWithFilters from '@views/orders/components/OrdersListWithFilters';
import { pagyShape } from 'utils/shapes';

const propTypes = {
  orders: PropTypes.array,
  team: PropTypes.object.isRequired,
  couriers: PropTypes.array.isRequired,
  couriers_filter: PropTypes.array.isRequired,
  editTeamURL: PropTypes.string.isRequired,
  pagy: pagyShape.isRequired,
  exportSubmitURL: PropTypes.string.isRequired,
}

const OrdersIndexTeamLeader = ({ team, couriers, ...props }) => {
  const [open, setOpen] = useState(false);
  const openExportModal = () => setOpen(true)
  const closeExportModal = () => setOpen(false)

  return (
    <AppProvider i18n={esTranslations}>
      <Frame>
        <Page fullWidth>
          <Layout>
            {/* Team info section */}
            <Layout.Section secondary>
              <Card>
                <Card.Section>
                  <div className="flex justifyBetween">
                    <DisplayText size="large">
                      { team.name }
                    </DisplayText>
                    <Button primary url={props.editTeamURL}>
                      Administrar
                    </Button>
                  </div>
                </Card.Section>

                <Card.Section title={ I18n.t("activerecord.models.courier.other") }>
                  <CourierList 
                    items={ couriers }
                    onClick={() => true}
                  />
                </Card.Section>
              </Card>
            </Layout.Section>

            {/* Orders Section */}
            <Layout.Section>
              <OrdersListWithFilters
                orders={ props.orders }
                statuses={ props.statuses }
                couriers={ props.couriers_filter}
                admin= {props.admin}
                teams={ props.teams}
                pagy={ props.pagy }
                exportSubmitURL={ props.exportSubmitURL }
              />
            </Layout.Section>
          </Layout>

          <div>
            
          </div>
        </Page>
      </Frame>
    </AppProvider>
  );
}

OrdersIndexTeamLeader.propTypes = propTypes;

export default OrdersIndexTeamLeader;