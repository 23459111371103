import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormLayout, Select, Form, Icon, Card, OptionList, TextField, Banner, TextStyle } from '@shopify/polaris';

import { ta } from 'utils';
import axios from 'utils/axios-rails';

import BasicTextField from '@shared/BasicTextField';
import DatePicker from '@shared/AppDatePicker';
import TaskDeliveryDetails from './TaskDeliveryDetails';

const propTypes = {
  // values: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
}

const defaultProps = {
  readOnly: false,
}

const TaskUpdateForm = ({ renderProps, ...props }) => {
  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [customerOrderItems, setCustomerOrderItems] = useState([]);
  
  const { values } = renderProps;
  const { kinds, teams } = props.catalogs;

  const orderDataExists = !!values.brightpearl_data.order_reference || !!values.brightpearl_data.order_items;

  return (
    <Card>
      <Banner title={ I18n.t(`enum.status.${values.status}`) }></Banner>
      <Card.Section title="Información de contacto">
        <FormLayout>
          <TextField
            readOnly
            label={ta("task.kind")}
            value={ I18n.t(`enum.kind.${values.kind}`) }
          />
          
          {/* Fields based on Contact selection */}
          <FormLayout.Group>
            <TextField
              readOnly
              label={ta("general.name")}
              value={ values.brightpearl_data.name }
            />
            <TextField
              readOnly
              label={ta("general.phone")}
              value={ values.brightpearl_data.phone }
              />
          </FormLayout.Group>
          <TextField
            label={ta("general.address")}
            readOnly
            value={ values.address }
          />
        </FormLayout>
      </Card.Section>

      { orderDataExists ? (
        <Card.Section title="Información de la tarea">
          <FormLayout>
            <TextField
              readOnly
              label={ta("task.provider_order_id")}
              value={ String(values.provider_order_id) }
            />
            <FormLayout.Group>
              <TextField
                readOnly
                label="#"
                value={ values.brightpearl_data.order_reference }
              />
              <TextField
                readOnly
                label={ta("general.status")}
                value={ values.brightpearl_data.order_status }
              />
            </FormLayout.Group>
            { values.brightpearl_data.order_items.length > 0 &&
              <OptionList
                title="Productos"
                readOnly
                allowMultiple
                onChange={ () => true }
                options={ values.brightpearl_data.order_items }
                selected={ values.item_ids }
              />
            }
          </FormLayout>
        </Card.Section>
        ) : ( null )
      }
          
      <Card.Section title="Información de envío">
        <TaskDeliveryDetails 
          initialCouriers={ props.initialCouriers }
          queryCouriersURL={ props.queryCouriersURL }
          teams={ teams }
          disabled={ props.readOnly }

          polarisFormProps={ renderProps }
        />
      </Card.Section>
    </Card>
  )
};

TaskUpdateForm.propTypes = propTypes;
TaskUpdateForm.defaultProps = defaultProps;

export default TaskUpdateForm;