import React, {useState} from 'react';
import PropTypes from 'prop-types'
import esTranslations from '@shopify/polaris/locales/es.json';
import {AppProvider, Page, Card, List, Badge, Button, TextStyle, Toast, Frame} from '@shopify/polaris';
import axiosRails from '@utils/axios-rails';
import {statusVariations } from 'utils';
import OrderItemAddress from "./components/components/OrderItemAddress";
import ResourceLogList from "./components/ResourceLogList";

const propTypes = {
  order: PropTypes.object.isRequired,
}

const OrderShow = (props) => {

  const order = props.order;
  const addressFields = ["name", "address", "national_id", "phone", "province"];
  
  const [status, setStatus] = useState(order.status);
  const [toast, setToast] = useState("");

  const dismissToast = () => setToast("");

  console.log(order)

  const unassign = async () => {
    if (confirm("Esta acción es irreversible, continuar?")) {
      try {
        const response = await axiosRails.post(order.unassign_url); 
        console.log(response)
        const { data } = response;

        if (data.errors) { 
          setToast(data.message);
          return null;
        }

        if (data.success){
          setStatus('unassigned');
          setToast(I18n.t("messages.update_successful"));
        } 
      } catch (error) {
        console.warn(error);
        setToast(I18n.t("messages.request_error"));
      }
    }
  }

  let primaryAction = status == "assigned" ? {
    content: 'Desasignar',
    onAction: unassign,
    destructive: true
  } :  null;

  return (
      <AppProvider i18n={""}>
        <Frame>
          <Page
              breadcrumbs={[{content: 'Atras', url: '/orders'}]}
              title={`Detalle Orden `}
              subtitle={ I18n.t(`enum.status.${status}`) }
              primaryAction={primaryAction}
          >
            <Card sectioned>
              <List type="bullet">
                <InfoLine title={"ID Orden"} text={ order.delivery_detail_provider_order_id } />
                <InfoLine title={I18n.t("activerecord.attributes.order.order_number")} text={ order.order_number } /> 

                { !!order.courier_name &&
                  <div className="Polaris-List__Item">
                    <InfoLine title={I18n.t("activerecord.attributes.order.courier_name")} text={ order.courier_name } /> 
                    <InfoLine title={I18n.t("activerecord.models.team.one")} text={ order.team_name } /> 
                  </div>
                }

                {(!!order.provider_data.shipping_address) &&
                  <div className="Polaris-List__Item">
                     { addressFields.map((field) => {
                      return (
                        <List.Item key={field}>
                          <TextStyle variation={"strong"}>
                            { I18n.t(`shopify.order.shipping_address.${field}`) }:
                          </TextStyle>
                          {order.provider_data.shipping_address[field]}
                        </List.Item>
                      )
                    })}
                  </div>
                }

                <div className="Polaris-List__Item status">
                  <List.Item>
                    <TextStyle variation={"strong"}>
                      {I18n.t("activerecord.attributes.order.status")}:
                    </TextStyle>
                    <TextStyle variation={ statusVariations[status] }>
                      { I18n.t(`enum.status.${status}`) }
                      <OrderItemAddress providerData={ order.provider_data } />
                    </TextStyle>
                  </List.Item>
                </div>

                { order.recipient_name && <InfoLine title={I18n.t("activerecord.attributes.order.recipient_name")} text={ order.recipient_name } /> }
                { order.delivered_at_human && <InfoLine title={I18n.t("activerecord.attributes.order.delivered_at")} text={ order.delivered_at_human } /> }

                <List.Item>
                  <TextStyle variation={"strong"}>
                    {I18n.t("activerecord.attributes.order.provider_name")}:
                  </TextStyle>
                  <Badge status="success"> {I18n.t("enum.provider_name."+order.provider_name)}</Badge>
                </List.Item>
              </List>
            </Card>

            <ResourceLogList title="Historial de Entrega" logs={ order.order_logs } />
            { toast && <Toast content={toast} onDismiss={ dismissToast } /> }

          </Page>
        </Frame>

      </AppProvider>
  )
}

const InfoLine = ({ title, text }) => (
  <List.Item>
    <TextStyle variation={"strong"}>
      { title }:
    </TextStyle>
    { text }
  </List.Item>
)

OrderShow.propTypes = propTypes;

export default  OrderShow;