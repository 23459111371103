import React from 'react';
import PropTypes from 'prop-types';

import { Badge } from '@shopify/polaris';

import { Page, Card, DataTable, TextField, Link, Button, Spinner } from '@shopify/polaris';
import includes from 'lodash/includes';

const DISPLAY_AMOUNT_STATUSES = ["PARTIALLY_PAID", "PENDING", "UNPAID"];

const propTypes = {
  editTracking: PropTypes.bool,
  handleOrderChange: PropTypes.func,
  uber: PropTypes.bool, // If true and not show display uber_valid (for form)
  isShow: PropTypes.bool, // If true and uber display uber_order_id

  onUberCancel: PropTypes.func,
  loading: PropTypes.bool, // Only works if onUberCancel is present
}

const defaultProps = {
  editTracking: false,
  uber: false,
  isShow: false,
}

const DisplayControlOrders = (props) => {
  const { orders, editTracking, uber } = props;

  const displayUberValid = props.uber && !props.isShow;
  const displayUberOrderId = props.uber && props.isShow;

  let records = [];
  let rowNumber = 1;
  if (props.orders.length > 0) {
    for (let order of props.orders) {
      let parsedOrder = order;
      let received_amount = null;

      if (!includes(DISPLAY_AMOUNT_STATUSES, order.financial_status)) {
        parsedOrder = {
          ...parsedOrder,
          amount: "",
        }
      }
      else {
        if (parseFloat(order.received_amount) != parseFloat(order.amount)) {
          received_amount = parseFloat(order.amount) - parseFloat(order.received_amount).toFixed(2);
          parsedOrder.amount = order.amount;
        }
      }

      let trackingNumberCell;
      if(!props.editTracking || order.read_only){
        trackingNumberCell = parsedOrder.tracking_number
      }
      else{
        trackingNumberCell = <TextField id={ order.provider_id } value={ order.tracking_number } onChange={ props.handleOrderChange } />
      }

      let id = parsedOrder.display_shopify_id || parsedOrder.shopify_id;

      //add an order link if associated record exists
      if (parsedOrder.order_url) id = <Link external url={parsedOrder.order_url}>{id}</Link>;

      // ==============> BUILDING OF THE ROWS IS DONE HERE  <===============

      let rowData = [
        rowNumber,
        id,
        parsedOrder.name,
        parsedOrder.order_number,
        I18n.t(`order.financial_status.${parsedOrder.financial_status}`),
        received_amount,
        parsedOrder.brightpearl_custom_field_oc && I18n.t(String(parsedOrder.brightpearl_custom_field_oc)), //TRUE/FALSE (only display when true)
        parsedOrder.customer_procedure_kind,
        parsedOrder.pcf_zona,
        trackingNumberCell,
      ]

      if(displayUberValid){ // Display uber_valid (for form purposes)
        rowData.push(
          <div>
            <Badge status={parsedOrder.uber_valid ? "info" : "warning"}>
              {!!parsedOrder.uber_valid ? I18n.t("true") : I18n.t("false")}
            </Badge>
          </div>
        )
      }

      if(displayUberOrderId){ // Display uber_order_id (for show purposes after creation)
        rowData.push(
          parsedOrder.display_uber_uuid
        )
      }

      if(!!props.onUberCancel && parsedOrder.uber_cancellable){ // Display uber cancel action if possible
        rowData.push(
          <div>
            <Button 
              onClick={ () => props.onUberCancel(parsedOrder.id) } 
              disabled={ props.loading }
              destructive plain
            >
              Cancelar Entrega
            </Button>
          </div>
        )
      }

      records.push(rowData),
      rowNumber +=1;
    }
  } 
  
  let headings = [
    "",
    I18n.t("activerecord.attributes.dispatch_order.provider_id"),
    I18n.t("activerecord.attributes.dispatch_order.name"),
    I18n.t("activerecord.attributes.dispatch_order.order_number"),
    I18n.t("activerecord.attributes.dispatch_order.financial_status"),
    I18n.t("activerecord.attributes.dispatch_order.received_amount"),
    I18n.t("activerecord.attributes.dispatch_order.brightpearl_custom_field_oc"),
    I18n.t("activerecord.attributes.dispatch_order.customer_procedure_kind"),
    I18n.t("activerecord.attributes.dispatch_order.pcf_zona"),
    I18n.t("activerecord.attributes.dispatch_order.tracking_number"),

  ]
  if(displayUberValid){
    headings = [
      ...headings, 
      I18n.t("activerecord.attributes.dispatch_order.uber_valid")
    ]
  }
  if(displayUberOrderId){
    headings = [
      ...headings,
      I18n.t("activerecord.attributes.dispatch_order.uber_order_id")
    ]
  }

  if(!!props.onUberCancel){
    headings = [
      ...headings,
      props.loading ? <Spinner size="small" color="teal" /> : ""
    ]
  }

  return (
    <Card>
      <DataTable
        headings={headings}
        rows={records}
        columnContentTypes={[
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
          'text',
        ]} />
    </Card>
  )
}

DisplayControlOrders.propTypes = propTypes;
DisplayControlOrders.defaultProps = defaultProps;

export default DisplayControlOrders;