import PropTypes from 'prop-types';

export const pagyShape = PropTypes.shape({ // ruby pagy object initial state
  count: PropTypes.number,
  last: PropTypes.number,
  next: PropTypes.number,
  page: PropTypes.number,
  prev: PropTypes.oneOfType([PropTypes.number, PropTypes.object,]), // can be null
  scaffold_url: PropTypes.string,
});

export const dispatchControlShape = PropTypes.shape({
  teamId: PropTypes.number.isRequired,
  orders: PropTypes.array.isRequired,
  providerName: PropTypes.string.isRequired,
  editTracking: PropTypes.bool,
});

export const taskCatalogs = PropTypes.shape({
  kinds: PropTypes.array,
  teams: PropTypes.array,
});

export const selectOptionsProp = PropTypes.arrayOf(
  PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number,]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
);

export const brightpearlIntegrationProps = {
  active: PropTypes.bool.isRequired,
  submitURL: PropTypes.string.isRequired,

  webhooks: PropTypes.array.isRequired,
  webhooksSetupURL: PropTypes.string.isRequired,
};

export const polarisFormProps = PropTypes.shape({
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  status: PropTypes.object,
  resetForm: PropTypes.func.isRequired,

  handleChange: PropTypes.func.isRequired,
  handleChangeNumber: PropTypes.func.isRequired,
  toggleValue: PropTypes.func.isRequired,
  pushNewValue: PropTypes.func.isRequired,
  pushToast: PropTypes.func.isRequired,
});  

export const brightpearlIntegrationShape = PropTypes.shape(brightpearlIntegrationProps);

export const orderProviderDataShape = PropTypes.shape({
  shipping_address: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    province: PropTypes.string,
    canton: PropTypes.string,
    district: PropTypes.string,
    
    national_id: PropTypes.string,
    phone: PropTypes.string,
  }),
  shipping_method: PropTypes.string,
  financial_status: PropTypes.string,
  delivery_limit_date: PropTypes.string,
});