import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ChoiceList } from '@shopify/polaris';

const propTypes = {
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
}

const defaultProps = {

}

const ProviderChoiceList = (props) => {
  const handleProviderChange = (values) => { props.handleChange(values[0]) } // Return just one values as it isn't multiple choice

  return (
    <ChoiceList
      title={ "Buscar por:" }
      choices={[
        {label: 'Brightpearl Order ID', value: 'bright_pearl'},
        {label: 'Shopify Order ID', value: 'shopify'},
      ]}
      selected={ props.value }
      onChange={ handleProviderChange }
    />
  )
};

ProviderChoiceList.propTypes = propTypes;
ProviderChoiceList.defaultProps = defaultProps;

export default ProviderChoiceList;