import React, { useRef, useState,useCallback } from 'react';
import PropTypes from 'prop-types';
import { Frame, Toast } from '@shopify/polaris';

const propTypes = {
  value: PropTypes.string.isRequired, // value to be copied
}

const defaultProps = {

}

const CopyToCliboardProvider = (props) => {

  // const [copySuccess, setCopySuccess] = useState(false);
  const [active, setActive] = useState(false);
  const textAreaRef = useRef(null);
  
  
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const copyToClipboard = (e) => {
    if(document.queryCommandSupported('copy')){
      textAreaRef.current.select();
      document.execCommand('copy');
      // This is just personal preference.
      // I prefer to not show the the whole text area selected.
      // e.target.focus();
      toggleActive();
      // setCopySuccess('Copied!');
    }
    else{
      alert("Copiado automático no es soportado por este navegador.")
    }
  };

  const renderProps = {
    copyToClipboard,
  }

  return (
    <div>
      {/* Some content with the copy function trigger */}
      { props.children(renderProps) }
      <form style={{ width: 0, height: 0, opacity: 0.01 }}>
        <textarea
          readOnly
          ref={textAreaRef}
          value={ props.value }
        />
      </form>
      <Frame>
        { active &&
          <Toast 
            content={ I18n.t("messages.copied_to_clipboard") } 
            onDismiss={toggleActive} />
        }
      </Frame>
    </div>
  );
};

CopyToCliboardProvider.propTypes = propTypes;
CopyToCliboardProvider.defaultProps = defaultProps;

export default CopyToCliboardProvider;
