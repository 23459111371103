import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { Autocomplete, Icon } from '@shopify/polaris';
import { SearchMajorMonotone } from '@shopify/polaris-icons';

import debounce from 'lodash/debounce';
import axios from 'utils/axios-rails';

const propTypes = {
  queryURL: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.array.isRequired,
  
  label: PropTypes.string,
  placeholder: PropTypes.string,

  extraParams: PropTypes.object, // for query
  textFieldProps: PropTypes.object,
}

const defaultProps = {
  label: "Busqueda",
  placeholder: "some@email.com",
  extraParams: {},
  textFieldProps: {},
}

const SearchAutocomplete = forwardRef( (props, ref) => {

  const [text, setText] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onTextChange = (value) => {
    setText(value);
    debouncedQueryOptions(value);
    
  }

  const onSelect = (itemValue) => {
    const item = options.find( (item) => item.value == itemValue )
    props.onSelect(itemValue, item)
  }

  const queryOptions = async (value) => {
    setLoading(true);
    try {
      const response = await axios.post(props.queryURL, {
        query: {
          value,
          ...props.extraParams
        }
      })

      if(response.data.options){
        setOptions( response.data.options );
      }
    } catch (error) {
      console.log("Boom", error)
    }
    setLoading(false);
  }

  const debouncedQueryOptions = useCallback( debounce(queryOptions, 700),[props.extraParams]);

  // List of functions to expose on parent
  useImperativeHandle(ref, () => ({
    clearOptions: () => setOptions([]), // clear options from parent
  }));


  return (
    <Autocomplete
      disabled={true}
      options={ options }
      selected={ props.selected }
      onSelect={ onSelect }
      loading={loading}
      textField={
        <Autocomplete.TextField
          onChange={ onTextChange }
          value={ text }
          prefix={<Icon source={SearchMajorMonotone} color="inkLighter" />}
          label={ props.label }
          placeholder={ props.placeholder }
          {...props.textFieldProps}
        />
      }
    />
  )
});

// options: PropTypes.array.isRequired,
// onTextChange: PropTypes.func.isRequired,
// textValue: PropTypes.string.isRequired,
// onSearch: PropTypes.func.isRequired,
// onSearchError: PropTypes.func,

SearchAutocomplete.propTypes = propTypes;
SearchAutocomplete.defaultProps = defaultProps;

export default SearchAutocomplete;