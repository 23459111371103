import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { gql, useQuery, useMutation } from '@apollo/client';
import { Card, Button } from '@shopify/polaris';

import CustomerTicketAttachments from './CustomerTicketAttachments';
import GeneralModal from 'components/shared/GeneralModal';
import PolarisMediaDropzone from '@components/shared/ui/PolarisMediaDropzone';
import { base64FileReader } from '@utils/index';
import { userErrorsToFormik } from 'api/index';

import { useFormik } from 'formik';

const propTypes = {
  customerTicketId: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  onToast: PropTypes.func.isRequired,
}

const defaultProps = {
  as: 'div',
}

const CustomerTicketAdminMedia = (props) => {
  const modalRef = useRef(null);
  const Cmp = props.as;

  const { data, loading, error, refetch } = useQuery(MEDIA_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.customerTicketId
    }
  });

  const [uploadAdminMedia] = useMutation(ADMIN_MEDIA_CREATE, {
    onCompleted: (uploadData) => {
      const customerTicketUpdated = uploadData.customerTicketAdminMediaUpload.customerTicket;
      if(customerTicketUpdated?.id){
        props.onToast(I18n.t("messages.update_successful"));
        modalRef.current?.close(); // Close modal
        refetch(); // Refresh data
      }
      else{
        console.log("Errors", uploadData)
        props.onToast(I18n.t("messages.one_error"));
        formik.setErrors(userErrorsToFormik(uploadData.customerTicketAdminMediaUpload.errors));
      }
      formik.setSubmitting(false);
    },
    onError: (error) => {
      console.log("Error", error);
      props.onToast(I18n.t("messages.request_error"));
      formik.setSubmitting(false);
    }
  });

  // Media to upload, currently, media can only be added, not modified or deleted.
  const formik = useFormik({
    initialValues: {
      media: []
    },
    onSubmit: (values) => {
      uploadAdminMedia({
        variables: {
          id: props.customerTicketId,
          media: values.media
        }
      });
    }
  });

  const handleFilesChange = async (files) => {
    formik.setSubmitting(true);

    // const fileArray = Array.from(files); // Convert the FileList to an array
    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = base64FileReader(({ readerResult, mimeType, base64 }) => { // new FileReader() with onLoad parsing logic for base64.
          const mediaItem = {
            base64, mimeType, // from onLoad
            filename: file.name, // from File
          }
          resolve(mediaItem);
        });
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file); // Trigger the onload event
      });
    });

    // Actual form state update
    try {
      const base64Files = await Promise.all(promises)
      const currentMedia = formik.values.media || [];
      formik.setFieldValue("media", [
        ...currentMedia,
        ...base64Files
      ]);
      formik.setFieldTouched("media", true);
    } catch (error) {
      console.error("File read error:", error)
    }
    formik.setSubmitting(false);
  };

  const handleFileDelete = (index) => {
    const currentMedia = formik.values.media;
    const newMedia = currentMedia.filter( (item, i) => i !== index );
    formik.setFieldValue("media", newMedia);
  }

  const handleOpenModal = () => {
    modalRef.current?.open();
  }

  return (
    <Cmp
      primaryFooterAction={{
        content: I18n.t('actions.add'),
        onAction: handleOpenModal,
        loading: loading,
      }}
    >
      { !!data && <CustomerTicketAttachments attachments={data.customerTicket.adminMedia} /> }

      <GeneralModal
        ref={ modalRef }
        title={ "Añadir media" }
        primaryAction={{
          content: "Subir",
          onAction: formik.handleSubmit,
          loading: formik.isSubmitting,
          disabled: formik.values.media.length === 0,
        }}
        secondaryActions={[
          { content: "Cancelar", onAction: () => modalRef.current?.close() },
        ]}
      >
        <PolarisMediaDropzone
          files={ formik.values.media }
          loading={ formik.isSubmitting }
          label="Media"
          placeholderHint='Haga clic para subir fotos o videos'
          onChange={handleFilesChange}
          onClearAll={ () => formik.setFieldValue("media", [])}
          onDelete={ handleFileDelete }
          multiple
        />
      </GeneralModal>
    </Cmp>
  )
};

const MEDIA_LIST = gql`
  query($id: ID!){
    customerTicket(id: $id) {
      id
      adminMedia{
        url
        identifier
        contentType
      }
    }
  }
`;

const ADMIN_MEDIA_CREATE = gql`
  mutation($id: ID!, $media: [MediaInput!]!) {
    customerTicketAdminMediaUpload(id: $id, media: $media) {
      customerTicket {
        id
      }
      errors {
        key
        message
      }
    }
  }
`;

CustomerTicketAdminMedia.propTypes = propTypes;
CustomerTicketAdminMedia.defaultProps = defaultProps;

export default CustomerTicketAdminMedia;