import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, Frame, Toast, ChoiceList } from '@shopify/polaris';
import MultipleTagsInput from '@shared/MultipleTagsInput';
import OrdersList from '@views/orders/components/OrdersList';
import OrderDeliveryModal from '@views/home/components/OrderDeliveryModal';
import axios from '@utils/axios-rails';
import { List } from 'immutable';

import ProviderChoiceList from '@shared/ProviderChoiceList';

const propTypes = {
  orders: PropTypes.array,
  submitURL: PropTypes.string.isRequired,
  // deliverySubmitURL: PropTypes.string.isRequired,
}

const OrdersIndexCourier = (props) => {
  // General states
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState(List(props.orders));
  const [toast, setToast] = useState("");
  const [formErrors, setFormErrors] = useState({});

  // New orders states
  const [orderIds, setOrderIds] = useState([]);
  const [provider, setProvider] = useState("bright_pearl");

  // Delivery states
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});

  const jsOrders = orders.toJS();

  const submitOrders = async () => {
    setLoading(true);
    try {
      const response = await axios.post(props.submitURL, {
        provider_ids: orderIds,
        order: {  // Base data for each order
          provider_name: provider
        }
      });
      const { data } = response;
      if(data.success){
        setOrders( orders.unshift(...data.orders) ); // Insert on top
      }
      else{
        // errors display
      }
      console.log("CL response",data);
      setToast(data.message);
    } catch (error) {
      console.error(error);
      setToast(I18n.t("messages.request_error"));
    }

    setLoading(false);
  }

  const setupDeliveryModal = (item) => {
    console.log("Modal", item);
    setSelectedOrder(item);
    setFormErrors({});
    setModalOpen(true);
  }

  const completeDelivery = async (recipientName) => {
    setLoading(true);
    setFormErrors({});
    try {
      const response = await axios.post(`/orders/${selectedOrder.id}/deliver`, {
        order: {
          recipient_name: recipientName
        }
      });
      const { data } = response;
      if(data.success){
        const currentOrderIndex = orders.findIndex( (order) => order.id == data.order.id );
        setOrders( orders.set(currentOrderIndex, data.order) ); // Update order.
        setModalOpen(false);
      }
      else{
        setFormErrors(data.errors);
      }
      console.log("DL response", data);
      setToast(data.message);
    } catch (error) {
      console.error(error);
      setToast(I18n.t("messages.request_error"));
    }
    setLoading(false);
  }

  return(
    <AppProvider>
      <Frame>
        <Page>
          <div>
            <Card
              primaryFooterAction={{
                loading: loading,
                onAction: submitOrders,
                content: I18n.t("actions.register")
              }}
              sectioned>
              <ProviderChoiceList
                handleChange={ setProvider }
                value={ provider }
              />
              <MultipleTagsInput
                label={ "Order Insert" }
                tags={orderIds}
                handleChange={ setOrderIds }
                placeholder={ I18n.t("form.placeholder.courier_orders") }
              />
            </Card>

            <Card>
              <OrdersList
                items={ jsOrders }
                itemProps={{
                  deliveryAction:  { content: "Entregar", onAction: setupDeliveryModal }
                }}
              />
            </Card>

            { modalOpen &&
              <OrderDeliveryModal 
                open={modalOpen}
                handleClose={ () => setModalOpen(false) }
                onDeliver={ completeDelivery }
                loading={ loading }
                order={ selectedOrder }
                errors={ formErrors }
              />
            }

            { toast &&
              <Toast content={ toast } onDismiss={ () => setToast("") } duration={3000} />
            }
          </div>
        </Page>
      </Frame>
    </AppProvider>
  );
}

OrdersIndexCourier.propTypes = propTypes;

export default OrdersIndexCourier;
