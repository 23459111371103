// TEMPORALLY CLONNED NEW but should createa TeamForm component and use itt on both

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import merge from 'lodash/merge';

import PolarisFormProvider from '@shared/PolarisFormProvider';
import FormPageActions from '@shared/FormPageActions';
import TeamForm from './components/TeamForm';
import TeamRequirementsBanner from './components/TeamRequirementsBanner';

const defaultValues = {
  name: "",
  user_id: ""
}

const propTypes = {
  record: PropTypes.object.isRequired,
  update: PropTypes.bool,
  submitURL: PropTypes.string.isRequired,
  deleteURL: PropTypes.string,
  users: PropTypes.array.isRequired,
};

const defaultProps = {
  update: false,
}

class TeamEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //...defaultValues // When using polaris instead of formik
      defaultValues: props.record
    }
  }

  render() {
    let { defaultValues } = this.state;
    let { submitURL, deleteURL } = this.props;
    console.log("Mongo", this.props);
    return (
      <PolarisFormProvider
        title={I18n.t("activerecord.models.team.one")}
        defaultValues={defaultValues}
        submitURL={submitURL}
        submitMethod={"PUT"}
        paramsName="team"
        pageProps={ this.props.page }
      >
        {(renderProps) =>
          <div>
            {this.props.users.length > 1 ?
              <div>
                <TeamForm
                  formProps={ renderProps }
                  users={ this.props.users }
                />

                <FormPageActions
                  onPrimaryAction={renderProps.handleSubmit}
                  deleteURL={this.props.deleteURL}
                />
              </div>
              :
              <TeamRequirementsBanner/>
            }
          </div>
        }
      </PolarisFormProvider>
    );
  }
}


TeamEdit.propTypes = propTypes;
TeamEdit.defaultProps = defaultProps;


export default TeamEdit;
