import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, TextField, Form, Page, Card, Toast, Frame, DisplayText } from '@shopify/polaris';
import { uidMaxLength } from 'utils';
import axios from '@utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
}

const CourierLogin = (props) => {
  const [form, setForm] = useState({
    uid: "",
    national_id: ""
  })

  const [toast, setToast] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (value, id) => {
    setForm({
      ...form,
      [id]: value
    });
  }

  const handleSubmit = async () => {
    setLoading(true);
    const response = await axios.post(props.submitURL, {
      ...form
    });
    let data = response.data;
    try {
      if (data.success) {
        setToast(I18n.t("devise.sessions.signed_in"));
        window.location = data.redirectURL;
        return true;
      }
      else{
        setToast(I18n.t("messages.courier_login_invalid"));
      }
    } catch (error) {
      setToast("messages.request_error")
    }
    setLoading(false);
  }

  const onDismiss = () => setToast("");

  return(
    <AppProvider>
      <Frame>
        <Page title={ I18n.t("app.sign_in") }>
          <div>
            <Card
              primaryFooterAction={{
                content: I18n.t("actions.submit"), onAction: handleSubmit, loading: loading
              }}
              sectioned>
              <Form onSubmit={handleSubmit}>
                <TextField
                  id="national_id"
                  value={form.national_id}
                  label={ I18n.t("activerecord.attributes.courier.national_id") }
                  placeholder={"0000"}
                  onChange={handleChange}
                />
                <TextField
                  id="uid"
                  value={form.uid}
                  label={ I18n.t("activerecord.attributes.courier.uid") }
                  placeholder={"0000"}
                  onChange={handleChange}
                  maxLength={ uidMaxLength }
                />
              </Form>
            </Card>
            { !!toast ? <Toast content={toast} onDismiss={onDismiss} />  : null }
          </div>
        </Page>
      </Frame>
    </AppProvider>
  )
}

CourierLogin.propTypes = propTypes;

export default CourierLogin;