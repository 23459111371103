import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import FormikInput from '@components/shared/ui/FormikInput';
import { Select, SkeletonPage, SkeletonDisplayText, Layout, TextStyle } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';

import PolarisMediaDropzone from '@components/shared/ui/PolarisMediaDropzone';

import { gql, useQuery } from '@apollo/client';
import { base64FileReader } from '@utils/index';

const propTypes = {

}

const defaultProps = {

}

const TicketDataStep = (props) => {
  const { data, loading, error } = useQuery(CATALOGS);
  const formik = useFormikContext();

  const handleFilesChange = async (files) => {
    formik.setSubmitting(true);

    // const fileArray = Array.from(files); // Convert the FileList to an array
    const promises = files.map(file => {
      return new Promise((resolve, reject) => {
        const reader = base64FileReader(({ readerResult, mimeType, base64 }) => { // new FileReader() with onLoad parsing logic for base64.
          const mediaItem = {
            base64, mimeType, // from onLoad
            filename: file.name, // from File
          }
          resolve(mediaItem);
        });
        reader.onerror = error => reject(error);
        reader.readAsDataURL(file); // Trigger the onload event
      });
    });

    // Actual form state update
    try {
      const base64Files = await Promise.all(promises)
      const currentMedia = formik.values.media || [];
      formik.setFieldValue("media", [
        ...currentMedia,
        ...base64Files
      ]);
      formik.setFieldTouched("media", true);
    } catch (error) {
      console.error("File read error:", error)
    }
    formik.setSubmitting(false);
  };

  const handleFileDelete = (index) => {
    const currentMeddia = formik.values.media;
    const newMedia = currentMeddia.filter( (item, i) => i !== index );
    formik.setFieldValue("media", newMedia);
  }

  if(loading){
    return (
      <div className='full-width-skeleton'>
        <div className="mb24">
          <SkeletonDisplayText size="large" />
        </div>

        <div className="mb24">
          <SkeletonDisplayText size="small" />
        </div>
        
        <div className="mb24">
          <SkeletonDisplayText size="small" />
        </div>
      
        <div>
          <SkeletonDisplayText size="small" />
        </div>
      </div>
    )
  }

  if(error){
    return <h2 className='heading-2 text-error text-center'>{ error }</h2>
  }

  // ticketKind = "RETURN" | "WARRANTY"
  const getReasonOptionsForKind = (enumValues, ticketKind = "RETURN") => {
    let valuesForKind;
    if(ticketKind === "RETURN"){
      valuesForKind = enumValues.filter( item => item.name.includes("RETURN") );
    }
    else{
      valuesForKind = enumValues.filter( item => item.name.includes("WARRANTY") );
    }

    const options = valuesForKind.map( item => ({ label: I18n.t(`enum.reason.${item.name.toLowerCase()}`), value: item.name }) );

    return [
      { label: "Razón", value: "" }, // placeholder
      ...options
    ]
  }

  const reasonOptions = getReasonOptionsForKind(data.reasonEnum.enumValues, formik.values.ticketKind);

  const resolutionPreferenceOptions = [
    { label: "Método de reintegro", value: "" }, // placeholder
    ...data.resolutionPreferenceEnum.enumValues.map( item => ({ 
      label: I18n.t(`enum.resolution_preference.${item.name.toLowerCase()}`),
      value: item.name 
    }))
  ]

  return (
    <div>
      <div className="mb24">
        <Select
          name="reason"
          label='¿Por qué quisieras devolver el producto?'
          options={reasonOptions}
          value={formik.values.reason}
          onChange={ (value) => formik.setFieldValue("reason", value) }
          onBlur={formik.handleBlur}
          error={!!formik.touched.reason && formik.errors.reason}
        />
      </div>

      <FormikInput 
        name="description"
        label="Compartí un resumen del fallo o motivo de devolución"
        placeholder="..."
        className='polaris-input-multiline-container mb24'
        multiline
      />

      <div className='mb24'>
        <PolarisMediaDropzone
          files={ formik.values.media }
          loading={ formik.isSubmitting }
          label="Subí fotos o video como evidencia"
          onChange={handleFilesChange}
          onClearAll={ () => formik.setFieldValue("media", [])}
          onDelete={ handleFileDelete }
          multiple
        />
        <TextStyle variation='subdued'>
          Haga clic para subir fotos o videos
        </TextStyle>

        { !!formik.touched.media && !!formik.errors.media &&
          <div className='flex'>
            <div className='mr4'>
              <AlertMinor color="#bf0711" fill="#bf0711" height={20} width={20} />
            </div>
            <TextStyle variation='negative'>
              { formik.touched.media && formik.errors.media }
            </TextStyle>
          </div>
        }
      </div>

      <div>
        <Select
          name="resolutionPreference"
          label='¿Idealmente como quisieras el reintegro?'
          options={resolutionPreferenceOptions}
          value={formik.values.resolutionPreference}
          onChange={ (value) => formik.setFieldValue("resolutionPreference", value) }
          onBlur={formik.handleBlur}
          error={!!formik.touched.resolutionPreference && formik.errors.resolutionPreference }
          helpText="Intentaremos acomodar tu preferencia pero no podemos garantizar que el reintegro se realice bajo el método seleccionado."
        />
      </div>
    </div>
  )
};

const CATALOGS = gql`
query{
  reasonEnum: __type(name: "CustomerTicketReasonEnum") {
    name
    kind
    enumValues {
      name
    }
  }
	
	resolutionPreferenceEnum: __type(name: "ResolutionPreferenceEnum") {
    name
    kind
    enumValues {
      name
    }
  }
}
`;

TicketDataStep.propTypes = propTypes;
TicketDataStep.defaultProps = defaultProps;

export default TicketDataStep;