import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppProvider, Page, Button, Subheading, PageActions } from '@shopify/polaris';
import { ExportMinor, ClipboardMinor } from '@shopify/polaris-icons';
import DispatchControlOrders from './components/DispatchOrders';
import CopyToClipboardProvider from '@shared/CopyToClipboardProvider';
import axios from 'utils/axios-rails';

import reduce from 'lodash/reduce';

const propTypes = {
  dispatch_control: PropTypes.object.isRequired,
  pdfURL: PropTypes.string.isRequired,
  cancelUberDeliveryBaseURL: PropTypes.string, // For uber delivery allow to cancel orders if not delivered yet - baseURL with PLACEHOLDER_ID
}

const defaultProps = {

}

const DispatchControlShow = (props) => {
  const [loading, setLoading] = useState(false);
  const { dispatch_control } = props;

  const title = `${I18n.t("activerecord.models.dispatch_control.one")} ${dispatch_control.name}`;

  // Setup ids to copy to clipboard
  let ids = dispatch_control.dispatch_orders[0].shopify_id; // when only one.
  if (dispatch_control.dispatch_orders.length > 1) {
    ids = reduce(dispatch_control.dispatch_orders, (str, order) => {
      if (typeof (str) == "string") {
        return `${str},${order.shopify_id}`;
      }
      else {
        // str == first element on first iteration
        return `${str.shopify_id},${order.shopify_id}`;
      }
    });
  }

  // TODO Change to UI alerts instead of JS alerts
  const handleUberCancel = async (dispatchOrderId) => {
    setLoading(true);
    if(confirm(I18n.t("messages.irreversible_action"))){
      const response = await axios.post(`${props.cancelUberDeliveryBaseURL.replace("PLACEHOLDER_ID", dispatchOrderId)}`);
      try {
        if(response.data.success){
          alert("Cancelada correctamente");
          location.reload(); // Not efficient but was the faster way at the time
        } 
        else{
          alert(`Ocurrio un error al cancelar: ${response.data.message}`)
        }
      } catch (error) {
        console.log("Error", error);
        alert("Ocurrio un error en el sistema");
      }
    }
    setLoading(false);
  };

  return (
    <AppProvider>
      <CopyToClipboardProvider
        value={ids}
      >
        {({ copyToClipboard }) =>
          <Page
            fullWidth
            title={ title }
            titleMetadata={
              <span>{dispatch_control.created_at_text}</span>
            }
            subtitle={
              <strong>
                {I18n.t("activerecord.models.team", { count: 1 })}: {dispatch_control.team.name}
                { !!dispatch_control.uber_vehicle &&
                  <>
                    <br/>
                    <span>Uber: {I18n.t(`enum.uber_vehicle.${dispatch_control.uber_vehicle}`)} </span>
                  </>
                }

              </strong>
            }
            secondaryActions={[
              { content: "Copiar IDs", icon: ClipboardMinor, onAction: copyToClipboard },
              { content: "Exportar PDF", icon: ExportMinor, url: `${props.pdfURL}.pdf`, external: true },
            ]}
            breadcrumbs={[{ content: I18n.t("actions.back"), url: '/admin/dispatch_controls' }]}>
            <div className="mt20 mb20">
              <DispatchControlOrders 
                isShow
                onUberCancel={handleUberCancel}
                orders={dispatch_control.dispatch_orders} 
                uber={dispatch_control.team.uber}
                loading={loading}
              />
            </div>
          </Page>
        }
      </CopyToClipboardProvider>
    </AppProvider>
  )
}

DispatchControlShow.propTypes = propTypes;
DispatchControlShow.defaultProps = defaultProps;

export default DispatchControlShow;
