import React, { useState, useCallback, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';

import { Modal } from '@shopify/polaris';

const propTypes = {
  initialOpen: PropTypes.bool,
  // loading: PropTypes.bool,

  errors: PropTypes.array,
  title: PropTypes.string,
  onClose: PropTypes.func, // Override default close action
  
  primaryAction: PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  secondaryActions: PropTypes.arrayOf(PropTypes.shape({
    content: PropTypes.string,
    onAction: PropTypes.func,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  })),
}

const defaultProps = {
  initialOpen: false,
  errors: [],
}

/**
 * @type React.ForwardRefRenderFunction<React.FunctionComponent, propTypes>
 */
const GeneralModal = forwardRef( (props, ref) => {
  const { children, initialOpen, ...modalProps } = props;
  const [open, setOpen] = useState(initialOpen);
  
  const openModal = useCallback( () => setOpen(true), []);
  const closeModal = useCallback( () => setOpen(false), []);

  useImperativeHandle(ref, () => ({
    open: openModal,
    close: closeModal, 
  }));
  
  return (
    <Modal
      open={ open }
      onClose={ props.onClose || closeModal }
      {...modalProps}
    >
      <Modal.Section>
        { children }
      </Modal.Section>
    </Modal>
  )
});

GeneralModal.propTypes = propTypes;
GeneralModal.defaultProps = defaultProps;

export default GeneralModal;