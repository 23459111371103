import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { SkeletonPage, Layout, Card, TextContainer, SkeletonBodyText, SkeletonDisplayText } from '@shopify/polaris';

const propTypes = {
  secondary: PropTypes.bool,
  fullWidth: PropTypes.bool
};

const defaultProps = {
  secondary: false,
  fullWidth: true
}

const PageSkeleton = (props) => (
  <SkeletonPage fullWidth={ props.fullWidth }>
    <Layout>
      <Layout.Section>
        <Card sectioned>
          <SkeletonBodyText />
        </Card>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
        <Card sectioned>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
      </Layout.Section>
      { props.secondary && 
        <Layout.Section secondary>
          <Card>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={1} />
            </Card.Section>
          </Card>
          <Card subdued>
            <Card.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </Card.Section>
            <Card.Section>
              <SkeletonBodyText lines={2} />
            </Card.Section>
          </Card>
        </Layout.Section>
      }
    </Layout>
  </SkeletonPage>
)

PageSkeleton.propTypes = propTypes;
PageSkeleton.defaultProps = defaultProps;

export default PageSkeleton;