// Placeholder for an idea of a Wrapper with render props to recycle handleChange and other stuffs.
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withFormik } from 'formik';
import { AppProvider, Page, Toast, Frame } from '@shopify/polaris';
import esTranslations from '@shopify/polaris/locales/es.json';

import { pick } from 'lodash';

import axiosRails from '@utils/axios-rails';


const propTypes = {
  defaultValues: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.number, PropTypes.arrayOf(PropTypes.node),]).isRequired,

  handleSubmit: PropTypes.func, // Default submit requires  submitURL and submitMethod
  submitURL: PropTypes.string,
  submitMethod: PropTypes.string,
  paramsName: PropTypes.string.isRequired,
  pickValuesCallback: PropTypes.func, // Be able to change submitValues just before the submittion. (useful for special change on structure like images or nested attributes)

  title: PropTypes.string,
  pageProps: PropTypes.object, // Page Wrapper props
  debug: PropTypes.bool,
};

const defaultProps = {
  title: "",
  pageProps: {},
  submitMethod: "POST",
  paramsName: "data"
}

class PolarisFormProvider extends Component {
  render() {
    const formikStatus = this.props.status;
    let renderProps = {
      ...pick(this.props, ['setFieldValue', 'values', 'handleSubmit', 'errors', 'status', 'resetForm']),
      handleChange: this.handlePolarisChange,
      handleChangeNumber: this.handlePolarisChangeNumber,
      toggleValue: this.toggleValue,
      pushNewValue: this.pushNewValue,
      pushToast: this.pushToast,
    }
    if(this.props.debug){
      console.group("PolarisFormProvider")
      console.log("PolarisFP", this.props);
      console.log("PFP values", this.props.values);
      console.log("Errors", this.props.errors)
      console.groupEnd("PolarisFormProvider")
    }
    if(!this.props.handleSubmit && !this.props.paramsName){
      console.warn("Default submit requires params name")
    }
    return (
      <AppProvider i18n={esTranslations}>
        <Frame>
          <Page
            title={this.props.title}
            {...this.props.pageProps}
          >
            {this.props.children(renderProps)}

            { formikStatus.showToast ?
              <Toast
                content={formikStatus.toastContent}
                onDismiss={this.closeToast} />
              : null
            }
          </Page>
        </Frame>
      </AppProvider>
    );
  }

  handlePolarisChange = (value, id) => {
    // console.log(`Change ${id} into ${value}`)
    let { setFieldValue } = this.props;
    setFieldValue(id, value);
  }

  // Cast into Number (SUPER useful for Polaris Select that always turn ids into strings)
  handlePolarisChangeNumber = (value, id) => {
    let { setFieldValue } = this.props;
    setFieldValue(id, Number(value));
  }

  toggleValue = (field) => {
    let { values, setFieldValue } = this.props;
    setFieldValue(field, !values[field]);
  }

  pushNewValue = (name, newRecord) => {
    let { setFieldValue, values } = this.props;
    let currentArray = values[name];
    let newArray = [
      ...currentArray,
      newRecord,
    ]
    console.log("Push new value", newRecord);
    setFieldValue(name, newArray);
  }

  pushToast = (message) => {
    const { status, setStatus } = this.props;
    setStatus({
      ...status,
      showToast: true,
      toastContent: message
    })
  }

  closeToast = () => {
    const { status, setStatus } = this.props;
    setStatus({
      ...status,
      showToast: false,
    })
  };
}


PolarisFormProvider.propTypes = propTypes;
PolarisFormProvider.defaultProps = defaultProps;

const FormWithFormik = withFormik({
  mapPropsToValues: (props) => {
    return props.defaultValues;
  },

  mapPropsToStatus: (props) => ({
    submitLoading: false,
    showToast: false,
    toastContent: "",
  }),

  enableReinitialize: true,

  handleSubmit: async (values, formikBag) => {
    const { setStatus } = formikBag;

    if (formikBag.props.handleSubmit) {
      await formikBag.props.handleSubmit(values, formikBag); // <======================= customHandleSubmit
    }
    else {
      // Default Submit behavior
      setStatus({ submitLoading: true });
      let submitValues;
      submitValues = {
        [formikBag.props.paramsName]: values
      }

      // Default handleSubmit
      console.log("SUBMIT!", submitValues);
      let { submitMethod, submitURL } = formikBag.props;

      if(formikBag.props.pickValuesCallback){
        submitValues = formikBag.props.pickValuesCallback(submitValues);
      }

      // Might need a try/catch here
      let response = await axiosRails({
        method: submitMethod,
        data: submitValues,
        url: submitURL,
      });

      let { data, status } = response;

      if (data.success) {
        formikBag.setStatus({
          submitLoading: false,
          showToast: true,
          toastContent: I18n.t("messages.save_success")
        });
        window.location = data.redirectURL;
        return true;
      }
      else {
        console.warn(data);
        console.log("Errors", data.errors);

        formikBag.setStatus({
          submitLoading: false,
          showToast: true,
          toastContent: I18n.t("messages.record_errors"),
        })
        formikBag.setErrors(data.errors);
      }

    }
  }
})(PolarisFormProvider)


export default FormWithFormik;
