import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DescriptionList ,ResourceItem, Avatar, TextStyle, Collapsible, TextContainer, 
         Button, Badge } from '@shopify/polaris';
         
import { statusVariations } from 'utils';
import { orderProviderDataShape } from 'utils/shapes';
import OrderItemAddress from './components/OrderItemAddress';
  
const propTypes = {
  onClick: PropTypes.func,
  compact: PropTypes.bool, // Change information layout a bit.
  shorcutActions: PropTypes.array, // prop of resource item
  deliveryAction: PropTypes.object, // Special action only visible when order is "assigned"
  unassignAction: PropTypes.func,
  issueEmail: PropTypes.func,
  admin: PropTypes.bool.isRequired, // Allow to display #show link

  order: PropTypes.shape({
    id: PropTypes.number,
    provider_data: orderProviderDataShape,
  }).isRequired,
}

const defaultProps = {
  shorcutActions: [],
  // unassignAction: null
}


const OrderItem = (props) => {
  const [open, setOpen] = useState(false)
  const { id, order_number, status, delivered_at, delivered_at_human, recipient_name, team_name, courier_name, provider_name } = props.order;

  let shorcutActions = props.shorcutActions;

  const isShopifyProvider = provider_name == "shopify";

  const generateDescriptionItems = (orderData) => {
    const providerAddress = orderData.provider_data && orderData.provider_data.shipping_address;
    let descriptionItems = [];
    if(!!providerAddress){
      descriptionItems = [
        { term: I18n.t(`shopify.order.shipping_address.name`), description: providerAddress.name },
        { term: I18n.t(`shopify.order.shipping_address.address`), description: providerAddress.address },
        { term: I18n.t(`shopify.order.shipping_address.province`), description: providerAddress.province },
        { term: I18n.t(`shopify.order.shipping_address.canton`), description: providerAddress.canton },
        { term: I18n.t(`shopify.order.shipping_address.district`), description: providerAddress.district },
        { term: I18n.t(`shopify.order.shipping_address.phone`), description: providerAddress.phone },
        { term: I18n.t(`shopify.order.shipping_address.national_id`), description: providerAddress.national_id },
      ]
    };

    descriptionItems = [
      ...descriptionItems,
      { term: I18n.t("activerecord.attributes.order.recipient_name"), description: orderData.recipient_name },
      { term: I18n.t("activerecord.attributes.order.delivered_at"), description: orderData.delivered_at_human },
    ];

    return descriptionItems;
  }

  const descriptionItems = useMemo( () => generateDescriptionItems(props.order), [props.order.provider_data] );

  // Shorcut actions by default doesn't has access to resourceitem object. deliveryAction function is overriden calling it with the item information to make up for it and added as a shorcut action
  if(!!props.deliveryAction && status == "assigned"){
    shorcutActions = [
      { ...props.deliveryAction, primary: true , onAction: () => props.deliveryAction.onAction(props.order) },
      ...shorcutActions // Other normal shorcut actions
    ]
  }

  // Functions
  const onItemClick = () => {
    setOpen(!open);
    // should i use onClick callback? props.onClick
  }

  return(
    <ResourceItem
      id={`${props.order.typename}-${id}`}
      url={""}
      external
      shortcutActions={ shorcutActions }
      accessibilityLabel={`View details for ${'user'}`}
      onClick={ onItemClick }
    >
      <div className="flex justifyBetween">

        {/* When compact. Everything gets into two flex columns. All info left and Right only status. Non compact has extra left column only for order number */}
        <div className={ props.compact ? `` : `flex` }>

          <div>
            <h3>
              <TextStyle variation="strong">
                {order_number}
              </TextStyle>
            </h3>
            <p>
              <TextStyle variation={ statusVariations[status] }>
                {I18n.t(`enum.status.${status}`)}
              </TextStyle>
            </p>
          </div>

          <OrderItemAddress providerData={ props.order.provider_data } />

        </div>

        { !props.compact && !!team_name && 
          <div>
            <p>{ team_name}</p>
            <p>{ courier_name }</p>
          </div>
        }
      </div>

      {/* Collapsible */}
      <Collapsible open={open} id="basic-collapsible">
        <div>
          { descriptionItems.length > 0 ?
              <DescriptionList
                items={descriptionItems}
              />
            :
            <TextContainer>
              Dirección vacía.
            </TextContainer>
          }
          <div className="flex order-item-actions mt4">
            { props.unassignAction && status == "assigned" && 
              <div className="mr8">
                <Button 
                  destructive
                  onClick={ () => props.unassignAction(props.order) }>
                  Desasignar
                </Button>
              </div>
            }
            { props.issueEmail && !!props.order.issue_email_url &&
              <div className="mr8">
                <Button
                  onClick={ () => props.issueEmail(props.order) }>
                  Reenviar email
                </Button>
              </div>
            }
            {props.admin &&
              <div>
                <Button
                    primary={true}
                    external
                    url={`/orders/${props.order.id}`}>
                  Ver más detalles
                </Button>
              </div>
            }
          </div>
        </div>
      </Collapsible>

    </ResourceItem>
  )
}


OrderItem.propTypes = propTypes;
OrderItem.defaultProps = defaultProps;

export default OrderItem;
