import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { messageTemplateColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';


const propTypes = {
  records: PropTypes.array.isRequired,
}

const defaultProps = {

}

const MessageTemplateIndex = (props) => {
  return (
    <FilterTable
      pageProps={{
        title: I18n.t("activerecord.models.message_template", { count: 2 }),
        primaryAction: { content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.message_template.one") }), url: props.newUrl },
      }}
      columnsInfo={ messageTemplateColumns }
      resourceTableProps={{
        urlAction: props.urlAction
      }}
      {...props}
    />
  )
};

MessageTemplateIndex.propTypes = propTypes;
MessageTemplateIndex.defaultProps = defaultProps;

export default withPagination(MessageTemplateIndex, {
  mapPropsToInitialFilters: (props) => ({}),
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "filters",
  filterURL: "/messge_templates",
});