import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { 
  AppProvider, Page, Card, DescriptionList, Banner, Layout, Badge, Frame, Select, 
  Toast, Spinner, Thumbnail, Link
} from '@shopify/polaris';

import { ApolloProvider, gql, useQuery, useMutation } from '@apollo/client';
import { adminClient, graphqlEnumToOptions, userErrorsToFormik } from 'api/index';

import PageSkeleton from './PageSkeleton';
import snakeCase from 'lodash/snakeCase';

import PolarisBooleanBadge from '@components/shared/ui/PolarisBooleanBadge';

import CustomerDataSection from './components/CustomerDataSection';
import CustomerTicketActions from './components/CustomerTicketActions';
import ResourceLogList from '@components/views/orders/components/ResourceLogList';
import CustomerTicketComments from './components/CustomerTicketComments';
import CustomerTicketAdminMedia from './components/CustomerTicketAdminMedia';

import RelatedRecordsSection from './components/RelatedRecordsSection';

import { polarisBuildListItems } from 'utils/index';

const propTypes = {
  recordId: PropTypes.number.isRequired,
}

const defaultProps = {

}

const CustomerTicketDetails = (props) => {
  const [toast, setToast] = useState(null);
  const [supplierStatusValue, setSupplierStatusValue] = useState(undefined);

  // =================== API Hooks ==========================
  const { data: dataCatalogs, loading: loadingCatalogs } = useQuery(ENUM_CATALOGS);
  const { data, loading, error, refetch } = useQuery(RECORD_GET, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.recordId
    }
  });

  const [recordUpdate, { loading: loadingUpdate }] = useMutation(RECORD_UPDATE, {
    onCompleted: (dataUpdate) => {
      const customerTicketUpdated = dataUpdate.customerTicketUpdate.customerTicket;
      if(customerTicketUpdated?.id){
        setToast(I18n.t("messages.update_successful"));
        refetch();
      }
      else{
        setToast("An error ocurred updating the supplier status");
        setSupplierStatusValue(data.customerTicket.supplierStatus); // Revert value
      }
    }
  });

  // =======================================================

  // supplierStatus updatable select requires controlled value
  useEffect(() => {
    if(data){
      setSupplierStatusValue(data.customerTicket.supplierStatus || ""); // Set default value
    }
  }, [data?.customerTicket?.supplierStatus]);

  // >>> First loading <<< 
  // Load skeleton if required data hasn't loaded at least once
  if(!data || loadingCatalogs){
    return <PageSkeleton />
  }

  const handleSupplierStatusChange = (value) => {
    setSupplierStatusValue(value);
    recordUpdate({
      variables: {
        id: props.recordId,
        input: {
          supplierStatus: !!value ? value : null
        }
      }
    })
  };

  const customerTicket = data.customerTicket;
  const actionsLoading = loading || loadingUpdate; // Loader on top of content

  const adminDataItems = polarisBuildListItems([
    // requireItems - Only display if true or when ticket has been approved/rejected meaning requiral has been explicitly set.
    // Not shown from the start to avoid confusion as "No" required can be interpreted as not required but also as not set.
    { 
      name: "requireItems", 
      accessor: (value) => <PolarisBooleanBadge value={value} />, 
      hideIf: (value, record) => !record.requireItemsDisplayable // Display if on any status after NEW_TICKET or its equivalent)
    },
    { name: "itemsReceived", accessor: (value) => <PolarisBooleanBadge value={value} /> },
    { name: "resolutionAction", accessor: (value) => value && I18n.t(`enum.resolution_action.${snakeCase(value)}`)},
    { name: "brightpearlSalesCreditId", hideIfEmpty: true, accessor: (value, record) => 
      <Link external url={record.brightpearlSalesCreditUrl}>{value}</Link>
    },
    { name: "supplierStatus", accessor: (value) => {
      if(customerTicket.supplierStatusActionable){
        return (
          <Select
            value={supplierStatusValue}
            options={graphqlEnumToOptions({ 
              enumValues: dataCatalogs.supplierStatusEnum.enumValues, 
              enumName: "supplier_status",
              prependPlaceholder: true
            })}
            onChange={ handleSupplierStatusChange }
            disabled={ actionsLoading }
          />
        )
      }
      else{
        return value && I18n.t(`enum.supplier_status.${snakeCase(value)}`);
      }
    }},
    { name: "createdAt", accessor: (value) => I18n.l("time.formats.app_datetime", value) },
    { name: "rejectReason", accessor: (value) => value, hideIfEmpty: true},
  ], customerTicket);

  return (
    <div className="polaris-page-customer-ticket-details">
      <Page
        fullWidth
        title={
          <>
            <span>{ customerTicket.name }</span>
            <span className='ml8'>{ actionsLoading && <Spinner color='teal' size="small" /> }</span>
          </>
        }
        // Right side actions (Current polaris version do not support natively multiple actions on the right)
        // Modal with buttons
        titleMetadata={
          <CustomerTicketActions
            customerTicket={customerTicket}
            onToast={setToast}
            onRefetch={refetch}
          />
        }
        breadcrumbs={[{ content: I18n.t("app.customer_tickets.title"), url: "/customer_tickets" }]}
      >
        <Layout>
          { !!toast && <Toast content={toast} onDismiss={() => setToast(null)} /> }

          {/* Left section - Customer, Attachments, Logs, etc */}
          <Layout.Section>
            <Card>
              <Banner
                title={ I18n.t(`enum.status.${snakeCase(customerTicket.status)}`) }
                status={ statusToBannerStatus[customerTicket.status] }
              >
              </Banner>
      
              {/* Customer data and attachments */}
              <CustomerDataSection customerTicket={customerTicket} />
            </Card>

            <CustomerTicketComments
              customerTicketId={customerTicket.id}
              onToast={setToast}
              as={ ({ children }) =>
                <Card>
                  <Card.Section title="Comentarios">
                    { children }
                  </Card.Section>
                </Card>
              }
            />

            { customerTicket.resourceLogs.length > 0 &&
              <Card>
                <Card.Section title="Logs">
                  <ResourceLogList
                    logs={customerTicket.resourceLogs}
                    isGql
                    withCard={false}
                  />
                </Card.Section>
              </Card>
            }
          </Layout.Section>

          {/* Right section - Admin and related records info */}
          <Layout.Section secondary>
            <Card>
              <Card.Section title="Admin">
                <DescriptionList
                  items={adminDataItems}
                />
              </Card.Section>
            </Card>

            <Card>
              <RelatedRecordsSection customerTicket={customerTicket} />
            </Card>

            <CustomerTicketAdminMedia
              customerTicketId={customerTicket.id}
              onToast={setToast}
              as={ ({ children, primaryFooterAction }) =>
                <Card primaryFooterAction={primaryFooterAction}>
                  <Card.Section title="Admin media">
                    { children }
                  </Card.Section>
                </Card>
              }
            />
          </Layout.Section>
        </Layout>
      </Page>
    </div>
  )
};

const CustomerTicketDetailsWithApollo = (props) => {
  return (
    <ApolloProvider client={adminClient}>
      <AppProvider>
        <Frame>
          <CustomerTicketDetails {...props} />
        </Frame>
      </AppProvider>
    </ApolloProvider>
  )
};

const RECORD_GET = gql`
  fragment TeamData on Team {
    id
    name
  }

  fragment CourierData on Courier{
    id
    name
    team{
      ...TeamData
    }
  }

  query($id: ID!){
    customerTicket(id: $id) {
      id
      name
      customerKind
      providerName
      providerCustomerId
      customerEmail
      customerName
      providerLineItemId
      moneyProductOriginalTotal
      productTitle
      productSku
      productImageUrl
      ticketKind
      reason
      description
      resolutionPreference
      retrievalMethod
      retrievalNotes
      retrievalDate
      address
      brightpearlSalesCreditId

      status
      requireItems
      itemsReceived
      resolutionAction
      supplierStatus
      createdAt
      rejectReason

      media{
        url
        identifier
        contentType
      }

      resourceLogs{
        id
        displayMessage
        eventDatetime
        notes
      }

      relatedDeliveryRecordType
      relatedDeliveryRecordId

      displayProviderOrderId
      providerOrderUrl
      brightpearlSalesCreditUrl
      approvable
      rejectable
      escalable
      resolutionActionable
      supplierStatusActionable
      readOnly
      requireItemsDisplayable

      task{
        id
        name
        status
        createdAt
        adminUrl
        courier{
          ...CourierData
        }
      } # task

      relatedDeliveryRecord{
        ... on Order{
          id
          orderNumber
          adminUrl

          courier{
            ...CourierData
          }
        } # on Order

        ... on DispatchOrder{
          id
          name
          orderNumber

          dispatchControl{
            id
            name
            team{
              ...TeamData
            }
          }
        } # on DispatchOrder
      } # relatedDeliveryRecord
      
    } # customerTicket
  }
`;

const RECORD_UPDATE = gql`
mutation($id: ID!, $input: CustomerTicketUpdateInput!){
  customerTicketUpdate(id: $id, input: $input) {
    customerTicket {
      id
      supplierStatus
    }
    errors {
      key
      message
    }
  }
}
`;

const ENUM_CATALOGS = gql`
query{
  supplierStatusEnum: __type(name: "CustomerTicketSupplierStatusEnum") {
    name
    kind
    enumValues {
      name
    }
  }
}
`;

const statusToBannerStatus = {
  "NEW_TICKET": "",
  "REJECTED": "critical",
  "APPROVED": "info",
  "APPROVED_AWAITING_ITEMS": "info",
  "WAITING_RESOLUTION": "info",
  "PROCESSING_RESOLUTION": "info",
  "ESCALATED": "warning",
  "APPROVED_AND_COMPLETED": "success",
  "ESCALATED": "warning",
}

CustomerTicketDetails.propTypes = propTypes;
CustomerTicketDetails.defaultProps = defaultProps;

export default CustomerTicketDetailsWithApollo;