import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { TextField } from '@shopify/polaris';

const propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string
}

const defaultProps = {
  className: 'input-container'
}

const FormikInput = (props) => {
  const { name, className, ...inputProps } = props;
  const formik = useFormikContext();

  const handleChange = (value) => {
    formik.setFieldValue(name, value);
  }

  return (
    <div className={className}>
      <TextField
        name={name}
        onChange={handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name]}
        error={formik.touched[name] && formik.errors[name]}
        {...inputProps}
      />
    </div>
  )
};

FormikInput.propTypes = propTypes;
FormikInput.defaultProps = defaultProps;

export default FormikInput;