import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AppProvider, TextField, Page, Card,Button, Toast, Frame } from '@shopify/polaris';
import { ta } from 'utils';

const csrfToken = document.querySelector("meta[name=csrf-token]").content;

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  loginUrl: PropTypes.string.isRequired
};

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false);
  const [toast, setToast]= useState(props.errors.email ? props.errors.email[0] : "")

  const htmlForm = useRef(null);
  const submitForm = () => {
    setLoading(true);
    htmlForm.current.submit();
  }
  const dismissToast = () => setToast(false);
  return (
      <AppProvider>
        <Frame>
          <Page
              title="¿Olvidaste tu contraseña?"
          >
            <Card sectioned>
              <form
                ref={ htmlForm}
                action={ props.submitURL }
                method="post"
              >
                <TextField
                    label={ ta("user.email") }
                    placeholder="foo@bar.com"
                    name="user[email]"
                    value={ email }
                    onChange={ (value, id) => setEmail(value) }
                />
                <div  className="flex flexColumn justifyCenter mt4">
                  <Button
                      primary
                      submit
                      fullWidth
                      loading={loading}
                      onClick={ submitForm }
                  >
                    Enviar
                  </Button>
                </div>
                <input type="hidden" name="authenticity_token" value={csrfToken} />
              </form>
            </Card>
            <div>
              { toast &&
                <Toast content={toast} onDismiss={dismissToast} duration={3000}  />
              }
            </div>
          </Page>
        </Frame>
      </AppProvider>
  );

}

ForgotPassword.propTypes = propTypes;


export default ForgotPassword;
