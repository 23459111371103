import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { clsx } from 'clsx';
import { RadioButton } from '@shopify/polaris';

import ReturnIcon from '@components/../../assets/images/icons/customer_tickets/return.svg';
import WarrantyIcon from '@components/../../assets/images/icons/customer_tickets/warranty.svg';
import FallbackProductImg from '@components/../../assets/images/fallback-product.png';

import { useFormikContext } from 'formik';
import SquareBoxRadioButtons from './SquareBoxRadioButtons';

const propTypes = {

}

const defaultProps = {

}

const ItemIdentificationStep = (props) => {
  const formik = useFormikContext();

  const handleCustomerKindChange = (value) => {
    formik.setFieldValue("ticketKind", value);
  }

  const handleLineItemChange = (lineItem) => {
    formik.setValues({
      ...formik.values,
      providerLineItemId: lineItem.providerId,
      providerProductId: lineItem.productProviderId,
      productTitle: lineItem.title,
      productSku: lineItem.sku,
      productImageUrl: lineItem.imageUrl,
      productOriginalTotal: lineItem.productOriginalTotal,
      currencyCode: lineItem.currencyCode,
    })
  }

  return (
    <div id="item-identification-step">
      <SquareBoxRadioButtons
        className="mb20 lg:mb40"
        value={formik.values.ticketKind}
        onClick={handleCustomerKindChange}
        options={[
          {
            title: 'Devolución',
            description: "Sólo producto en condiciones originales en plazo de 4 días después de recibir.",
            value: "RETURN",
            icon: ReturnIcon,
            className: "gradient-return",
          },
          {
            title: 'Garantía',
            description: "Reclamos por defectos de fábrica validos durante periodo de garantía del producto",
            value: "WARRANTY",
            icon: WarrantyIcon,
            className: "gradient-warranty"
          }
        ]}
        error={!!formik.touched.ticketKind && formik.errors.ticketKind}
      />

      <div className='mb12'>
        <p className="heading-4 mb4">
          Seleccionar producto
        </p>

        <p className="text-neutral-gray-200">
          { I18n.t("app.customer_tickets.form.item_selection_hint") }
        </p>
      </div>

      <LineItemRadioButtons 
        value={formik.values.providerLineItemId}
        onClick={handleLineItemChange}
        lineItems={ formik.values.formOrder.lineItems }
      />
    </div>
  )
};

// lineItem: { providerId, imageUrl, title, productType, productProviderId }
const LineItemRadioButtons = ({ lineItems, onClick, value }) => {
  return(
    <div className="square-box-radio-buttons line-items-radio-buttons">
      { lineItems.map((lineItem, index) => {
        const handleOptionClick = () => { onClick(lineItem) };
        const active = value === lineItem.providerId;
        return(
          <div key={lineItem.providerId} className={clsx("square-box-option flex1 pv12 ph20 flex alignItemsCenter relative mb12", active && "active" )} onClick={handleOptionClick}>
            <img width={64} height={64} src={ lineItem.imageUrl || FallbackProductImg } className="mr12" />

            <div className='flex1 mr12'>
              { lineItem.productType &&
                <p className="text-neutral-gray-200 uppercase">
                  { lineItem.productType }
                </p>
              }
              <p>{ lineItem.title }</p>
            </div>

            <RadioButton 
              checked={ active }
              onChange={ handleOptionClick }
            />
          </div>
        )
      } )}
    </div>
  )
}

ItemIdentificationStep.propTypes = propTypes;
ItemIdentificationStep.defaultProps = defaultProps;

export default ItemIdentificationStep;