import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { ResourceList, ResourceItem, Button, ButtonGroup, Icon } from '@shopify/polaris';
import { SortAscendingMajorTwotone, SortDescendingMajorMonotone, SortMinor } from '@shopify/polaris-icons';

import { renderAccessorValue } from '@utils/columns';

const propTypes = {
  
  items: PropTypes.array.isRequired,
  itemURL: PropTypes.string,
  itemURLAsAction: PropTypes.bool,
  urlAction: PropTypes.string,
  urlParams: PropTypes.string,

  columnsInfo: PropTypes.array.isRequired,
  conditionalRowClass: PropTypes.func,

  // resourceListProps: ResourceList.propTypes,
  resourceListProps: PropTypes.object,
  onDelete: PropTypes.func,

  accessorUtils: PropTypes.object, // Anything to pass to renderAccessorValue which in turn is received on columns information
  loading: PropTypes.bool,
  withFilters: PropTypes.bool, // Render columnsInfo headers as a row inside ResourceList, if not, the headers will appear before the open search bar and look weird. This should be the standard way for new components.
  sort: PropTypes.shape({
    value: PropTypes.string, // as backend command
    order: PropTypes.oneOf(['asc', 'desc']),
  }),
  onSort: PropTypes.func,
};
const defaultProps = {
  resourceListProps: {},
  itemURLAsAction: false,
  urlParams: "",
  shortcutActions: [],
  loading: false,
  withFilters: false,
  accessorUtils: {},
}


// If possible for new components use `PolarisRecordsTable` instead, DataTable handle columns spacing better and the component is more simpler to allow customizations by parent instead of the list component itself (this one got too complex trying to handle too many cases itself)
const IndexResourceList = (props) => {

  const handleSort = useCallback((sortKey, sortDirection) => {
    if(!!sortDirection){
      props.onSort(sortKey, sortDirection);
    }
    else{ // Remove sort
      props.onSort(null, null);
    }
  }, []);

  const getNextSortDirection = useCallback((sortKey, currentSortKey, currentSortDirection) => {
    console.log(sortKey, currentSortKey, currentSortDirection);
    if(!sortKey){
      return null; // No sort
    }

    if(currentSortKey !== sortKey){
      return 'desc'; // Default first sort direction (from newer to older)
    }
    else if(currentSortDirection === 'desc'){
      return 'asc';
    }
    else{
      return null; // Remove sort
    }
  }, []);

  const listHeaders = (
    <div className={`container-fluid pt16 pb16 resource-header`}>
      <div className={`row ${props.resourceListProps.onSelectionChange ? 'ml35' : ''}`}>
        {props.columnsInfo.map((col, index) => {
          const isSortable = !!col.sortable_key;
          const nextSortDirection = isSortable ? getNextSortDirection(col.sortable_key, props.sort?.value, props.sort?.order) : undefined;
          return(
            <div key={index} className={ `col-12 col-md-${col.size} `}>
              <div className='flex alignItemsCenter header-cell'>
                <span className={ isSortable ? 'mr4' : '' }>
                  {col.header}
                </span>
                { isSortable &&
                  <div className="flex sort-button">
                    <Button
                      plain
                      primary={props.sort.value === col.sortable_key}
                      onClick={() => handleSort(col.sortable_key, nextSortDirection)}
                    >
                      <Icon
                        source={ (props.sort.value != col.sortable_key) ? SortMinor : props.sort.order === 'asc' ? SortAscendingMajorTwotone : SortDescendingMajorMonotone }
                      />
                    </Button>
                  </div>
                }
              </div>
            </div>
          )
        }
        )}
      </div>
    </div>
  );

  const items = props.withFilters ? [{ headers: true }, ...props.items] : props.items; // Add a fake item to show headers if withFilters is true

  return(
    <div className={`index-resource-list`}>

      {/* Old way to show headers for "table", these are disconnected from items */}
      { !props.withFilters && listHeaders }

      <ResourceList
        items={items}
        renderItem={(item) => {

          // Special render for headers row
          if(item.headers){
            return(
              <div
                id="headers"
              >
                { listHeaders }
              </div>
            )
          }

          // Normal render
          const urlAction = !!props.urlAction ? `/${props.urlAction}` : ""; // TODO use a replace tactic instead like "/:id/edit", "/:id", etc and then replace :id with item.id
          const fullURL = !!props.itemURL ? `${props.itemURL}/${item.id}${urlAction}${props.urlParams}` : null; // itemURL/ID + destroy/edit/show if present + ?foo=bar if present
          let rowClass = ""; 
          let shortcutActions = props.shortcutActions;

          if(!!props.itemURL && props.itemURLAsAction){
            shortcutActions = [
              ...shortcutActions,
              {  content: I18n.t("actions.show"), url: fullURL, }
            ]
          };

          // Using serializer value
          if(!!item.delete_url){
            shortcutActions = [
              ...shortcutActions,
              { content: I18n.t("actions.delete"), destructive: true, 
                onClick: () => props.onDelete(item, `${item.delete_url}${item.id}`),
              }
            ]
          };

          if (!!props.conditionalRowClass) {
            rowClass = props.conditionalRowClass(item);
          }
          return (
            <ResourceItem
              id={item.id}
              url={ !props.itemURLAsAction ? fullURL : null }
              shortcutActions={shortcutActions}
            >
              <div className="container-fluid p0">
                <div className={`row ${rowClass}`}>
                  { props.columnsInfo.map((col, index) =>
                    <div key={index} className={`content-cell col-12 col-md-${col.size} ${col.className || ''}`}>
                      { renderAccessorValue(item, col, props.accessorUtils) }
                    </div>
                  )}
                  {/* ShorcutActions on mobile (experimental) */}
                  {/* { shortcutActions.length > 0 &&
                    <div className='p12 hidden-desktop'>
                      <ButtonGroup>
                        { shortcutActions.map((action, index) => 
                          <Button {...action} >
                            { action.content }
                          </Button>
                        )}
                      </ButtonGroup>
                    </div>
                  } */}

                </div>
              </div>
            </ResourceItem>
          )
        }}
        {...props.resourceListProps}
        loading={props.loading}
      />
    </div>
  )
}

IndexResourceList.propTypes = propTypes;
IndexResourceList.defaultProps = defaultProps;

export default IndexResourceList;