import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Toast } from '@shopify/polaris';

const propTypes = {
  message: PropTypes.string,
  onDismiss: PropTypes.func,
}

const SharedToast = (props) => {
  return !!props.message ? (
    <Toast content={ props.message } onDismiss={ props.onDismiss } />
  ) : null
}

SharedToast.propTypes = propTypes;

export default SharedToast;