import React from 'react';

import { userColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';

const UsersIndex = (props) => {
  const primaryAction = props.newURL ? { content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.user.one") }), url: props.newURL } : null
  return (
    <FilterTable
      pageProps={{
        title: I18n.t("activerecord.models.user.other"),
        primaryAction: primaryAction
      }}
      searchName="search_query"
      columnsInfo={userColumns}
      {...props}
    />
  )
}

export default withPagination(UsersIndex , {
  mapPropsToInitialFilters: () => ({
    search_query: "",
    role: "",
  }),
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "user_filters",
  filterURL: "/admin/users/pagination",
});