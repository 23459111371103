import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Button, Popover, List, Heading, Icon } from '@shopify/polaris';
import { CaretDownMinor } from '@shopify/polaris-icons';
import { selectOptionsProp } from 'utils/shapes';

const propTypes = {
  items: selectOptionsProp.isRequired,
}

const defaultProps = {

}

const PopupItems = (props) => {
  const [popoverActive, setPopoverActive] = useState(false);

  const togglePopoverActive = useCallback( (event) => {
    !!event.stopPropagation && event.stopPropagation(); // When scrolling out of view the event is called to close popover BUT stopPropagation raises an exception "e.stopPropagation is not a function" so it is validated before calling
    setPopoverActive((popoverActive) => !popoverActive)
  }, []);


  const activator = (
    <div className="polaris-button no-underline">
      <Button plain monochrome onClick={togglePopoverActive} disclosure>
        { props.items.length } { `producto${(props.items.length > 1) ? 's' : ''}` }
      </Button>
    </div>
  );

  return (
    <div>
      <Popover
        active={ popoverActive }
        activator={ activator }
        autofocusTarget="first-node"
        onClose={ togglePopoverActive }
      >
        <div className="p12">
          <List type="bullet">
            { props.items.map( (item) =>
              <List.Item key={ item.value }>{ item.label }</List.Item>
            )}
          </List>
        </div>
      </Popover>
    </div>
  )
};

PopupItems.propTypes = propTypes;
PopupItems.defaultProps = defaultProps;

export default PopupItems;