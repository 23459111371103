import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Card, Button, ButtonGroup, Thumbnail } from '@shopify/polaris';
import { AttachmentMajorMonotone } from '@shopify/polaris-icons';
import GeneralModal from 'components/shared/GeneralModal';

const propTypes = {
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      identifier: PropTypes.string,
      contentType: PropTypes.string.isRequired,
    })
  ).isRequired,
  containerClassName: PropTypes.string,
}

const defaultProps = {
}

const CustomerTicketAttachments = (props) => {
  const [focusedMedia, setFocusedMedia] = useState(null);
  const modalRef = useRef(null);

  const handleMediaClick = (media) => {
    setFocusedMedia(media);
    modalRef.current?.open();
  }

  const { imageAttachments, otherAttachments } = props.attachments.reduce(
    (acc, mediaItem) => {
      if (mediaItem.contentType.includes('image')) {
        return { ...acc, imageAttachments: [...acc.imageAttachments, mediaItem]};
      } 
      else {
        return { ...acc, otherAttachments: [...acc.otherAttachments, mediaItem]};
      }
    },
    { imageAttachments: [], otherAttachments: [] }
  );
  
  return (
    <div className={ props.containerClassName }>
      {/* Margin left is irregular when items spans multiple rows */}
      <div className="polaris-button-group-no-margin-left">
        <ButtonGroup>
          {/* Image attachments */}
          { imageAttachments.map((media, index) => (
            <Button
              plain
              key={index}
              onClick={ () => handleMediaClick(media)}
            >
              <Thumbnail
                source={ media.url }
                alt={ media.identifier }
                size='large'
              />
            </Button>
          ))}
          {/* Other attachments */}
          { otherAttachments.map((media, index) => (
            <Button
              key={index}
              plain
              url={media.url}
              external
              icon={AttachmentMajorMonotone}
            >
              { media.identifier }
            </Button>
          ))}
        </ButtonGroup>
      </div>

      <GeneralModal
        ref={ modalRef }
        title={ focusedMedia?.identifier || "Media" }
        large
      >
        <div className='flex justifyCenter alignItemsCenter'>
          <img
            src={focusedMedia?.url}
            alt={focusedMedia?.identifier}
            style={{
              height: "auto",
              maxWidth: "100%",
              maxHeight: "80vh",
            }}
          />
        </div>
      </GeneralModal>
    </div>
  )
};

CustomerTicketAttachments.propTypes = propTypes;
CustomerTicketAttachments.defaultProps = defaultProps;

export default CustomerTicketAttachments;