import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, TextField, Form, Page, Card, Checkbox,Button, Link } from '@shopify/polaris';
import { ta } from 'utils';

const csrfToken = document.querySelector("meta[name=csrf-token]").content;


const propTypes = {
  submitURL: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  shopifyLoginURL: PropTypes.string.isRequired,
};


const SignIn = (props) => {
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback((newChecked) => setChecked(newChecked), []);
  const htmlForm = useRef(null);

  console.log(props);

  const submitForm = () => {
    setLoading(true);
    htmlForm.current.submit();
  }

  return (
    <AppProvider>
      <Page
        title="Inicio de Sesión"
      >
        <Card sectioned>

          <form
            ref={ htmlForm }
            action={ props.submitURL }
            method="post"
          >
            <TextField
              label={ ta("user.email") }
              placeholder="foo@bar.com"
              name="user[email]"
              value={ email }
              onChange={ (value, id) => setEmail(value) }
            />

            <TextField
              label={ ta("user.password") }
              placeholder="*****"
              name="user[password]"
              type="password"
              value={ password }
              onChange={ (value, id) => setPassword(value) }
            />

            <Checkbox
              name="user[remember_me]"
              label="Recuérdame"
              checked={checked}
              onChange={handleChange}
            />
            <div className="mt4 mb8">
              <Link
                  url={props.forgotPasswordLink}>
                ¿Olvidaste tu contraseña?
              </Link>
            </div>


            <input name="user[remember_me]" type="hidden" value={ checked } />
            <input type="hidden" name="authenticity_token" value={csrfToken} />

            <div className="flex flexColumn justifyCenter mt4">
              <Button 
                primary
                submit
                fullWidth
                loading={loading}
                onClick={ submitForm }
              >
                Ingresar
              </Button>

              <div className="shopifyButtonWrapper mt8">
                <Button
                  loading={loading}
                  url={ props.shopifyLoginURL }
                  fullWidth
                >
                  Ingresar con Shopify
                </Button>
              </div>
            </div>
          </form>

          {/* to add forgot password link */}

        </Card>
      </Page>
    </AppProvider>
  )
}


SignIn.propTypes = propTypes;


export default SignIn;
