import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, Form, TextField, FormLayout, Layout  } from '@shopify/polaris';
import CouriersList from '@views/couriers/components/CouriersList';
import NewCourierForm from 'components/views/couriers/components/Form';
import CourierCard from '@views/couriers/components/CourierCard';
import PolarisFormProvider from '@shared/PolarisFormProvider';

import {
  EditMajorMonotone
} from '@shopify/polaris-icons';

import axios from 'utils/axios-rails';

const propTypes = {
  page: PropTypes.object,
  couriers: PropTypes.array.isRequired,
  createCourierURL: PropTypes.string.isRequired,
  team: PropTypes.object.isRequired,
  canUpdate: PropTypes.bool,
}

const TeamShowAndEdit = (props) => {
  const [couriers, setCouriers] = useState(props.couriers);

  const [formState, setForm] = useState({
    active: false,
    action: "team"
  });
  const [selectedCourier, setSelectedCourier] = useState({});
  const [courierOrders, setCourierOrders] = useState([]);

  const submitForm = async (values, formikBag) => {
    console.log("GO", values);
    console.log(formState.action);
    const response = await axios.post(props.createCourierURL, {
      courier: {
        ...values
      }
    });
    let {data} = response;
    console.log("D", data)

    if(data.success){
      formikBag.setStatus({
        submitLoading: false,
        showToast: true,
        toastContent: I18n.t("messages.save_success")
      });
      setCouriers([
        ...couriers,
        data.record
      ]);
      setForm({
        active: false
      });
    }
    else{
      formikBag.setStatus({
        submitLoading: false,
        showToast: true,
        toastContent: I18n.t("messages.record_errors"),
      })
      formikBag.setErrors(data.errors);
    }
  }

  const selectCourier = async (courier) => {
    setCourierOrders([]);
    setSelectedCourier(courier);
    try {
      const response = await axios.get(`/teams/${props.team.id}/couriers/${courier.id}/get_orders`)
      setCourierOrders(response.data.orders);
    } catch (error) {
      alert("Ocurrio un error al traer la información de las ordenes para el mensajero.");
      console.log(error)
    }
  }

  console.log("ptp",Page.defaultProps)
  return(
    <PolarisFormProvider
      pageProps={{
        ...props.page ,
        secondaryActions: !props.canUpdate ? [] : [
          {
            content: "Editar información",
            url: props.editURL,
            icon: EditMajorMonotone
          },
        ],
      }}
      defaultValues={{
        uid: "",
        phone: "",
        user_attributes: {}
      }}
      handleSubmit={ submitForm }
    >
    { ({
      handleChange, handleSubmit, values, errors, resetForm
    }) => {
      return(
        <Layout>
          <Layout.Section>
            <div>
              <Card
                sectioned
                title={I18n.t("activerecord.models.courier.other")}
                primaryFooterAction={{
                  content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.courier.one") }),
                  disabled: formState.active,
                  onAction: () => { resetForm(); setForm({ ...formState, active: true, action: "courier" }) }
                }}
              >
                <CouriersList
                  items={couriers}
                  onClick={ (courier) => selectCourier(courier) }
                />
              </Card>

              {/* Courier Form */}
              {formState.active &&
                <NewCourierForm
                  handleChange={handleChange}
                  handleSubmit={handleSubmit}
                  values={values}
                  errors={errors}
                  secondaryFooterActions={[
                    {
                      content: I18n.t("actions.cancel"),
                      onAction: () => { resetForm(); setForm({ active: false }) }
                    }
                  ]}
                />
              }
            </div>
          </Layout.Section>

          {/* Right layout to show selected courier info*/}
          <Layout.Section secondary>
            <CourierCard
              editURL={ selectedCourier.id && `/teams/${props.team.id}/couriers/${selectedCourier.id}/edit` }
              courier={selectedCourier}
              orders={ courierOrders }
            />
          </Layout.Section>
        </Layout>
      )
    }}
  </PolarisFormProvider>
  );
}


TeamShowAndEdit.propTypes = propTypes;

export default TeamShowAndEdit;
