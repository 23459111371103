import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Toast, DataTable, Button } from '@shopify/polaris';

import axios from 'utils/axios-rails';

const propTypes = {
  webhooks: PropTypes.array.isRequired,
  submitURL: PropTypes.string.isRequired,

  topicAttributeName: PropTypes.string,
  endpointAttributeName: PropTypes.string,
};

const defaultProps = {
  topicAttributeName: "topic" // shopify default is topic
}

// Must be inside a Frame and AppProvider!!!
const Webhooks = (props) => {

  // Convert objects into array to use it on datatable
  const convertToRows = (webhooks) => {
    return webhooks.map( (wb) => 
      [ 
        wb[props.topicAttributeName], 
        wb.createdAt || "-", 
        wb.updatedAt || "-",
        wb[props.endpointAttributeName],
      ]
    );
  }

  const [ state, setState ] = useState({
    webhooksRows: convertToRows(props.webhooks),
    loading: false,
    message: ""
  });
  

  // Send request to load hooks or reload on some case(delete and load new ones).
  const reloadWebhooks = async (deleteWebhook = false) => {
    await setState({
      ...state,
      loading: true
    })
    try {
      const params = deleteWebhook ? { clear: "DO IT" } : {};
      const response = await axios.post(props.submitURL, params );
      console.log(response);
      await setState({
        ...state,
        loading: false,
        message: response.data.message
      })
    } catch (error) {
      await setState({
        ...state,
        loading: false,
        message: I18n.t("messages.request_error")
      })
    }
  }

  const dismissMessage = () => {
    setState({
      ...state,
      message: "",
    })
  }

  return(
    <div>
      <DataTable 
        rows={ state.webhooksRows }
        headings={[
          I18n.t("settings.webhook.topic"),
          I18n.t("activerecord.attributes.general.created_at"),
          I18n.t("activerecord.attributes.general.updated_at"),
          I18n.t("settings.webhook.endpoint"),
        ]}
        columnContentTypes={[ 'text', 'text', 'text' ]}
        footerContent={
          <div>
            <Button 
              primary
              fullWidth
              loading={ state.loading }
              onClick={ reloadWebhooks }
            >
              Actualizar Webhooks
            </Button>
            <Button 
              primary
              fullWidth
              destructive
              loading={ state.loading }
              onClick={ () => reloadWebhooks(true) }
            >
              Eliminar Webhooks
            </Button>
          </div>
        }
      />

      { !!state.message &&
        <Toast
          content={ state.message }
          onDismiss={ dismissMessage }
        />
      }
    </div>
  )
}

Webhooks.propTypes = propTypes;
Webhooks.defaultProps = defaultProps;

export default Webhooks;