import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
  Card, TextStyle, Form, FormLayout, Spinner,
  List
} from '@shopify/polaris';

import PolarisFormProvider from '@shared/PolarisFormProvider';
import FormPageActions from '@shared/FormPageActions';

import BasicTextField from '@shared/BasicTextField';

import { ta } from 'utils';
import axios from 'utils/axios-rails';

import debounce from 'lodash/debounce';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  deleteURL: PropTypes.string,
  record: PropTypes.object.isRequired,
  update: PropTypes.bool,

  processTemplateURL: PropTypes.string.isRequired, // URL to process the template on backend
  processedTemplate: PropTypes.string, // Initial value for the processed template when editing existing record
}

const defaultProps = {
  update: false,
}

const MessageTemplateForm = (props) => {
  const [processingLoading, setProcessingLoading] = useState(false); // Loader for processed template
  const [processedTemplate, setProcessedTemplate] = useState(props.processedTemplate); // To preview the template with WhatsApp

  console.log("MessageTemplateForm props", props);

  // Process template on backend each x seconds to build the preview link
  const debouncedProcessTemplate = useCallback( debounce( async (template) => {
    if(!template){
      setProcessedTemplate(null);
    }
    else{
      try {
        const response = await axios.post(props.processTemplateURL, { template })
        setProcessedTemplate(response.data.payload);
      } catch (error) {
        alert(I18n.t("messages.request_error"));
      }
    }  
    setProcessingLoading(false);  
  }, 5000), []);

  // Process template to display preview
  const handleTemplateChange = (newValue, onChangeCallback) => {
    setProcessingLoading(true);
    onChangeCallback("template", newValue);
    debouncedProcessTemplate(newValue);
  }


  return (
    <PolarisFormProvider
      title={I18n.t("activerecord.models.message_template.one")}
      defaultValues={ props.record }
      submitURL={ props.submitURL }
      submitMethod={ props.update ? "PUT" : "POST" }
      paramsName="message_template"
      pageProps={props.page}
    >
      {(renderProps) =>
        <Card sectioned>
          <Form onSubmit={renderProps.handleSubmit}>
            <FormLayout>
              <BasicTextField
                name="title"
                label={ta("general.title")}
                placeholder="Template de entrega"
                {...renderProps}
              />
              <BasicTextField
                name="template"
                label={ta("message_template.template")}
                multiline={3}
                {...renderProps}
                onChange={ (value) => handleTemplateChange(value, renderProps.setFieldValue) }
              />

              {/* Template system hints */}
              <TextStyle variation="subdued">
                Para introducir valores o elementos se debe seguir el siguiente formato: [ITEM], ejemplo: [courier_name].
                <br />
                Variables disponibles:
                <List>
                  <List.Item>customer_name: Nombre del cliente</List.Item>
                  <List.Item>courier_name: Nombre del repartidor</List.Item>
                  <List.Item>order_number: Número de orden</List.Item>
                  <List.Item>street_address: Dirección de entrega</List.Item>
                  <List.Item>edit_address_url: Link para editar la dirección de entrega</List.Item>
                  <List.Item>br: Cambio de línea</List.Item>
                </List>
              </TextStyle>

              <hr />

              {/* WhatsApp formatting hints */}
              <TextStyle variation="subdued">
                Adicionalmente se puede utilizar formato propio de "WhatsApp" para agregar estilo a los textos, ejemplo: Para agregar bold <strong>"*Texto en Bold*"</strong> .
                <br/>
                Para más información: <a target='_blank' href="https://faq.whatsapp.com/539178204879377">https://faq.whatsapp.com/539178204879377</a>
              </TextStyle>
            </FormLayout>

            { !!processedTemplate &&
              <div className='mt12'>
                <div className="flex align-items-center">
                  <a className='mr8' target='_blank' href={`https://wa.me/?text=${processedTemplate}`}>Previsualizar con datos</a>

                  { processingLoading && <Spinner  size="small" color="teal" accessibilityLabel="Loading" /> }
                </div>
                <p>
                  <TextStyle variation="subdued">
                    Si abre link desde teléfono puede previsualizar como se ve en WhatsApp.
                  </TextStyle>
                </p>
              </div>
            }

            <div className='mt12'>
              <FormPageActions
                onPrimaryAction={renderProps.handleSubmit}
                deleteURL={ props.deleteURL }
              />
            </div>
          </Form>
        </Card>
      }
    </PolarisFormProvider>    
  )
};

MessageTemplateForm.propTypes = propTypes;
MessageTemplateForm.defaultProps = defaultProps;

export default MessageTemplateForm;