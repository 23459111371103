import React, {useState} from 'react';
import PropTypes from 'prop-types';

import { Card, Pagination } from '@shopify/polaris';
import PolarisFormProvider from '@shared/PolarisFormProvider';
import CourierForm from './components/Form';
import OrdersList from '@views/orders/components/OrdersList';
import axiosRails from "../../../utils/axios-rails";
import GoogleMapOneMarker from 'components/shared/GoogleMapOneMarker';

const propTypes = {
  team: PropTypes.object.isRequired,
  courier: PropTypes.object.isRequired,
  page: PropTypes.object.isRequired,
  submitURL: PropTypes.string.isRequired,
  submitPaginationURL:  PropTypes.string.isRequired,
  orders: PropTypes.array,
}

const CourierEdit = (props) => {
  const [orders, setOrders]= useState(props.orders)
  const [loading, setLoading]= useState(false)
  const [pagy, setPagy]= useState(props.pagy)
  const previous= !!pagy.prev
  const next = !!pagy.next


  const nextPage = () =>{
    const pagePagy = pagy.page+1
    paginate(pagePagy)
  }
  const previousPage = () => {
    const pagePagy = pagy.page-1
    paginate(pagePagy)
  }

  const paginate = async (page)=> {
    setLoading(true)
    try {
      const resp =  await axiosRails.post(props.submitPaginationURL, {
        page: page,
        is_pagination: true,
        id: props.courier.id
      });
      // console.log("%c Good the Pagination!", "font-size: 1em; color: green")
      setOrders(resp.data.records)
      setPagy(resp.data.pagy)
      setLoading(false)
    } catch (error) {
      // console.error(`%c ${error}`, "font-size: 2em; color: red");
      setLoading(false)
    }
  }
  // console.log("CE", props);
  // console.log("%c All Good!", "font-size: 2em; color: green");

  return(
    <PolarisFormProvider
      defaultValues={{
        ...props.courier,
        user_attributes: props.courier.user,
      }}
      pageProps={{ ...props.page, title: props.courier.user.name }}
      submitURL={ props.submitURL }
      submitMethod="PUT"
      paramsName="courier"
    >
    { ({handleChange, values, errors, handleSubmit}) => (
      <div>
        <CourierForm
          handleChange={ handleChange } values={values} errors={errors} handleSubmit={handleSubmit}
        />

        { props.courier.has_default_destination &&
          <Card title="Destino final de la ruta">
            <div style={{ height: '312px' }}>
              <GoogleMapOneMarker
                markerLoc={{ lat: Number(props.courier.default_destination_lat), lng: Number(props.courier.default_destination_lon) }}
                mapCenterLoc={{ lat: Number(props.courier.default_destination_lat), lng: Number(props.courier.default_destination_lon) }}
                moveMapCenter
              />
            </div>
          </Card>
        }

        { props.orders.length > 0 &&
          <Card title={ I18n.t("activerecord.models.order.other") }>
            <OrdersList
              items={orders}
              loading={loading}
              admin={props.admin}
            />

            <div className="flex justifyCenter mt20 mb10" >
              <Pagination
                hasPrevious={ previous }
                onPrevious={ previousPage }
                hasNext={ next }
                onNext={ nextPage }
              />
            </div>
          </Card>
        }
        </div>
    )}
    </PolarisFormProvider>
  )
}

CourierEdit.propTypes = propTypes;

export default CourierEdit;