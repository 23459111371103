import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Frame, Toast, Card, FormLayout, Select, Button, ExceptionList, ChoiceList } from '@shopify/polaris';
import { List } from 'immutable';
import { PolarisFormProvider } from '@shared/PolarisFormProvider';

import DispatchControlTagForm from './components/DispatchControlTagForm';
import DispatchControlPerTracking from './components/DispatchControlPerTrackingForm'
import SharedUI from './components/DispatchControlSharedUI';

import split from 'lodash/split';
import debounce from 'lodash/debounce';

import axios from 'utils/axios-rails';
import { values } from 'lodash';

const propTypes = {
  teamOptions: PropTypes.array.isRequired,
  submitURL: PropTypes.string.isRequired,
  queryOrdersUrl: PropTypes.string.isRequired,
}

const formTypes = { standard: "standard", withTracking: "withTracking" };
const defaultOrdersForTracking = [{ id: "", tracking: "" }];

const DispatchControlFormWrapper = (props) => {
  const [toast, setToast] = useState("");
  const [loading, setLoading] = useState(false);
  const [orderIds, setOrderIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [uber, setUber] = useState(false)

  const [ordersForTracking, setOrdersForTracking] = useState(defaultOrdersForTracking);
  const [trackingIdFocus, setTrackingIdFocus] = useState("0.id"); // Setup of auto focus for Tracking form (as it is meant to be used with barcode scanner)
  const [formType, setFormType] = useState(formTypes.standard);

  const debouncedTrackingFocusChange = useCallback(debounce(setTrackingIdFocus, 500),[]);
  const [form, setForm] = useState({
    orders: [],
    teamId: 0,
    providerName: "bright_pearl",
    editTracking: false,
    uber_vehicle: "",
  });

  const closeToast = () => setToast("");

  const handleFormType = (values) => {
    setOrdersForTracking(defaultOrdersForTracking);
    let newValue = values[0];
    setFormType(newValue);
    handleChange(newValue == formTypes.withTracking, "editTracking") // set true on editTracking when using tracking form
  }

  const handleChange = (value, name) => setForm({
    ...form,
    [name]: value
  });

  // For correos CR form (tracking form)
  const handleOrderTrackingChange = (value, providerId) => {
    let ordersList = List(form.orders);
    let orderIndex = ordersList.findIndex( (order) => order.provider_id == providerId );
    ordersList = ordersList.setIn([orderIndex, "tracking_number"], value); // Update order tracking number
    handleChange(ordersList.toJS(), "orders");
  }
  
  const handleDeepChange = (value, name) => {
    let ordersList = List(ordersForTracking);
    let namePair = split(name, "."); // [index, attrName]
    ordersList = ordersList.setIn(namePair, value);
    setOrdersForTracking( ordersList.toJS() );

    if(namePair[1] == "id"){ // focus next input (tracking)
      debouncedTrackingFocusChange( `${namePair[0]}.tracking`)
    }
    else{
      debouncedTrackingFocusChange( `${Number(namePair[0]) + 1}.id`) // next row id
    }
  }

  const addNewTrackingOrder = () => {
    setOrdersForTracking([
      ...ordersForTracking,
      defaultOrdersForTracking[0]
    ])
  }

  useEffect(() => {
    let ordersForTrackingSize = ordersForTracking.length;
    let lastOrder = ordersForTracking[ordersForTrackingSize - 1];
    if(lastOrder.tracking != "" && lastOrder.id != ""){ // When modifying current last order, auto add new row if id and tracking has been filled already
      addNewTrackingOrder()
    }
  }, [ordersForTracking]);

  // =================================================================

  const queryOrders = async () => {
    setLoading(true);

    try {
      let params = {};
      if(formType == formTypes.standard){// just query ids. tracking can be modified manually later
        if (orderIds.length == 0) {
          setToast(I18n.t("dispatchControl.errors.id"))
          setLoading(false);
          return false;
        }
        params = { ids: orderIds, providerName: form.providerName, ordersWithTracking: [] , team_id: form.teamId}
      }

      else{ // query with ids and send ids/tracking match array
        let filteredOrders = ordersForTracking.filter( order => !!order.id ) // Id must be present
        if (filteredOrders.length == 0) {
          setToast(I18n.t("dispatchControl.errors.id"))
          setLoading(false);
          return false;
        }
        params = { 
          ids: filteredOrders.map( order => order.id ),
          providerName: form.providerName,
          ordersWithTracking: filteredOrders,
          team_id: form.teamId,
          
        }
      }

      let response = await axios.post(props.queryOrdersUrl, { query: params })
      if (response.data.success) {
        handleChange(response.data.orders, "orders");
        response.data.orders.length == 0 ? setToast(I18n.t('dispatchControl.errors.noFound')) : null;
        setUber(response.data.uber)
      }
    } catch (error) {
      { setToast(I18n.t('dispatchControl.errors.error')) }
    }

    setLoading(false);
  }
  
  const handleSubmit = async () => {
    const { orders, teamId, providerName, editTracking, uber_vehicle } = form;
    setLoading(true);
  
    try {
      let response = await axios.post(props.submitURL, {
        team_id: teamId,
        dispatch_orders_attributes: orders,
        provider_name: providerName,
        submit_tracking: editTracking,
        uber_vehicle: uber_vehicle,
      })
      if (response.data.success) {
        window.location = response.data.redirectUrl;
      }
      else {
        console.log("Non success", response);
        setErrors(response.data.errors);
        setToast(I18n.t("messages.record_errors"));// aca tiene que ser el cambio
      }
    } catch (error) {
      setToast(I18n.t('dispatchControl.errors.error'));
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <AppProvider>
      <Frame>
        <Page
          fullWidth
          title={`${I18n.t("actions.new")} ${I18n.t("activerecord.models.dispatch_control.one")}`}
          breadcrumbs={[{ content: I18n.t("actions.back"), url: '/admin/dispatch_controls' }]}>

          <Card sectioned>
            <ChoiceList
              title={ "" }
              choices={[
                {label: 'Estándar', value: formTypes.standard },
                {label: 'Correos de CR', value: formTypes.withTracking },
              ]}
              selected={ formType }
              onChange={ handleFormType }
            />
          </Card>
          
          { formType == formTypes.standard ? (
            <DispatchControlTagForm
              queryOrders={queryOrders}
              teamOptions={ props.teamOptions }

              handleSubmit={handleSubmit}
              handleChange={ handleChange }
              setOrderIds={ setOrderIds }
              uberVehicleOptions={props.uberVehicleOptions} 
              uber={uber}
              values={ form }
              errors={ errors }
              loading={loading}
              orderIds={ orderIds }
            />
          ) : (
            <DispatchControlPerTracking
              onQuery={ queryOrders }
              orders={ ordersForTracking }
              handleChange={ handleDeepChange }
              onAdd={ addNewTrackingOrder }
              trackingIdFocus={ trackingIdFocus }

              loading={ loading }

              handleTeamChange={ handleChange }
              teamOptions={ props.teamOptions }
              teamId={ form.teamId }
            />
          )}

          {  form.orders.length > 0 &&
            <SharedUI
              values={form} errors={ errors } loading={ loading } 
              handleSubmit={ handleSubmit } handleChange={ handleChange } handleOrderTrackingChange={ handleOrderTrackingChange } 
              uber={uber}
            />
          }

          {/* Orders found UI */}
          {!!toast && <Toast content={toast} onDismiss={closeToast} /> }
        </Page>
      </Frame>
    </AppProvider>
  )
}

DispatchControlFormWrapper.propTypes = propTypes;

export default DispatchControlFormWrapper;
