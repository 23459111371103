import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Badge } from '@shopify/polaris';

const propTypes = {
  value: PropTypes.bool.isRequired,
}

const defaultProps = {

}

// Standarized colors for boolean values on Badge
const PolarisBooleanBadge = (props) => {
  return (
    <Badge status={props.value ? 'info' : 'warning'}>
      {props.value ? 'Sí' : 'No'}
    </Badge>
  )
};

PolarisBooleanBadge.propTypes = propTypes;
PolarisBooleanBadge.defaultProps = defaultProps;

export default PolarisBooleanBadge;