import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { taskColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';
import TaskResourceList from './components/TaskResourceList';

import { AppProvider, Frame, Toast, Modal, Button, Icon, ButtonGroup, TextField, Card } from '@shopify/polaris';
import { ListMajorMonotone, SoftPackMajorMonotone, ReportsMajorMonotone } from '@shopify/polaris-icons';

import axiosRails from '@utils/axios-rails';

const propTypes = {
  records: PropTypes.array.isRequired,
  newUrl: PropTypes.string,
  reportURL: PropTypes.string,
}

const defaultProps = {

}

const TaskIndex = (props) => {
  const [view, setView] = useState("table");
  const [toast, setToast] = useState(null);
  const [modalStatus, setModalActive] = useState(false);
  const [reportEmail, setReportEmail] = useState("");

  const primaryAction = props.newUrl ? { content: I18n.t("actions.create_x", { x: I18n.t("activerecord.models.task.one") }), url: props.newUrl } : null;
  
  let secondaryActionText = `${I18n.t("messages.generate_report_of")}${I18n.t("enum.kind.warranty")}`
  const secondaryActions = props.reportURL ? [{
    content: secondaryActionText,
    accessibilityLabel: secondaryActionText,
    icon: ReportsMajorMonotone,
    external: true,
    // url: props.reportURL,
    onAction: () => {
      setModalActive(true);
    },
  }] : [];

  const hideModal = () => {
    setModalActive(false);
  }

  const hideToast = () => {
    setToast(null);
  }

  const onReportApproved = async () => {
    try {
      const { data } = await axiosRails.post(props.reportURL, { email: reportEmail });
      if(data.success){
        setModalActive(false);
        setToast("Tomará tiempo. Te enviaremos un email cuando este listo.");
      }
      else{
        setToast(data.error);
      }
    } catch (error) {
      setToast(I18n.t("messages.request_error"));
    }
  }
 
  const setListView = () => setView("list");
  const setTableView = () => setView("table")

  // If view isn't list then don't bother processing the component
  const resourceListView = () => {
    if(view == "list")
      return ( 
      <TaskResourceList 
          loading={ props.loading } 
          items={ props.records }  
          itemProps={{
            extraActions: (taskId, task) => (
              <div className="flex mt4">
                  { task.delete_url &&
                    <div className="mr8">
                      <Button 
                        destructive
                        onClick={ () => props.deleteElement(task, `${task.delete_url}${taskId}`) }>
                        {I18n.t("actions.delete")}
                      </Button>
                    </div>
                  }
                  <div>
                    <Button
                        primary
                        url={`/tasks/${taskId}`}>
                      {I18n.t("actions.view_details")}
                    </Button>
                  </div>
              </div>
            )
          }}
        />
    )
  }

  const TaskListMemo = useCallback(resourceListView, [props.loading, props.records, view]);
  return(
    <AppProvider>
      <FilterTable
        searchName="search_query"
        searchPlaceholder={"ID de Orden"}
        pageProps={{
          title: I18n.t("activerecord.models.task.other"),
          primaryAction: primaryAction,
          secondaryActions: secondaryActions,
        }}
        columnsInfo={ taskColumns }
        resourceTableProps={{
          itemURLAsAction: false,
        }}
        contentComponent={ view == "list" ? TaskListMemo : null }
        resourceTableClassName={ "resource-table-tasks" }
        filterFooter={
          <div className="mt20">
            <ButtonGroup segmented>
              <Button onClick={ setTableView } primary={ view == "table" } monochrome><Icon source={ SoftPackMajorMonotone}/></Button>
              <Button onClick={ setListView } primary={ view == "list" }><Icon source={ListMajorMonotone} /></Button>
            </ButtonGroup>
          </div>
        }
        {...props}
      />
      { modalStatus &&
        <Modal
          small
          open={ true }
          onClose={ hideModal }
          title={ I18n.t('messages.ask_if_sure') }
          primaryAction={{
            content: I18n.t('true'),
            onAction: onReportApproved,
            disabled:!reportEmail
          }}
          secondaryActions={[
            {
              content: I18n.t('false'),
              onAction: hideModal,
            },
          ]}
        >
          <Card sectioned>
            <TextField
              label="Email"
              value={ reportEmail }
              onChange={ setReportEmail }
            />
          </Card>
        </Modal>
      }

      { toast &&
        <div style={{position: 'absolute'}}>
          <Frame>
            <Toast content={ toast } onDismiss={hideToast} />
          </Frame>
        </div>
      }
  
    </AppProvider>
  )
};

TaskIndex.propTypes = propTypes;
TaskIndex.defaultProps = defaultProps;

export default withPagination(TaskIndex, {
  mapPropsToInitialFilters: () => ({
    search_query: "",
    kind: " ",
    status: " ",
    team_id: " ",
    courier_id: " ",
  }),
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "task_filters",
  filterURL: "/tasks/filter",
});