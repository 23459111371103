import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import {useDropzone} from 'react-dropzone';

import { Button, Spinner, Frame, Thumbnail, Icon } from '@shopify/polaris';
import { DeleteMinor, CircleCancelMinor } from '@shopify/polaris-icons'

import { clsx } from 'clsx';

import ImagePlaceholderIcon from '@components/../../assets/images/icons/attachment.svg';
import isEmpty from 'lodash/isEmpty';

const propTypes = {
  files: PropTypes.array,
  multiple: PropTypes.bool,
  imageUrl: PropTypes.string,
  
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func, // Delete a single file
  onClearAll: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,

  placeholderHint: PropTypes.string,
}

const defaultProps = {
  disabled: false,
  loading: false,
}

// Using react-dropzone actually, but with a Polaris style

const ImageDropZone = (props) => {
  const { imageUrl, onClearAll, label, disabled, loading, ...dropZoneProps } = props;
  
  // const handleDrop = useCallback(acceptedFiles => {
  //   props.onChange(acceptedFiles);
  // }, [])

  const handleDrop = (acceptedFiles) => {
    props.onChange(acceptedFiles);
  }

  const handleFileDelete = (event, index) => {
    event.stopPropagation();
    props.onDelete(index);
  }

  const {getRootProps, getInputProps} = useDropzone({ onDrop: handleDrop })

  const isDisabled = disabled || loading;

  const uploadPlaceholder = (
    <>
      <div className="polaris-placeholder-icon">
        <img src={ImagePlaceholderIcon} />
      </div>
      { props.placeholderHint && <span>{ props.placeholderHint }</span> }
    </>
  );

  const multipleFilesPreview = (
    <>
      { props.files && props.files.map((file, index) => {
        return (
          <div key={index} className="file-item-container">
            <FileItemPreview file={file} />

            { props.onDelete &&
              <Button 
                plain
                size='slim' 
                icon={CircleCancelMinor} 
                onClick={(event) => handleFileDelete(event, index)} 
              />
            }
          </div>
        )
      } ) }
    </>
  );

  const hasFilesToShow = !!imageUrl || !isEmpty(props.files);

  return (
    <div className={clsx('components-image-dropzone mv8', props.multiple && 'multiple-files', hasFilesToShow && 'has-files', loading && 'is-loading')}>
      <div className="actions-container">
        <div className="components-label">
          <span className='fs12'>{ label }</span>
        </div>
        { onClearAll &&
          <Button 
            plain
            size='slim' 
            destructive
            onClick={onClearAll} 
            disabled={ isDisabled } 
            icon={DeleteMinor}
          />
        }
      </div>

      <div {...getRootProps({className: 'dropzone'})}>
        <input disabled={ isDisabled } {...getInputProps()} />
        <div className="dropzone-placeholder" >
          {/* Loading is placed on top of anything */}
          { loading && <Spinner size="large" /> }

          {/* Display content (not loading state) */}
          { !loading && (
            <>
              {/* Display image if provided (better for single image upload) */}
              { imageUrl && <img style={{ maxWidth: "100%" }} src={ props?.imageUrl } />}
            
              {/* Display files (preview depend on file type) */}
              { !imageUrl && multipleFilesPreview }

              {/* Placeholder upload instructions - Display when no files have been set */}
              { !hasFilesToShow && uploadPlaceholder }
            </>
          )}
        </div>
      </div>
    </div>
  )
};

const FileItemPreview = ({ file }) => {
  const filename = file.name || file.filename || "unknown filename";
  if (file.mimeType.startsWith('image/')) {
    const imgSrc = `data:${file.mimeType};base64, ${file.base64}`;
    return(
      <div style={{ display: "flex", alignItems: "center"  }}>
        <Thumbnail source={imgSrc} size='medium' />
        <span style={{ marginLeft: 4 }}>{ filename }</span>
      </div>
    )
  }
  else{
    return(
      <span>
        { filename }
      </span>
    )
  }
}

ImageDropZone.propTypes = propTypes;
ImageDropZone.defaultProps = defaultProps;

export default ImageDropZone;