import React, { Component} from 'react';
import PropTypes from 'prop-types';
import esTranslations from '@shopify/polaris/locales/es.json';
import { Card, Tabs, Pagination, Select, TextField, AppProvider, Page, DataTable } from '@shopify/polaris';
import IndexResourceList from '@shared/IndexResourceList';
// import DatePicker from '@presentational/DatePicker';
// import locales from '@utils/locales.json';

const propTypes = {
  tabs: PropTypes.array, // If provided Tab filtering is enabled. Manipulated with status and statusTabIndex
  users: PropTypes.array, // If list provided then select to filter with user_id will be used, commonly used with "Seller" name using user.seller vendor as label.
  searchName: PropTypes.string, // The name of the search input to send to the backend.
  searchPlaceholder: PropTypes.string,

  filterByCreation: PropTypes.bool,
  kind: PropTypes.array,
  columnsInfo: PropTypes.array.isRequired, // Columns information to feed ResourceTable Headers/Body components
  showURL: PropTypes.string, // List item url
  // resourceTableProps: PropTypes.shape(IndexResourceList.propTypes),
  resourceTableProps: PropTypes.object,

  customFilters: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node),]), // Extra filters
  headerComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node),]), // Component to be rendered above filters
  pageProps: PropTypes.object, // Polaris page component props

  contentComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node),]),
  resourceTableClassName: PropTypes.string,
  // filterFooter: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node),]),
}

const defaultProps = {
  users: [],
  tabs: [],
  filterByCreation: false,
  resourceTableProps: {},
  resourceTableClassName: "resource-table-body-container"
}

class FilterTable extends Component {
  render() {
    const props = this.props;
    const {
      page, totalPages, records, loading,
      columnsInfo,
      tabs,
      users,
      showURL,
      pageProps,
      searchName,
      kind,
      status,
      couriers,
      teams,
      updateFilter,
      filterState,
      paginate,
      delayedPaginate,
      handleUserChange,
      handleFilterChange,
      showDatepicker,
      toggleDatepicker,
      onStatusChange,
      generalStatsGraph,
      hideCardSection, // Simplistic workaround for DashboardIndex which use this component but it really shouldn't... (refactoring will be done when hook for filter is made)
    } = props;

    const renderDateProps = {filterState, handleFilterChange}
    const renderProps = { records, updateFilter, generalStatsGraph}
    

    return (
      <AppProvider i18n={esTranslations}>
        <Page
          fullWidth
          {...pageProps}
        >
          { props.optionFilterDate && props.optionFilterDate(renderDateProps)}

          { props.headerComponent && props.headerComponent(renderProps) }

          <Card>
            { !props.hideCardSection &&
              <Card.Section>
                {/* Custom Filters */}
                {props.customFilters && props.customFilters(renderProps)}

                <div className="row">
                  {/* Search Term */}
                  {!!searchName &&
                    <div className="col-12 col-md-4 col">
                      <TextField
                        disabled={loading}
                        id={searchName}
                        label={I18n.t('actions.search')}
                        value={filterState[searchName]}
                        onChange={(value, id) => updateFilter(value, id, 'delayed')}
                        placeholder={ props.searchPlaceholder }
                      />
                    </div>
                  }
                  { !!props.role &&
                    <div className="col-12 col-md-2 col">
                      <Select
                      disabled={loading}
                      label={I18n.t("activerecord.attributes.user.role")}
                      options={props.role}
                      onChange={(value)=>{handleFilterChange(value, 'role')}}
                      value={filterState.role}
                      />
                    </div>
                  }
                  { !!props.kind &&
                    <div className="col-12 col-md-2 col">
                      <Select
                      disabled={loading}
                      label={I18n.t("activerecord.attributes.task.kind")}
                      options={kind}
                      onChange={(value)=>{handleFilterChange(value, 'kind')}}
                      value={filterState.kind}
                      />
                    </div>
                  }
                  { !!props.status &&
                  <div className="col-12 col-md-2 col">
                    <Select
                        disabled={loading}
                        label={I18n.t("activerecord.attributes.task.status")}
                        options={status}
                        onChange={(value)=>{handleFilterChange(value, 'status')}}
                        value={filterState.status}
                    />
                  </div>
                  }
                  { !!props.couriers &&
                  <div className="col-12 col-md-2 col">
                    <Select
                        disabled={loading}
                        label={I18n.t("activerecord.attributes.task.courier_ids")}
                        options={couriers}
                        onChange={(value)=>{handleFilterChange(value, 'courier_id')}}
                        value={Number(filterState.courier_id)}
                    />
                  </div>
                  }
                  { !!props.teams &&
                  <div className="col-12 col-md-2 col">
                    <Select
                        disabled={loading}
                        label={I18n.t("activerecord.models.team.one")}
                        options={teams}
                        onChange={(value)=>{handleFilterChange(value, 'team_id')}}
                        value={Number(filterState.team_id)}
                    />
                  </div>
                  }
                  {/* Users/Sellers dropdown */}
                  {users.length > 0 &&
                    <div className="col-12 col-md-6">
                      <Select
                        disabled={loading}
                        label="Seller"
                        options={users}
                        onChange={handleUserChange}
                        value={Number(filterState.user_id)}
                      />
                    </div>
                  }
                </div>

                { !!props.filterFooter && props.filterFooter }
              </Card.Section>
            }

            {tabs.length > 0 &&
              <Tabs
                tabs={props.tabs}
                selected={filterState.statusTabIndex}
                onSelect={onStatusChange}>
              </Tabs>
            }

            {/* =================== CONTENT ======================== */}
            { props.contentComponent ? props.contentComponent() :
              <div className={ props.resourceTableClassName }>
                <IndexResourceList
                  items={records}
                  itemURL={showURL}
                  columnsInfo={columnsInfo}
                  onDelete={props.deleteElement}
                  {...props.resourceTableProps} 
                  loading={ loading }
                />
              </div>
            }
            {/* =================== CONTENT ======================== */}

            <div className="p20 flex flexColumn justifyCenter alignItemsCenter">
              <Pagination
                hasPrevious={ props.hasPrevious }
                hasNext={ props.hasNext }
                onPrevious={ props.previousPage }
                onNext={ props.nextPage }
              />
            </div>
          </Card>
          
          {/* =================== Task Kind Stats (TODO move this to DashboardIndex somehow) ======================== */}
          { props.taskTableData &&
            <div className="mt16">
              <div className='resource-table-dashboard-teams'>
                <IndexResourceList
                  items={props.taskTableData}
                  itemURL={ null }
                  columnsInfo={ props.dashboardTaskColumns }
                  {...props.resourceTableProps}
                  loading={ loading }
                />
              </div>
            </div>
          }
        </Page>
      </AppProvider>
    );
  }
}


FilterTable.propTypes = propTypes;
FilterTable.defaultProps = defaultProps;


export default FilterTable;
