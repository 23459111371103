import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Page, Banner, TextStyle, AppProvider } from '@shopify/polaris';

const propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
}

const defaultProps = {
  title: "Aviso"
}

const InformationPage = (props) => {
  return (
    <AppProvider>
      <Page>
        <Banner title={ props.title }>
          <TextStyle>
            { props.message }
          </TextStyle>
        </Banner>
      </Page>
    </AppProvider>
  )
};

InformationPage.propTypes = propTypes;
InformationPage.defaultProps = defaultProps;

export default InformationPage;