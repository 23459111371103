import compact from 'lodash/compact';
import snakeCase from 'lodash/snakeCase';

export const ta = (stuff) => {
  return I18n.t(`activerecord.attributes.${stuff}`);
}

export const uidMaxLength = 4;

export const disambiguateLabelForFilter = (key, value) => {
  switch (key) {
    case 'couriers':
      return `${I18n.t("activerecord.attributes.courier.filter_by_courier")} ${value.label} `;
    case 'teams':
      return `${I18n.t("activerecord.models.team.one")} ${value.label}`;
    case 'status':
      return I18n.t("activerecord.attributes.order.filter_by_status");
    case 'created_at':
      return I18n.t("activerecord.attributes.order.filter_by_created_at") ;
    case 'delivered_at':
      return I18n.t("activerecord.attributes.order.filter_by_delivered_at") ;
    default:
      return value;
  }
}

export const statusVariations = {
  assigned: "subdued",
  delivered: "positive",
  cancelled: "negative",
  unassigned: "negative",
}

export const calculateDays = (date, date_end)=>{
  date= new Date(date)
  date_end = new Date(date_end)
  if( date.getMonth() === date_end.getMonth()  && date.getDate() === date_end.getDate() ){
    return ` ${I18n.t('date.abbr_month_names_javascript.'+ date.getMonth())} ${date.getDate()}`
  }else if( date.getMonth() === date_end.getMonth() && date.getDate() != date_end.getDate()){
    return (
        ` ${I18n.t('date.abbr_month_names_javascript.'+date.getMonth())} ${date.getDate()} - ${date_end.getDate()}`
    )
  }else{
    return (
        ` ${I18n.t('date.abbr_month_names_javascript.'+date.getMonth())} ${date.getDate()} - ${I18n.t('date.abbr_month_names_javascript.'+date_end.getMonth())} ${date_end.getDate()}`
    )
  }
}

// File reader configured to receive a file and return the base64 string to be sent to API
// Use reader.readAsDataURL(file) to trigger the onload event
export const base64FileReader = (onLoad) => {
  const reader = new FileReader();
  reader.onload = (e) => { // Get the base64 string and send it to the API
    const readerResult = reader.result; // Format is: data:<media_type>;base64,<base64_string>. i.e. "data:image/jpeg;base64,/9jxxx..."
    const parts = readerResult.split(";base64,");
    const mimeType = parts[0].split(":")[1]; // i.e. "image/jpeg"
    const base64 = parts[1]; // Base64 string

    onLoad({ readerResult, mimeType, base64 });
  }

  return reader; // usage example: reader.readAsDataURL(file);
}

/**
 * Build items for a Polaris <DescriptionList/> component
 * Receive a list of `fields` and a `record` to build the items based on the fields specification and values on the record object.
 * @param {*} fields - Array of fields, fields can be either a string or an object with the following properties: { name: 'fieldName', accessor: (record) => record.field_name, hideIfEmpty: true }
 * @param {*} record - Object with values for the fields
 * @returns {Array} - Array of DescriptionList items: { term: 'Field name', description: 'Field value' }
 * 
 * Valid field attributes: name, accessor, hideIfEmpty, title, hideIf
 */
// TODO Make translation dynamic
export const polarisBuildListItems = (fields, record) => {
  const descriptionItems =  fields.map(field => {
    const isString = typeof field === 'string';
    const fieldName = isString ? field : field.name;
    const fieldValue = record[fieldName];

    const fieldDescription = isString ? fieldValue : field.accessor(fieldValue, record);

    if(!isString && field.hideIfEmpty && !fieldValue){
      return null;
    }
    else if(!isString && field.hideIf && field.hideIf(fieldValue, record)){
      return null
    }

    // "term" is the 'title' of the ListItem
    const term = (!isString && field.title) ? field.title : I18n.t(`activerecord.attributes.customer_ticket.${snakeCase(fieldName)}`);

    return {
      term: term,
      description: fieldDescription
    }
  })

  return compact(descriptionItems);
}