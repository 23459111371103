import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Card, TextStyle } from '@shopify/polaris';

import capitalize from 'lodash/capitalize';

const propTypes = {
  logs: PropTypes.arrayOf(
    PropTypes.shape({
      human_created_at: PropTypes.string,
      user_name: PropTypes.string,
      action: PropTypes.string, // enum
      notes: PropTypes.string,
    }),
  ).isRequired,

  title: PropTypes.string,

  isGql: PropTypes.bool, // With Admin API. New way, false is for legacy
  withCard: PropTypes.bool,
};

const defaultProps = {
  title: "Historial",
  isGql: false,
  withCard: true,
};

const ResourceLogList = ({ logs, ...props }) => {
  const buildLogs = (items) => {
    const messageAccessor = props.isGql ? "displayMessage" : "display_message_no_date";
    
    return items.map((orderLog, index) => {
      const dateValue = props.isGql ? capitalize(I18n.l("time.formats.app_datetime", orderLog.eventDatetime)) : orderLog["human_event_datetime"]; // GQL localize value, legacy use human_event_datetime from serializer
      const isFirst = index === 0;
      
      return (
        <div className="dot-container" key={orderLog.id}>
          {!isFirst && <div className="dot-line"></div>}
          <div className="dot"></div>
          <p className={`dot-text fs14 ${isFirst ? "first" : ""}`}>
            <strong>{dateValue}</strong>{orderLog[messageAccessor]}
            { !!orderLog.notes &&
              <>
                <br/>
                <TextStyle variation='subdued'>{ orderLog.notes }</TextStyle>
              </>
            }
          </p>
        </div>
      )
    })
  }

  const timeline = useMemo(() => buildLogs(logs), [logs.length]);

  if (logs.length > 0 && props.withCard) { // Legacy
    return (
      <Card
        title={props.title}
        sectioned
      >
        <div id="verticalTimeline" className="mt30">
          {timeline}
        </div>
      </Card>
    )
  }
  else if(logs.length > 0) { // New, flexible
    return (
      <div id="verticalTimeline">
        {timeline}
      </div>
    )
  }
  else {
    return null;
  }
}

ResourceLogList.propTypes = propTypes;
ResourceLogList.defaultProps = defaultProps;

export default ResourceLogList
