import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Toast, Card, FormLayout, Select, Button, ExceptionList , ChoiceList, Checkbox  } from '@shopify/polaris';


import TagsInput from 'react-tagsinput';
import OrdersList from 'components/views/orders/components/OrdersList';

const propTypes = {
  queryOrders: PropTypes.func.isRequired,
  orderIds: PropTypes.array.isRequired,

  loading: PropTypes.bool,
  values: PropTypes.shape({
    teamId: PropTypes.oneOfType([ PropTypes.string, PropTypes.number ]),
  }),

  handleChange: PropTypes.func.isRequired,
  setOrderIds: PropTypes.func.isRequired,

  teamOptions: PropTypes.array.isRequired,
  uberVehicleOptions: PropTypes.array.isRequired,
};

const defaultProps = {

}
const DispatchControlTagForm = ({ values, ...props }) => {
  const [isUber, setIsUber] = useState(false);

  let valueIsValid = !values.teamId;

  useEffect( () => {
    if(!!values.teamId){
      const team = props.teamOptions.find(team => team.value === values.teamId );
      setIsUber(team.data.uber);
    }
  }, [values.teamId])
  
  return(

  <div>
    <Card
      primaryFooterAction={{
        loading: props.loading,
        onAction: props.queryOrders,
        content: I18n.t("actions.generate"), 
        disabled: valueIsValid
      }}
    >
      <Card.Section title={I18n.t("form.placeholder.dispatch_control.order_ids")}>
        <div className="hidden_i">
          <ChoiceList
              title={ I18n.t("activerecord.attributes.order.provider_name") }
              choices={[
                {label: 'Bright Pearl', value: 'bright_pearl'},
                {label: 'Shopify', value: 'shopify'},
              ]}
              selected={ values.providerName }
              onChange={ (values) => props.handleProviderChange(values[0], "providerName") }
            />
        </div>
        <FormLayout>

          <div className="mb8">
            <FormLayout>
              <FormLayout.Group>
                <Select
                  id="team"
                  name="team"
                  label={I18n.t("activerecord.models.team.one")}
                  options={props.teamOptions}
                  value={ values.teamId }
                  onChange={ (value) => props.handleChange(Number(value), "teamId") }
                  error={props.errors.team}
                />
              </FormLayout.Group>
            </FormLayout>
          </div>

          { isUber && 
            <FormLayout.Group>
              <Select
                id="uber_vehicle"
                name="uber_vehicle"
                label="Tipo de Vehículo Uber"
                options={props.uberVehicleOptions}
                value={ values.uber_vehicle }
                onChange={(value) => props.handleChange(value,"uber_vehicle") }
              />
            </FormLayout.Group>
          }

          <FormLayout.Group>
            <div>
              <TagsInput
                value={props.orderIds}
                onlyUnique
                addKeys={[188, 9, 13]}
                inputProps={{
                  placeholder: I18n.t("form.placeholder.dispatch_control.order_ids")
                }}
                addOnPaste
                pasteSplit={
                  (data) => {
                    return data.split(',').map(d => d.trim())
                  }}
                onChange={ props.setOrderIds  } />
            </div>
          </FormLayout.Group>
          
          <FormLayout.Group>
            <Checkbox
              label="Editar guías"
              checked={ values.editTracking }
              onChange={ (checked) => props.handleChange(checked, "editTracking") }
              disabled={ props.loading || values.orders.length < 1 }
            />
          </FormLayout.Group>
        </FormLayout>
      </Card.Section>
    </Card>
  </div>
)};

DispatchControlTagForm.propTypes = propTypes;
DispatchControlTagForm.defaultProps = defaultProps;

export default DispatchControlTagForm;
