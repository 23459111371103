import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, TextField, Stack, Button, Heading, Select, FormLayout } from '@shopify/polaris';
import {
  AddNoteMajorMonotone
} from '@shopify/polaris-icons';

const propTypes = {
  orders: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    tracking: PropTypes.string,
  })),
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onQuery: PropTypes.func.isRequired,
  trackingIdFocus: PropTypes.string,

  handleTeamChange: PropTypes.func.isRequired,
  teamId: PropTypes.number,
  teamOptions: PropTypes.array.isRequired,
}

const defaultProps = {

}

const DispatchControlPerTrackingForm = (props) => {
  const lastOrder = props.orders[ props.orders.length - 1 ]
  return (
    <Card 
      primaryFooterAction={{ 
        content: I18n.t("actions.generate"),
        onAction: props.onQuery,
        loading: props.loading
      }}
      sectioned>

      <div className="mb8">
        <FormLayout>
          <FormLayout.Group>
            <Select
              id="team"
              name="team"
              label={I18n.t("activerecord.models.team.one")}
              options={props.teamOptions}
              value={ props.teamId }
              onChange={ (value) => props.handleTeamChange(Number(value), "teamId") }
            />
          </FormLayout.Group>
        </FormLayout>
      </div>

      <div className="mb8">
        <Stack distribution="equalSpacing">
          <Stack.Item fill>
            <Heading>ID</Heading>
          </Stack.Item>
          <Stack.Item fill>
            <Heading>{I18n.t("activerecord.attributes.dispatch_order.tracking_number")}</Heading>
          </Stack.Item>
        </Stack>
        { props.orders.map( (order, index) =>
          <div key={index} className="mt4">
            <Stack distribution="equalSpacing">
              <Stack.Item fill>
                <TextField 
                  id={ `${index}.id` }
                  autoFocus={ props.trackingIdFocus == `${index}.id` }
                  focused={ props.trackingIdFocus == `${index}.id` }
                  placeholder="100"
                  value={ order.id } onChange={ props.handleChange } />
              </Stack.Item>
              <Stack.Item fill>
                <TextField 
                  id={ `${index}.tracking` } 
                  autoFocus={ props.trackingIdFocus == `${index}.tracking` }
                  focused={ props.trackingIdFocus == `${index}.tracking` }
                  placeholder="CRAA00000"
                  value={ order.tracking } onChange={ props.handleChange } />
              </Stack.Item>
            </Stack>
          </div>
        )}
      </div>
      <Button
        disabled={ lastOrder.id == "" || props.loading }
        monochrome outline
        icon={AddNoteMajorMonotone}
        onClick={ props.onAdd }>Agregar</Button>
    </Card>
  )
};

DispatchControlPerTrackingForm.propTypes = propTypes;
DispatchControlPerTrackingForm.defaultProps = defaultProps;

export default DispatchControlPerTrackingForm;