// Parse errors from GraphQL userError to formik errors
// errors: { key: String, message: String }[]
export const userErrorsToFormik = (errors) => {
  const fieldErrors = {};
  for (const error of errors) {
    fieldErrors[error.key] = error.message;
  }

  return fieldErrors;
};

/**
 * Parse introspection enumValues to options for a Select/Dropdown component
 * enumName as Rails I18n key, i.e. "supplier_status"
 * Sample:
 ```
  query{
    supplierStatusEnum: __type(name: "CustomerTicketSupplierStatusEnum") {
      name
      kind
      enumValues {
        name
      }
    }
  }
  ```
 */
export const graphqlEnumToOptions = ({ enumValues, enumName, prependPlaceholder = false, blacklistValues = []}) => {
  const options = enumValues.map( item => ({ 
    label: I18n.t(`enum.${enumName}.${item.name.toLowerCase()}`),
    value: item.name 
  }) ).filter( item => !blacklistValues.includes(item.value) );

  if(prependPlaceholder){
    return [
      { label: "-", value: "" }, // placeholder
      ...options
    ]
  }
  else{
    return options;
  }
}