import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { PageActions, Toast, Frame } from '@shopify/polaris';

import axiosRails from '@utils/axios-rails';

const defaultError = I18n.t("actions.default_error");

// Must be wrapped in a Frame parent for Toasts to work
class FormPageActions extends Component {
  state = {
    localLoading: false,
    showToast: false,
    toastMessage: "",
  }
  render() {
    const { localLoading, showToast, toastMessage } = this.state;
    const props = this.props;
    return (
      <div className="mt10">
        <PageActions
          primaryAction={ props.onPrimaryAction ? {
            content: I18n.t("actions.save"),
            loading: props.loading || localLoading,
            onAction: props.onPrimaryAction
          } : null }
          
          secondaryActions={ props.deleteURL ? [
            {
              content: props.deleteText || I18n.t("actions.delete"),
              destructive: true,
              loading: props.loading || localLoading,
              onAction: this.handleDelete,
            },
          ] : null }
        />
        { showToast ?
          <Toast 
            content={toastMessage} 
            onDismiss={this.toggleToast} />
          : null
        }
      </div>
    );
  }

  // TODO use a modal instead of confirm
  handleDelete = async () => {
    if (confirm('Esta acción es irreversible, continuar?')) {
        // Save it!
      await this.setState({ localLoading: true });
      try {
        const response = await axiosRails.delete(this.props.deleteURL);
        const { data } = response;
        if(data.success){
          alert("Eliminado con éxito");
          window.location = data.redirectURL;
        }
        else{
          // alert("An error ocurred. ");
          await this.setState({
            showToast: true,
            toastMessage: data.message || (data.errors && data.errors.base) || defaultError
          })
          console.log(data);
        }
      } catch (error) {
        alert("Ocurrio un error.")
      }
      await this.setState({ localLoading: false });
    } else {
      // Do nothing!
    }
  }

  toggleToast = () => {
    this.setState(({showToast}) => ({showToast: !showToast}));
  };
}


FormPageActions.propTypes = {
  onPrimaryAction: PropTypes.func,
  loading: PropTypes.bool,
  deleteURL: PropTypes.string,
  deleteText: PropTypes.string,
};

FormPageActions.defaultProps = {

}


export default FormPageActions;
