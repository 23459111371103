import React, {useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';

import polarisFilterEs from '@utils/polarisFiltersEs';
import { AppProvider, Page, Card, Frame, Toast, Filters, ChoiceList, Pagination, DatePicker, Select, Button } from '@shopify/polaris';
import axiosRails from '@utils/axios-rails';
import { List } from 'immutable';

import withFilteredPagination from '@shared/withFilteredPagination';
import { disambiguateLabelForFilter } from 'utils';
import { pagyShape } from 'utils/shapes';

import { orderColumns } from '@utils/columns';
import IndexResourceList from '@shared/IndexResourceList';

const propTypes = {
  orders: PropTypes.array,
  statuses: PropTypes.array.isRequired,
  couriers: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  admin: PropTypes.bool.isRequired,
  pagy: pagyShape.isRequired,
  paginationClass: PropTypes.string,
  exportSubmitURL: PropTypes.string.isRequired,
}

const OrdersListWithFilters = (props) => {
  const exportLinkRef = useRef(null);
  const [fileBase64, setFileBase64] = useState(0);

  const [toast, setToast] = useState("");
//////////////Date Time Picker/////////////////////////
const dateTime= new Date()
const beginDate= dateTime.setHours(0,0,0,0)
const [{month, year}, setDate] = useState({
  month: dateTime.getUTCMonth(),
  year: dateTime.getUTCFullYear(),
});
const [selectedDates, setSelectedDates] = useState({
  start: new Date(beginDate),
  end: new Date(beginDate),
});
const [selectedDatesDelivered, setSelectedDatesDelivered] = useState({
  start: new Date(beginDate),
  end: new Date(beginDate),
});
// ---------------------------------------------------
const [couriers, setCouriers] = useState( " ");
const [teams,  setTeams] = useState( " ")

  const { records, loading, updateFilter, filterState, startLoading, stopLoading, setRecords, nextPage, previousPage, hasNext, hasPrevious } = props;
  const orders = records;

  const handleExport = async () => {
    try {
      startLoading();
      const response = await axiosRails.post(props.exportSubmitURL, {
        filter: filterState
      })
      const { data } = response;
      setFileBase64(`data:application/xlsx;base64,${data.base64File}`);
      exportLinkRef.current.click();
    } 
    catch (error) {
      console.error("Export error", error);
      alert("Ocurrio un error, por favor intente nuevamente.")
    }
    stopLoading();
  }

  const dismissToast = () => setToast("");
  const onUnassign = async (order) => {
    console.log("asdadadasd")
    console.log(order)
    if(loading){
      return false;
    }
    if (confirm("Esta acción es irreversible, continuar?")) {
      startLoading();
      try {
        const response = await axiosRails.post(order.unassign_url); 
        const { data } = response;
        if(data.success){
          let recordsList = List(records);
          const currentOrderIndex = recordsList.findIndex( (leOrder) => leOrder.id == data.order.id);
          if(data.can_view){ // can view unassigned order (only admins)
            setRecords( recordsList.set(currentOrderIndex, data.order).toJS() );
          }
          else{
            setRecords( recordsList.delete(currentOrderIndex).toJS() );
          }
        }
        else{
          // failed
        }
        setToast(data.message);

      } catch (error) {
        console.warn(error);
        setToast(I18n.t("messages.request_error"));
      }

      stopLoading();
    }
  }

  const updateStatus = (values) => updateFilter(values[0], "status", "filter")


  // TODO Replace all these methods with as few shared methods as possible.
  const updateCouriers = (value) => {
   value === " " ? setCouriers(" ") : setCouriers(parseInt(value))
   updateFilter(value, "courier_id", "filter")
  }

  const updateTeams= (value) => {
    setTeams(value)
    updateFilter(value[0], 'team_id', 'filter')
  }
  const updateDateCreation =(values) => {
    setSelectedDates(values)
    updateFilter(values, "created_at", "filter")
  }
  const updateDateDelivered =(values) => {
    setSelectedDatesDelivered(values)
    updateFilter(values, "delivered_at", "filter")
  }
  const updateQuery = (val) => updateFilter(val, "order_number", "delayed");
  const clearQuery = () => updateFilter("", "order_number", "delayed");
  const handleMonthChange = useCallback(
      (month, year) => setDate({month, year}),
      [],
  );

  // TODO low priority find a way to maybe reduce the amount of lines to set up the filters components
  const filters = [

    {
      key: 'status',
      label: I18n.t("activerecord.attributes.order.status"),
      filter: (
        <ChoiceList
          title={ I18n.t("activerecord.attributes.order.status") }
          titleHidden
          choices={ props.statuses }
          selected={ filterState.status }
          onChange={ updateStatus }
        />
      ),
      shortcut: false,
    },
    {
      
      key: 'created_at',
      label: I18n.t("activerecord.attributes.order.created_at"),
      filter: (
          <DatePicker
              month={month}
              year={year}
              onChange={updateDateCreation}
              onMonthChange={handleMonthChange}
              selected={selectedDates}
              allowRange={true}
          />
      ),
      shortcut: false,
    },
    {
      key: 'delivered_at',
      label: I18n.t("activerecord.attributes.order.delivered_at"),
      filter: (
          <DatePicker
              month={month}
              year={year}
              onChange={updateDateDelivered}
              onMonthChange={handleMonthChange}
              selected={selectedDatesDelivered}
              allowRange={true}
          />
      ),
      shortcut: false,
    },
    {
      key: 'courier_id',
      label: I18n.t("activerecord.attributes.order.courier_id"),
      filter: (
          <Select
              label={ I18n.t("activerecord.attributes.order.courier_id") }
              labelHidden
              options={ props.couriers }
              value={ couriers }
              onChange={ updateCouriers }
          />
      ),
      shortcut: false,
    },
  ];

  { props.admin &&
    filters.push(
        {
          key: 'team',
          label: I18n.t("activerecord.models.team.one"),
          filter: (
              <ChoiceList
                  title={ I18n.t("activerecord.models.team.one") }
                  titleHidden
                  choices={ props.teams }
                  selected={ teams || [] }
                  onChange={ updateTeams }
              />
          ),
          shortcut: false,
        }
    )

  }

  const clearFilterAndUpdate = useCallback( (filterName, clearValue = " ", trigger = "filter") => updateFilter(clearValue, filterName, trigger), [] );
  const handleFiltersClearAll = useCallback(() => {
    clearFilterAndUpdate("status", " ", "none");
    clearFilterAndUpdate("courier_id", " ", "none");
    setCouriers(" ");
    clearFilterAndUpdate("team_id", " ", "none");
    setTeams(" ")
    clearFilterAndUpdate("created_at", {}, "none");
    clearFilterAndUpdate("delivered_at", {});
  },[clearFilterAndUpdate])

  
  // Search In Props The name from Team or Courier
  const findNameOptions= (key, value)=>{
    return props[key].find((val)=>{
      return val.value === Number(value)
    })
  }
  // OPTIMIZE. Search a way to setup at once all filters and then FILTER out those with cleared value from array instead of these many Ifs
  const appliedFilters = [];
  if (!(filterState.status == " ")){
    const key = 'status';
    appliedFilters.push({
      key,
      label: disambiguateLabelForFilter(key, filterState.status),
      onRemove: () => clearFilterAndUpdate(key),
    });
  }
  if (!(filterState.courier_id == " ")) {
    const key = 'couriers';
    appliedFilters.push({
      key,
      label: disambiguateLabelForFilter(key, findNameOptions(key,filterState.courier_id)),
      onRemove: () => { clearFilterAndUpdate("courier_id"), setCouriers(" ") },
    });
  }
  if (!(filterState.team_id == " ")) {
    const key = 'teams';
    appliedFilters.push({
      key,
      label: disambiguateLabelForFilter(key, findNameOptions(key, filterState.team_id)),
      onRemove: () => { clearFilterAndUpdate("team_id"), setTeams(" ") },
    });
  }
  if(!(JSON.stringify(filterState.created_at) === '{}' )){
    const key = 'created_at';
    appliedFilters.push({
      key,
      label: disambiguateLabelForFilter(key, selectedDates),
      onRemove: () => clearFilterAndUpdate("created_at", {}),
    });
  }
  if(!(JSON.stringify(filterState.delivered_at) === '{}' )){
    const key = 'delivered_at';
    appliedFilters.push({
      key,
      label: disambiguateLabelForFilter(key, selectedDates),
      onRemove: () => clearFilterAndUpdate("delivered_at", {}),
    });
  }

  const handleSort = (key, direction) => updateFilter({ value: key, order: direction }, "sort", "filter");

  const filterControl = (
    <AppProvider i18n={polarisFilterEs}>
      <Filters
        queryPlaceholder='ID orden, recibido por, número de orden, número de tracking'
        queryValue={ filterState.order_number }
        filters={filters}
        appliedFilters={appliedFilters}
        onQueryChange={ updateQuery }
        onQueryClear={ clearQuery }
        onClearAll={handleFiltersClearAll}
      />
    </AppProvider>
  )

  return (
    <div>
      <div className="flex justifyEnd alignItemsCenter pv12">
        <Button primary onClick={ handleExport }>
          Exportar XLSX
        </Button>
        <a id="export-placeholder-link" ref={ exportLinkRef } href={ fileBase64 } download={`orders.xlsx`}></a>
      </div>
      <Card title={ I18n.t("app.last_orders") }>
        <div>
          <IndexResourceList
            withFilters
            items={orders}
            columnsInfo={orderColumns}
            resourceListProps={{
              filterControl: filterControl
            }}
            loading={ loading }
            accessorUtils={{
              baseUrl: props.admin ? "/orders/:id" : undefined,
              onUnassign,
            }}
            sort={ filterState.sort }
            onSort={ handleSort }
          />
          <div className="flex justifyCenter mt20 mb10">
            <Pagination
              hasPrevious={ hasPrevious }
              onPrevious={ previousPage }
              hasNext={ hasNext }
              onNext={ nextPage }
            />
          </div>
          {/* Need a FRAME component to wrap for this to work */}
          { toast && <Toast content={toast} onDismiss={ dismissToast } /> }
        </div>
      </Card>
    </div>
  );
}

OrdersListWithFilters.propTypes = propTypes;

export default withFilteredPagination(OrdersListWithFilters, {
  mapPropsToInitialRecords: (props) => props.orders,
  mapPropsToInitialFilters: () => ({
    order_number: "",
    status: " ",
    created_at: {},
    delivered_at:{},
    courier_id: " ",
    team_id: " ",
    sort: { value: null, order: null },
  }),
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "filter",
  filterURL: "/orders/filter",
  paginationClass: (props) => props.paginationClass,
});

// {
//   toast &&
//   <Toast content={toast} onDismiss={dismissToast} />
// }