import React, { useState, useCallback} from 'react';
import PropTypes from 'prop-types';

import { Collapsible, Button, List } from '@shopify/polaris';
import { selectOptionsProp } from 'utils/shapes';

const propTypes = {
  items: selectOptionsProp.isRequired,
  collapsedVisibleItems: PropTypes.number, // must be > 0

  initialOpen: PropTypes.bool,
}

const defaultProps = {
  collapsedVisibleItems: 1,

  initialOpen: false,
}

const CollapsibleItems = (props) => {
  const [visibleItems, setVisibleItems] = useState( props.items.slice(0, props.collapsedVisibleItems) ); // set collapsed visible items
  const [hiddenItems, setHiddenItems] = useState( props.items.slice( props.collapsedVisibleItems) );
  const [open, setOpen] = useState(props.initialOpen);
  
  const anyHiddenItems = hiddenItems.length > 0;
  
  const handleToggle = useCallback((event) => {
    console.log("x", event);
    event.preventDefault();
    event.stopPropagation();
    setOpen((open) => !open);
  }, []);

  
  return (
    <div>
      <List type="bullet">
        { visibleItems.map( (item) =>
          <List.Item key={ item.value }>{ item.label }</List.Item>
        )}
        
        <Collapsible open={open}>
          { hiddenItems.map( (item) =>
            <List.Item key={ item.value }>{ item.label }</List.Item>
          )}
        </Collapsible>
      </List>
      { anyHiddenItems &&
        <div className="ml16">
          <Button plain onClick={ handleToggle }>
            { open ? I18n.t("actions.view_less") : I18n.t("actions.view_more") }
          </Button>
        </div>
      }
    </div>
  );
};

CollapsibleItems.propTypes = propTypes;
CollapsibleItems.defaultProps = defaultProps;

export default CollapsibleItems;