import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { DataTable } from '@shopify/polaris';
import { renderAccessorValue } from '@utils/columns';

const propTypes = {
  items: PropTypes.array.isRequired,
  columnsData: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    header: PropTypes.string,
    accessor: PropTypes.oneOfType([ PropTypes.string, PropTypes.func, ]).isRequired
  })).isRequired,

  columnUtils: PropTypes.object, // Utils to use on accessors
}

const defaultProps = {
  columnUtils: {},
}

// research filters with react useContext
const PolarisRecordsTable = (props) => {

  // Return array of arrays, each array is a row with the columns values
  const rowMarkup = props.items.map(item => { // For each row/record
    return props.columnsData.map( (col, index) => ( // Each row columns (cells)
      renderAccessorValue(item, col, props.columnUtils)
    ));
  });

  const headerMarkup = useMemo( () => {
    return props.columnsData.map( (col, index) => col.header)
  }, []);

  const columnContentTypes = useMemo( () => {
    return props.columnsData.map( (col) => 'text');
  })

  return (
    <DataTable
      headings={headerMarkup}
      rows={rowMarkup}
      columnContentTypes={columnContentTypes} 
    />
  )
};

PolarisRecordsTable.propTypes = propTypes;
PolarisRecordsTable.defaultProps = defaultProps;

export default PolarisRecordsTable;