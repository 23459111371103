// New way from 2024 onward to manage UI queries inside the App
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { onError } from "@apollo/client/link/error";

// Token is set on controller. Internal API
const element = document.querySelector('[data-admin-token]');
const adminToken = element ? element.getAttribute('data-admin-token') : null;

const client = new ApolloClient({
  uri: '/admin/graphql',
  cache: new InMemoryCache(),
  headers: {
    'Authorization': `Bearer ${adminToken}`
  },
});

export default client;