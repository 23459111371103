import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { FormLayout, Select } from '@shopify/polaris';
import BasicTextField from '@shared/BasicTextField';
import { ta } from 'utils';

const propTypes = {
  renderProps: PropTypes.object.isRequired,
  roles: PropTypes.array.isRequired,
}

const defaultProps = {

}

const UserForm = ({ renderProps, ...props }) => {
  return (
    <FormLayout>
      <hr />
      <BasicTextField
        name="first_name"
        label={ta("general.first_name")}
        {...renderProps}
      />
      <BasicTextField
        name="last_name"
        label={ta("general.last_name")}
        {...renderProps}
      />
      <BasicTextField
        name="email"
        label={ta("user.email")}
        {...renderProps}
      />
      <BasicTextField
        name="password"
        type="password"
        label={ta("user.password")}
        {...renderProps}
      />
      <Select
        id="role"
        name="role"
        label={ta("user.role")}
        options={props.roles}
        value={renderProps.values.role}
        {...renderProps}
        onChange={renderProps.handleChange}
      />
    </FormLayout>
  )
};

UserForm.propTypes = propTypes;
UserForm.defaultProps = defaultProps;

export default UserForm;