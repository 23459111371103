import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

import { Page, AppProvider, Pagination, Card, Filters, ChoiceList } from '@shopify/polaris';
// import IndexResourceList from '@shared/IndexResourceList';
import PolarisRecordsTable from 'components/shared/ui/PolarisRecordsTable';
import PageSkeleton from './PageSkeleton';

import { gql, useQuery, useLazyQuery } from '@apollo/client';
import { adminClient, graphqlEnumToOptions } from 'api/index';

import { customerTicketColumns } from 'utils/columns';
import polarisFilterEs from '@utils/polarisFiltersEs';

import debounce from 'lodash/debounce';
import snakeCase from 'lodash/snakeCase';
import omit from 'lodash/omit';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';

const propTypes = {
  showBaseUrl: PropTypes.string.isRequired,
}

const defaultProps = {

}

const defaultFilterState = {
  page: 1,
  query: "",
  status: null,

  itemsReceived: null,
  requireItems: null,
  resolutionAction: null,
}

const CustomerTicketsIndex = (props) => {
  const [queryValue, setQueryValue] = useState("");
  // TODO Create hook that handle filter state and expose setFilterState where page is always set to 1 when a filter value changes! (along with nextPage, prevPage, etc)
  const [filterState, setFilterState] = useState(defaultFilterState);

  const { loading, error, refetch, data } = useQuery(RECORDS_INDEX, { 
    variables: {
      filter: omit(filterState, ["page"]),
      pagination: {
        page: filterState.page,
      }
    },
    client: adminClient,
  });

  const [exportXlsx, { loading: loadingExport }] = useLazyQuery(RECORDS_EXPORT, { 
    client: adminClient,
    onCompleted: (data) => {
      const base64Data = data.customerTicketsExportXlsx;
      console.log("Exported data", base64Data);

      const link = document.createElement('a');
      link.href = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + base64Data;
      link.download = "report.xlsx";
      document.body.appendChild(link); // Append the link to the body
      link.click(); // Trigger the download
      document.body.removeChild(link); // Remove the link from the DOM

      console.log("Done")
    }
  });

  const handleExport = () => {
    exportXlsx({
      variables: {
        filter: omit(filterState, ["page"]),
      },
    })
  }

  const { data: dataCatalogs, loading: loadingCatalogs, error: errorCatalogs } = useQuery(ENUM_CATALOGS, { client: adminClient });

  // Filter execution
  const handleQueryFilter = useCallback(debounce( (value) => {
    const query = value;
    setFilterState((prevState) => ({ ...prevState, query, page: 1 }));
  }, 800), []);

  // Query display value
  const handleQueryChange = (value) => {
    setQueryValue(value);
    handleQueryFilter(value); // Debounced!
  }

  const handleFilterChange = (fieldName, values) => {
    const value = values[0];
    if(value !== ""){ // "" is the placeholder value (cannot use null on controlled components...)
      setFilterState((prevState) => ({ ...prevState, [fieldName]: value, page: 1 }));
    }
    else{
      setFilterState((prevState) => ({ ...prevState, [fieldName]: null, page: 1 }));
    }
  }

  const handleFiltersClear = () => {
    setQueryValue("");
    setFilterState(defaultFilterState);
  }

  const handlePageNext = () => {
    setFilterState((prevState) => ({ ...prevState, page: prevState.page + 1 }));
  };

  const handlePagePrev = () => {
    setFilterState((prevState) => ({ ...prevState, page: prevState.page - 1 }));
  };

  // First load
  if(loadingCatalogs){
    return <AppProvider><PageSkeleton /></AppProvider>
  }

  if(error){
    console.log("Error", error);
    return(
      <AppProvider>
        <Page>
          <p className='text-center text-error'>
            Ocurrio un error: {error.message}
          </p>
        </Page>
      </AppProvider>
    )
  }

  const filters = [
    { key: "status", label: I18n.t("activerecord.attributes.customer_ticket.status"), shortcut: true, filter: (
      <ChoiceFilter 
        value={filterState.status}
        onChange={handleFilterChange}
        fieldName={"status"}
        enumValues={dataCatalogs.statusEnum.enumValues}
        enumOptions={{
          blacklistValues: ["WAITING_RESOLUTION", "PROCESSING_RESOLUTION", "APPROVED_AWAITING_ITEMS"]
        }}
      />
    )},
    { key: 'resolutionAction', label: I18n.t("activerecord.attributes.customer_ticket.resolution_action"), filter: (
      <ChoiceFilter 
        value={filterState.resolutionAction}
        onChange={handleFilterChange}
        fieldName={"resolutionAction"}
        enumValues={dataCatalogs.resolutionActionEnum.enumValues}
      />
    )},
    { key: 'supplierStatus', label: I18n.t("activerecord.attributes.customer_ticket.supplier_status"), filter: (
      <ChoiceFilter 
        value={filterState.supplierStatus}
        onChange={handleFilterChange}
        fieldName={"supplierStatus"}
        enumValues={dataCatalogs.supplierStatusEnum.enumValues}
      />
    )},
    { key: 'customerKind', label: I18n.t("activerecord.attributes.customer_ticket.customer_kind"), filter: (
      <ChoiceFilter 
        value={filterState.customerKind}
        onChange={handleFilterChange}
        fieldName={"customerKind"}
        enumValues={dataCatalogs.customerKindEnum.enumValues}
      />
    )},
    { key: 'ticketKind', label: I18n.t("activerecord.attributes.customer_ticket.ticket_kind"), filter: (
      <ChoiceFilter 
        value={filterState.ticketKind}
        onChange={handleFilterChange}
        fieldName={"ticketKind"}
        enumValues={dataCatalogs.ticketKindEnum.enumValues}
      />
    )},
    { key: 'reason', label: I18n.t("activerecord.attributes.customer_ticket.reason"), filter: (
      <ChoiceFilter 
        value={filterState.reason}
        onChange={handleFilterChange}
        fieldName={"reason"}
        enumValues={dataCatalogs.reasonEnum.enumValues}
      />
    )},
    { key: 'requireItems', label: I18n.t("activerecord.attributes.customer_ticket.require_items"), filter: (
      <BooleanChoiceFilter
        value={filterState.requireItems}
        onChange={handleFilterChange}
        fieldName={"requireItems"}
      />
    )},
    { key: 'itemsReceived', label: I18n.t("activerecord.attributes.customer_ticket.items_received"), filter: (
      <BooleanChoiceFilter
        value={filterState.itemsReceived}
        onChange={handleFilterChange}
        fieldName={"itemsReceived"}
      />
    )},
  ];

  const appliedFilters = filters.reduce((acc, filter) => {
    const filteredValue = filterState[filter.key];

    let label;
    if(isBoolean(filteredValue)){
      // i.e. { key: itemsReceived } => `activerecord.attributes.customer_ticket.items_received: Sí`
      label = `${I18n.t(`activerecord.attributes.customer_ticket.${snakeCase(filter.key)}`)}: ${filteredValue ? "Sí" : "No"}`;
    }
    else if(!!filteredValue){ // Other than "", null or undefined
      // i.e. { key: resolutionAction } => `enum.resolutionAction.PENDING` => `enum.resolution_action.pending`
      label = I18n.t(`enum.${snakeCase(filter.key)}.${filteredValue.toLowerCase()}`);
    }
    
    // Label defined means a value was selected
    if(!!label){
      return [
        ...acc,
        {
          key: filter.key,
          label,
          onRemove: () => handleFilterChange(filter.key, [""]),
        }
      ];
    }
    else{
      return acc;
    }
  } , []);

  return (
    <AppProvider i18n={polarisFilterEs}>
      <Page 
        fullWidth
        title={ I18n.t("app.customer_tickets.title") }
        primaryAction={{
          content: I18n.t("actions.export_xlsx"),
          onAction: handleExport,
          loading: loadingExport,
          disabled: loadingExport,
        }}
      >
        <Card sectioned>
          <Filters
            filters={filters}

            queryPlaceholder={ "Nombre, Email, ID de Orden, ID Producto, Nombre producto, ID Customer" }
            queryValue={queryValue}
            onQueryChange={handleQueryChange}
            onQueryClear={ () => handleQueryChange("") }

            appliedFilters={appliedFilters}
            onClearAll={handleFiltersClear}
          />

          <PolarisRecordsTable
            items={data?.customerTickets?.items || []}
            columnsData={customerTicketColumns}
            columnUtils={{
              baseUrl: props.showBaseUrl,
            }}
          />

          <div className='mt20 flex justifyCenter'>
            <Pagination
              hasPrevious={ data?.customerTickets?.metadata.prev }
              hasNext={ data?.customerTickets?.metadata.next }
              onPrevious={ handlePagePrev }
              onNext={ handlePageNext }
            />
          </div>
        </Card>
      </Page>
    </AppProvider>
  )
};

// Enum
const ChoiceFilter = ({ value, onChange, enumValues, fieldName, enumOptions = {} }) => (
  <ChoiceList 
    selected={ value ? [value] : [""] }
    onChange={ (values) => onChange(fieldName, values) }
    allowMultiple={ false }
    choices={graphqlEnumToOptions({ 
      enumValues: enumValues,
      enumName: snakeCase(fieldName), // To translate values
      prependPlaceholder: true,
      ...enumOptions,
    })}
  />
)

const BooleanChoiceFilter = ({ value, onChange, fieldName }) => (
  <ChoiceList
    selected={ isBoolean(value) ? [value] : [""] }
    onChange={(values) => onChange(fieldName, values)}
    allowMultiple={false}
    choices={[
      { label: "-", value: "" },
      { label: "Sí", value: true },
      { label: "No", value: false },
    ]}
  />
)

const RECORDS_INDEX = gql`
query($filter: CustomerTicketFilterInput, $pagination: PaginationInput){
  customerTickets(filter: $filter, pagination: $pagination) {
    items {
      id
      name
      status
      providerName
      customerKind
      customerEmail
      customerName
      displayProviderOrderId
      productTitle
      productSku
      moneyProductOriginalTotal
      ticketKind
      reason
      resolutionPreference
      retrievalMethod
      createdAt
      supplierStatus
      resolutionAction
      requireItems
      itemsReceived
      requireItemsDisplayable

      providerOrderUrl
    }
    metadata {
      items
      pages
      next
      prev
    }
  }
}
`;

const RECORDS_EXPORT = gql`
query($filter: CustomerTicketFilterInput){
  customerTicketsExportXlsx(filter: $filter)
}
`;

// Loaded once
const ENUM_CATALOGS = gql`
fragment EnumTypeFragment on __Type {
  enumValues {
    name
  }
}

query{
  statusEnum: __type(name: "CustomerTicketStatusEnum") {
    ...EnumTypeFragment
  }

  supplierStatusEnum: __type(name: "CustomerTicketSupplierStatusEnum") {
    ...EnumTypeFragment
  }

  customerKindEnum: __type(name: "CustomerKindEnum") {
    ...EnumTypeFragment
  }

  ticketKindEnum: __type(name: "CustomerTicketKindEnum") {
    ...EnumTypeFragment
  }

  reasonEnum: __type(name: "CustomerTicketReasonEnum") {
    ...EnumTypeFragment
  }

  resolutionActionEnum: __type(name: "CustomerTicketResolutionActionEnum") {
    ...EnumTypeFragment
  }
}
`;

CustomerTicketsIndex.propTypes = propTypes;
CustomerTicketsIndex.defaultProps = defaultProps;

export default CustomerTicketsIndex;