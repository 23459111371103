import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, AppProvider, Page, Frame, TextStyle, Heading, TextField, Label, Form, Button, List, DescriptionList } from '@shopify/polaris';
import axios from 'utils/axios-rails';

import DeliveryDetailAverageSamples from './DeliveryDetailAverageSamples';

const propTypes = {
  lastUpdatedAt: PropTypes.string.isRequired,
  queryURL: PropTypes.string.isRequired,
}

const defaultProps = {

}

const IntelligenceSmartPriceDashboard = (props) => {
  const [sku, setSKU] = useState("");
  const [prices, setPrices] = useState({ brightpearl: null, shopify: null, intelligence: null });
  const [priceLastUpdated, setPriceLastUpdated] = useState({}); // TODO merge smartPrice and lastUpdated on one state
  const [loading, setLoading] = useState(false);

  console.log("IntelligenceSmartPriceDashboard props: ", props);

  const queryPrice = async () => {
    if(!sku)
      return; // do not query if sku is not specified
    setLoading(true);
    try {
      const response = await axios.post(props.queryURL, {
        sku: sku,
      });
      const data = response.data;
      setPrices({
        intelligence: data.smart_price,
        shopify: data.shopify_price,
        brightpearl: data.brightpearl_price,
      });
      setPriceLastUpdated( response.data.last_updated_at);
      console.log("DD", response.data)
    } catch (error) {
      alert("Ocurrio un error!");
      console.warn(error);
    }
    setLoading(false);
  }

  const pricesList = [
    { term: "Intelligence", description: prices.intelligence },
    { term: "Shopify", description: prices.shopify },
    { term: "Brightpearl", description: prices.brightpearl },
  ]

  return (
    <AppProvider>
      <Frame>
      <Page
          title="Dashboard Intelligence"
          breadcrumbs={[{content: I18n.t("actions.back"), url: '/'}]}
        >
          <Card sectioned>
            <Form onSubmit={ queryPrice }>
              <TextField
                value={ sku }
                label="Consultar Smart Price por SKU"
                disabled={ loading }
                placeholder="BU0XXX"
                onChange={ setSKU }
                clearButton
                onClearButtonClick={ () => setSKU("") }
                connectedRight={<Button disabled={ !sku } loading={loading} onClick={ queryPrice }>Buscar</Button>}
              />
            </Form>

            <div className="mt8">
              <p>
                <TextStyle variation="strong">
                  Hora de ultima tarea para obtener y procesar Smart Price :
                </TextStyle>
              </p>
              <ul className="flex">
                <li className="mr28">CR  - { props.lastUpdatedAt }</li>
                <li>UTC - { props.lastUpdatedAtUtc }</li>
              </ul>
            </div>

            { prices.brightpearl && priceLastUpdated &&
              <div>
                <p className="mt12">
                  <TextStyle variation="subdued">
                    Hora de último cambio de Smart Price en IN:
                  </TextStyle>
                </p>
                <ul className="flex">
                  <li className="mr28"><TextStyle variation="subdued">CR  - { priceLastUpdated.local }</TextStyle></li>
                  <li><TextStyle variation="subdued">UTC - { priceLastUpdated.utc }</TextStyle></li>
                </ul>

                <DescriptionList
                  items={ pricesList }
                />
              </div>
            }
          </Card>
          
          { props.sampleData.map( (sample, index) => <DeliveryDetailAverageSamples key={ index } sampleData={ sample } /> ) }

        </Page>
      </Frame>
    </AppProvider>
  )
};

IntelligenceSmartPriceDashboard.propTypes = propTypes;
IntelligenceSmartPriceDashboard.defaultProps = defaultProps;

export default IntelligenceSmartPriceDashboard;