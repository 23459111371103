import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Form, TextField, PageActions, Button, Heading } from '@shopify/polaris';

import logo from "@components/../../assets/images/brightpearl-logo.svg";
import Webhooks from "./components/Webhooks";

import { brightpearlIntegrationProps } from 'utils/shapes';
import axios from 'utils/axios-rails';

const propTypes = {
  ...brightpearlIntegrationProps,
}

const defaultProps = {
  active: false,
  loading: false
}

const BrightPearlLogin = (props) => {
  console.log(props)
  const { active } = props;

  const handleSubmit = async () => {
    let { submitURL, setLoading } = props;
    setLoading(true)

    try {
      let response = await axios.post(submitURL, {
        enable: !active
      })
      if(response.data.success){
        if (response.data.redirectURL){
          // redirect if required.
          window.location = response.data.redirectURL;
          return true
        } else {
          alert(response.data.message);
        }
        
      } // End success data
      else{
        console.log(response.data);
        alert(response.data.message);
      }
    } catch (error) {
      alert(error);
    } // END try/catch
    setLoading(false)
}

  return(
    <div>
      <div className="mb10">
        <img src={ logo } height="64" style={{ backgroundColor: "#000" }} />
      </div>
      { active  &&
        <h5>
          Conexión con Bright Pearl activa.
        </h5>
      }

      <Heading >
        Presione el botón para empezar el proceso de autenticación con BrightPearl.
      </Heading>

      <div className="mt8">
        <Button
          loading={ props.loading }
          onClick={ handleSubmit }
          size="large"
          destructive={ active }
          primary={ !active }
        >
          { active ? I18n.t("actions.disable") : I18n.t("actions.enable") } BrightPearl
        </Button>
      </div>


      { active && 
        <Webhooks
          webhooks={props.webhooks}
          submitURL={ props.webhooksSetupURL }
          topicAttributeName="subscribeTo"
          endpointAttributeName="uriTemplate"
        />
      }
    </div>
  )
}

BrightPearlLogin.propTypes = propTypes;
BrightPearlLogin.defaultProps = defaultProps;

export default BrightPearlLogin;