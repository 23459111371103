import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ProfileIcon from '@components/../../assets/images/icons/customer_tickets/profile.svg';
import CasinoIcon from '@components/../../assets/images/icons/customer_tickets/casino.svg';

import { useFormikContext } from 'formik';
import FormikInput from '@components/shared/ui/FormikInput';
import SquareBoxRadioButtons from './SquareBoxRadioButtons';

import { TextStyle } from '@shopify/polaris';

const propTypes = {
  customerKinds: PropTypes.shape({
    CUSTOMER: PropTypes.string.isRequired,
    BUSINESS: PropTypes.string.isRequired,
  }).isRequired,
}

const defaultProps = {

}

const CustomerIdentificationStep = (props) => {
  const formik = useFormikContext();

  const handleCustomerKindChange = (value) => {
    formik.setFieldValue("customerKind", value);
  }

  return (
    <div id="customer-identification-step">
      <div className='flex alignItemsCenter mb12'>
        <hr className="flex1" />
        <p className='mh20 text-neutral-gray-200 fs12'>SOY:</p>
        <hr className="flex1" />
      </div>

      <SquareBoxRadioButtons
        className="mb20 lg:mb40"
        value={formik.values.customerKind}
        onClick={handleCustomerKindChange}
        options={[
          {
            description: 'Cliente Unimart.com',
            value: props.customerKinds.CUSTOMER,
            icon: ProfileIcon,
            className: "gradient-profile",
          },
          {
            description: 'Cliente Unimart Business (empresas)',
            value: props.customerKinds.BUSINESS,
            icon: CasinoIcon,
            className: "gradient-casino"
          }
        ]}
        error={!!formik.touched.customerKind && formik.errors.customerKind}
      />

      <FormikInput
        name="customerEmail"
        label="Email o teléfono"
        placeholder='email@example.com'
        className='mb20'
        keyboardType='email-address'
      />

      <FormikInput 
        name="formOrderNumber"
        label="Número de orden"
        placeholder='Ej: #123456'
      />
      
    </div>
  )
};

CustomerIdentificationStep.propTypes = propTypes;
CustomerIdentificationStep.defaultProps = defaultProps;

export default CustomerIdentificationStep;