import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { gql, useQuery, useMutation } from '@apollo/client';
import { adminClient, userErrorsToFormik } from 'api/index';
import TagsInput from 'react-tagsinput';

import { Page, Card, TextStyle, AppProvider, Frame, Toast, Select } from '@shopify/polaris';
import { useFormik } from 'formik';

const propTypes = {

}

const defaultProps = {

}

const CommonSettings = (props) => {
  const [toast, setToast] = useState(null);
  
  const { loading, error, data } = useQuery(APP_GET, { client: adminClient });
  const { loading: loadingCatalogs, error: errorCatalogs, data: dataCatalogs } = useQuery(CATALOGS, { 
    client: adminClient,
    variables: {
      filter: {
        disabled: false
      }
    }
  });
  
  const [appUpdate] = useMutation(APP_UPDATE, { client: adminClient });
  
  const formik = useFormik({
    initialValues: {
      ...data?.app
    },
    enableReinitialize: true,
    onSubmit: async (values, formikBag) => {
      console.log("onSubmit", values);
      const result = await appUpdate({ 
        variables: { 
          input: {
            warrantyExcludedBrands: values.warrantyExcludedBrands,
            rmaTaskDefaultTeamId: values.rmaTaskDefaultTeamId
          }
        }
      });

      const appRecord = result.data.appUpdate.app;
      if(!!appRecord){
        setToast(I18n.t("messages.save_success"));
        formikBag.setValues(appRecord);
      }
      else{
        setToast(I18n.t("messages.record_errors"));
        formikBag.setErrors(userErrorsToFormik(result.data.errors));
      }

    }
  });

  if(loading || loadingCatalogs){
    return <div className='mt20 text-center'>Loading...</div>
  }

  if(error){
    return <div className='mt20 text-center'>Error!</div>
  }

  const teamOptions = [
    { label: "-", value: "" },
    ...dataCatalogs.teams.items,
  ]

  console.log("formik", formik.values);

  return (
    <AppProvider>
      <Frame>
        <Page
          title="Settings"
          primaryAction={{
            content: I18n.t("actions.save"),
            onAction: formik.handleSubmit,
            loading: formik.isSubmitting
          }}
        >
          <Card sectioned>
            <TextStyle variation="subdued">
              { I18n.t("activerecord.attributes.app.warranty_excluded_brands") }
            </TextStyle>

            <TagsInput
              value={ formik.values.warrantyExcludedBrands || [] }
              onlyUnique
              addKeys={[188, 9, 13]}
              inputProps={{
                placeholder: "HP, Dell, Apple, etc."
              }}
              addOnPaste
              pasteSplit={
                (data) => {
                  console.log("pasteSplit",data);
                  return data.split(',').map(d => d.trim())
              }}
              onChange={ (tagsList) => formik.setFieldValue("warrantyExcludedBrands", tagsList)} 
            />

            <div className='mt20'>
              <Select
                label={ I18n.t("activerecord.attributes.app.rma_task_default_team_id") }
                options={teamOptions}
                value={formik.values.rmaTaskDefaultTeamId || ""}
                onChange={(value) => formik.setFieldValue("rmaTaskDefaultTeamId", value)}
              />
            </div>
          </Card>

          { toast && <Toast content={ toast } onDismiss={() => setToast(null)} />}
        </Page>
      </Frame>
    </AppProvider>
  )
};

const APP_GET = gql`
  query {
    app {
      id
      warrantyExcludedBrands
      rmaTaskDefaultTeamId
    }
  }
`;

const APP_UPDATE = gql`
  mutation ($input: AppInput!) {
    appUpdate(input: $input) {
      app {
        id
        warrantyExcludedBrands
        rmaTaskDefaultTeamId
      }
      errors {
        key
        message
      }
    }
  }
`;

const CATALOGS = gql`
  query($filter: TeamFilterInput){
    teams(filter: $filter){
      items{
        value: id
        label: name
      } # items
    }
  }
`;

CommonSettings.propTypes = propTypes;
CommonSettings.defaultProps = defaultProps;

export default CommonSettings;