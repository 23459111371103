import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import { Card, Tabs, AppProvider, Page, Frame } from '@shopify/polaris';
import BrightPearlLogin from './BrightPearlLogin';

import { brightpearlIntegrationShape } from 'utils/shapes';

const propTypes = {
  brightPearl: brightpearlIntegrationShape.isRequired
};

const defaultProps = {

}


const SettingsContainer = (props) => {
  const [selected, setSelected] = useState(0);
  const [loading, setLoading] = useState(false);

  const tabs = [
    { id: "brightPearl", content: "Bright Pearl" },
  ]

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex),
    [],
  );

  return(
    <AppProvider>
      <Frame>
        <Page
          title="Configuraciones"
          breadcrumbs={[{content: I18n.t("actions.back"), url: '/'}]}
        >
          <Card>
            <Tabs
              tabs={tabs} selected={selected} onSelect={handleTabChange}>
              <Card.Section>
                
                { selected == 0 && <BrightPearlLogin setLoading={setLoading} loading={loading} {...props.brightPearl} /> }
      
              </Card.Section>
            </Tabs>
          </Card> 
        </Page> 
      </Frame>
    </AppProvider>
  );
};

SettingsContainer.propTypes = propTypes;
SettingsContainer.defaultProps = defaultProps;

export default SettingsContainer;