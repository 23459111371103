import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Page, Card, ResourceList, ResourceItem, Avatar, TextStyle, Collapsible } from '@shopify/polaris';
import OrderItem from './OrderItem';
import TaskItem from '@views/tasks/components/TaskItem';

const propTypes = {
  items: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool, // add skeleton
  admin: PropTypes.bool, // To send to OrderItem
  itemProps: PropTypes.shape({
    compact: PropTypes.bool,
    shorcutActions: PropTypes.array,
  }),
  // filterControl
}

const defaultProps = {
  loading: false,
  itemProps: {}
}

const OrdersList = (props) => {
  console.log("OrderList", props);
  return (
    <ResourceList
      idForItem={ (item, index) => `${item.typename}-${item.id}` }
      loading={props.loading}
      resourceName={{ singular: I18n.t("activerecord.models.order.one"), plural: I18n.t("activerecord.models.order.other") }}
      items={props.items}
      renderItem={(item) => {

        return item.typename == "order" ? (
          <OrderItem
            admin={props.admin}
            order={ item }
            {...props.itemProps}
          />
        ) : (
          <TaskItem
            task={ item }
          />
        )
      }}
      filterControl={ props.filterControl }
    />
  );
}


OrdersList.propTypes = propTypes;
OrdersList.defaultProps = defaultProps;

export default OrdersList;
