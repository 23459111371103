import React, { useState } from 'react';
import PropTypes from 'prop-types';
import esTranslations from '@shopify/polaris/locales/es.json';
import { AppProvider, Page, Card, Frame } from '@shopify/polaris';
import axios from '@utils/axios-rails';

import OrdersListWithFilters from '@views/orders/components/OrdersListWithFilters';
import { pagyShape } from 'utils/shapes';

const propTypes = {
  orders: PropTypes.array,
  statuses: PropTypes.array.isRequired,
  couriers: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  admin: PropTypes.bool.isRequired,
  pagy: pagyShape.isRequired,
  urlSubmitCSV: PropTypes.string,
  exportSubmitURL: PropTypes.string.isRequired,
}

const OrdersIndexAdmin = (props) => {
  console.log("Admin", props);

  return (
    <AppProvider i18n={esTranslations}>
      <Frame>
        <Page fullWidth>
          <OrdersListWithFilters
            orders={ props.orders }
            statuses={ props.statuses }
            couriers={ props.couriers }
            teams={ props.teams}
            admin={ props.admin}
            pagy={ props.pagy }
            paginationClass={ props.paginationClass }
            exportSubmitURL={ props.exportSubmitURL }
          />
        </Page>
      </Frame>
    </AppProvider>
  );
}

OrdersIndexAdmin.propTypes = propTypes;

export default OrdersIndexAdmin;