import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { gql, useQuery, useMutation } from '@apollo/client';
import { ResourceList, ResourceItem, Avatar, TextStyle, TextField, Button } from '@shopify/polaris';

import { useFormik } from 'formik';

import { userErrorsToFormik } from 'api/index';

const propTypes = {
  customerTicketId: PropTypes.string.isRequired,
  as: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
  ]),
  onToast: PropTypes.func.isRequired,
}

const defaultProps = {
  as: 'div',
}

// Standalone to display and add comments to a ticket, requires ApolloProvider parent
// Might be able to recycle this component if the LIST query is set as a prop instead (as comments is a polymorphic model already)
const CustomerTicketComments = (props) => {
  const Cmp = props.as;

  const { data, loading, error, refetch } = useQuery(COMMENT_LIST, {
    fetchPolicy: 'network-only',
    variables: {
      id: props.customerTicketId
    }
  });

  const [createComment] = useMutation(COMMENT_CREATE, {
    onCompleted: (dataCreate) => {
      const commentCreated = dataCreate.resourceCommentCreate.resourceComment;
      if(commentCreated?.id){
        props.onToast(I18n.t("messages.create_success"));
        refetch(); // Refresh data
        formik.resetForm();
      }
      else{
        console.log("Errors", dataCreate)
        props.onToast(I18n.t("messages.one_error"));
        formik.setErrors(userErrorsToFormik(dataCreate.resourceCommentCreate.errors));
      }
      formik.setSubmitting(false);
    },
    onError: (error) => {
      console.log("Error", error);
      props.onToast(I18n.t("messages.request_error"));
      formik.setSubmitting(false);
    }
  });

  const formik = useFormik({
    initialValues: {
      content: ''
    },
    onSubmit: (values) => {
      createComment({
        variables: {
          input: {
            commentableId: props.customerTicketId,
            commentableType: 'CustomerTicket',
            content: values.content
          }
        }
      });
    }
  });

  return (
    <Cmp>
      <ResourceList 
        resourceName={{
          singular: "Comentario",
          plural: "Comentarios"
        }}
        loading={loading}
        items={data?.customerTicket?.resourceComments || []}
        selectable={false}
        renderItem={(comment, _itemId, _index) => {
          return (
            <ResourceItem
              media={<Avatar size='small' initials={ comment.user.initials } />}
            >
              <span className='font-bold'>{ comment.user.name || comment.user.email }</span>
              <p>
                { comment.content }
              </p>
              <small>
                <TextStyle variation='subdued'>
                  { I18n.l("time.formats.app_datetime", comment.createdAt) }
                </TextStyle>
              </small>
            </ResourceItem>
          )
        }}
      />

      <form onSubmit={formik.handleSubmit} className='mt12'>
        <TextField
          placeholder={"Añadir un comentario..."}
          value={formik.values.content}
          onChange={ (value) => formik.setFieldValue('content', value) }
          error={formik.errors.content}
          multiline
        />

        <div className="mt8">
          <Button
            submit
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
          >
            { I18n.t("actions.post") }
          </Button>
        </div>
      </form>
    </Cmp>
  )
};

const COMMENT_LIST = gql`
  query($id: ID!){
    customerTicket(id: $id) {
      id
      resourceComments{
        id
        content
        createdAt
        user{
          id
          email
          name
          initials
        }
      } # resourceComments
    }
  }
`;

const COMMENT_CREATE = gql`
mutation($input: ResourceCommentInput!) {
  resourceCommentCreate(input: $input) {
    resourceComment {
      id
    }
    errors {
      key
      message
    }
  }
}
`;

CustomerTicketComments.propTypes = propTypes;
CustomerTicketComments.defaultProps = defaultProps;

export default CustomerTicketComments;