import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { 
  AppProvider, Page, Button, Form, FormLayout, Card, 
  Toast, Frame, TextField, 
  TextStyle, Banner, Heading,
  DescriptionList, Layout,
  Icon, Badge
} from '@shopify/polaris';

import { EditMajorMonotone, ClipboardMinor } from '@shopify/polaris-icons';

import ProviderChoiceList from "@shared/ProviderChoiceList";

import TimelinePackage from './components/TimelinePackage';
import ResourceLogList from "./components/ResourceLogList";
import GoogleMapOneMarker from 'components/shared/GoogleMapOneMarker';
import GeneralModal from 'components/shared/GeneralModal';

import axios from 'utils/axios-rails';

const propTypes = {
  submitURL: PropTypes.string.isRequired,
  initialTimeline: PropTypes.array,
  updateCustomerAddressURLTemplate: PropTypes.string.isRequired, // Must replace ID value with the actual customer_address ID
  generateCustomerAddressURLTemplate: PropTypes.string.isRequired,
};

const defaultProps = {

}


const OrderTimelineContainer = (props) => {
  const defaultTimelineData = {
    packages: props.initialTimeline, // Package timelines (one timeline per package)
    order: {},
    customer_address: null,
    dispatch_control_name: null,
  }

  const editPreferencesModalRef = useRef(null);

  const [searchValue, setSearch] = useState("");
  const [timelineData, setTimelineData] = useState(defaultTimelineData);

  const [provider, setProvider] = useState("bright_pearl");
  const [toast, setToast] = useState(null);
  const [loading, setLoading] = useState(false);

  const [editableDeliveryInstructions, setEditableDeliveryInstructions] = useState(""); // To edit customer_address.delivery_instructions on modal (a virtual copy of the value)
  
  // To generate admin edit URL
  const editURLInputRef = useRef(null); // For the value of customerAddressEditURL to be copied to clipboard
  const [customerAddressEditURL, setCustomerAddressEditURL] = useState(""); // To copy to clipboard

  // Copy to clipboard when customerAddressEditURL changes!
  useEffect(() => {
    if(!!customerAddressEditURL){
      if(document.queryCommandSupported('copy')){
        editURLInputRef.current.select();
        document.execCommand('copy');
        console.log("Copied to clipboard", customerAddressEditURL);
        setToast("URL copiado al portapapeles");
      }
      else{
        setToast("Copiado automático no es soportado por este navegador.");
      }
    }
  }, [customerAddressEditURL]);

  const handleTimelineQuery = async () => {
    setLoading(true)
    if (!searchValue) {
      setToast(I18n.t("errors.not_empty_value"));
    }
    else{
      try{
        const response = await axios.post(props.submitURL, {
          orderId: searchValue,
          providerName: provider,
        });
        const data = response.data;
        console.log("response", data);
        if(!!data.payload.packages){
          setTimelineData(data.payload);
          const customerAddress = data.payload.customer_address;
          if(!!customerAddress){
            console.log("customerAddress", customerAddress.delivery_instructions);
            setEditableDeliveryInstructions(customerAddress.delivery_instructions);
          }
        }
        else{
          setTimelineData(defaultTimelineData); // Reset
        }

        setToast(response.data.message);
      }
      catch(error){
        setToast("Ocurrio un error con el servidor, por favor intente nuevamente en unos segundos.");
      }
    }

    setLoading(false);
  };

  // Only allow deliveryInstructions for now, if more values are added, consider using formik
  const handleCustomerAddressUpdate = async () => {
    const customerAddress = timelineData?.customer_address;
    if(!customerAddress) return;
    try {
      setLoading(true);
      const updateURL = props.updateCustomerAddressURLTemplate.replace("ID", customerAddress.id);
      const response = await axios.put(updateURL, {
        customer_address: { delivery_instructions: editableDeliveryInstructions },
      });
      if(response.data.success){
        setToast(response.data.message);
        handleClosePreferencesModal({ resetValues: false });
      }
      else{
        setToast(response.data.message);
      }
    } catch (error) {
      setToast(I18n.t("messages.request_error"));
      console.log("Error updating customer address", error);
    }
    setLoading(false);
  };

  const handleGenerateCustomerAddressURL = async () => {
    const cusotmerAddress = timelineData?.customer_address;
    if(!cusotmerAddress) return;

    try {
      setLoading(true);
      const generationURL = props.generateCustomerAddressURLTemplate.replace("ID", cusotmerAddress.id);
      const response = await axios.post(generationURL);
      const addressEditURL = response.data.payload;
      setCustomerAddressEditURL(addressEditURL); // useEffect will copy this to clipboard and display a toast
    } 
    catch (error) {
      setToast("Ocurrio un error al generar el enlace");
      console.log("Error for handleGenerateCustomerAddressURL", error);
    }
    setLoading(false);
  }

  const handleOpenPreferencesModal = () => editPreferencesModalRef.current.open();
  const handleClosePreferencesModal = ({ resetValues = true }) =>  {
    if(resetValues){
      setEditableDeliveryInstructions(timelineData?.customer_address?.delivery_instructions || "");
    }
    editPreferencesModalRef.current.close();
  }

  // Build data for DescriptionList
  const buildDeliveryPreferences = (customerAddress) => {
    if(!customerAddress) return null;

    let items = [
      { term: I18n.t("activerecord.attributes.customer_address.availability"), description: customerAddress.availability && I18n.t(`enum.availability.${customerAddress.availability}.display`) },
      { term: I18n.t("activerecord.attributes.customer_address.recipient_subject"), description: customerAddress.recipient_subject && I18n.t(`enum.recipient_subject.${customerAddress.recipient_subject}.display`) },
    ];

    if(!!customerAddress.recipient_name){ // Only display when relevant
      items = [
        ...items,
        { term: I18n.t("activerecord.attributes.customer_address.recipient_name"), description: customerAddress.recipient_name },
      ];
    }

    items = [
      ...items,
      { 
        term: (
          <div className='flex alignItemsCenter'>
            <span className='mr8'>{ I18n.t("activerecord.attributes.customer_address.delivery_instructions") }</span>
            <Button onClick={ handleOpenPreferencesModal } size="slim" plain monochrome><Icon source={EditMajorMonotone} /></Button>
          </div>
        ), 
        description: editableDeliveryInstructions
      },
      { term: I18n.t("activerecord.attributes.customer_address.form_referral"), description: customerAddress.form_referral && I18n.t(`enum.form_referral.${customerAddress.form_referral}`) },
      { term: I18n.t("activerecord.attributes.general.updated_at"), description: I18n.l(`time.formats.app_datetime`, customerAddress.updated_at) },
    ];

    return items;
  }

  const handleToastDismiss = () => setToast(null);

  const { order, packages, customer_address } = timelineData;

  const deliveryPreferences = buildDeliveryPreferences(customer_address);
  const hasAddressWithCoordinates = !!customer_address && !!customer_address.lat && !!customer_address.lon;

  console.log("Timeline Master Data", timelineData);

  return(
    <AppProvider>
      <Frame>
        <Page 
          fullWidth
          title={ "Order Timeline" }>

          {/* Main Section - Inputs and Timeline */}
          <Card sectioned>
            
            <Form onSubmit={ handleTimelineQuery }>
              <FormLayout>
                <ProviderChoiceList
                  value={ provider }
                  handleChange={ setProvider }
                />
                <TextField 
                  label={ I18n.t("shopify.order.id") }
                  placeholder="#100000"
                  onChange={ (val, id) => setSearch(val) }
                  value={ searchValue }
                  disabled={ props.loading }
                />
                <Button
                  loading={ loading }
                  onClick={ handleTimelineQuery }
                >
                  { I18n.t("actions.search") }
                </Button>
              </FormLayout>
            </Form>

            { order && order.issue &&
              <div id="timelineWarningBanner" className='mt8 flex justifyCenter alignItemsCenter'>
                <div className="banner-container">
                  <Banner title={ order.issue.title } status="warning">
                    <TextStyle>
                      { order.issue.description }
                    </TextStyle>
                  </Banner>
                </div>
              </div>
            } 
          </Card>
          
          { packages.map( (data, index) => 
            <TimelinePackage
              key={ index }
              package={ data }
              order={ order }
              deliveryEstimatedDisplay={ !!order && order.delivery_estimated_display }
            />
          )}

          {/* Customer Address Section */}
          { customer_address &&
            <Card 
              title="Ubicación y Preferencias"
              actions={[
                { content: "Generar enlace de ubicación y preferencias", icon: ClipboardMinor, disabled: loading, onAction: handleGenerateCustomerAddressURL }
              ]}
            >
              <Card.Section>
                <div className='flex alignItemsCenter'>
                  <Heading>Tiene:</Heading>
                  <Badge status={ hasAddressWithCoordinates ? "success" : "warning" }>
                    { hasAddressWithCoordinates ? "Si" : "No" }
                  </Badge>
                </div>
              </Card.Section>

              <Card.Section title="Preferencias">
                <DescriptionList items={deliveryPreferences} />
              </Card.Section>

              { hasAddressWithCoordinates &&
                <Card.Section title="Ubicación">
                  <div style={{ height: '312px' }}>
                    <GoogleMapOneMarker
                      markerLoc={{ lat: Number(customer_address.lat), lng: Number(customer_address.lon) }}
                      mapCenterLoc={{ lat: Number(customer_address.lat), lng: Number(customer_address.lon) }}
                      moveMapCenter={ true }
                    />
                  </div>
                </Card.Section>
              }

              {/* Input to copy edit address URL to clipboard, must not be hidden but can be "hidden" */}
              <form style={{ width: 0, height: 0, opacity: 0.01 }}>
                <textarea readOnly value={ customerAddressEditURL } ref={editURLInputRef} />
              </form>

            </Card>
          }

          {/* ResourceLogList are displayed only once (no per timeline) */}
          { order && order.order_logs &&
            <ResourceLogList title="Historial de Entrega" logs={ order.order_logs } />
          }

          {/* Small space because for some reason Frame takes a "bite" on lower margin of Card */}
          <div className='mt8'>
            { !!toast && <Toast content={ toast } onDismiss={ handleToastDismiss } /> }

            <GeneralModal
              ref={ editPreferencesModalRef }
              title="Preferencias de entrega"
              primaryAction={{
                content: I18n.t("actions.save"),
                onAction: handleCustomerAddressUpdate,
                loading: loading,
              }}
              secondaryActions={[{ content: "Cancelar", onAction: handleClosePreferencesModal }]}
              onClose={ handleClosePreferencesModal }
            >
              <TextField
                label={ I18n.t("activerecord.attributes.customer_address.delivery_instructions") }
                multiline={3}
                value={ editableDeliveryInstructions }
                onChange={ setEditableDeliveryInstructions }
              />
            </GeneralModal>
          </div>
        </Page>
      </Frame>
    </AppProvider>
  )
}

OrderTimelineContainer.propTypes = propTypes;
OrderTimelineContainer.defaultProps = defaultProps;

export default OrderTimelineContainer;