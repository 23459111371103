import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TruckIcon from '@components/../../assets/images/icons/customer_tickets/truck.svg';
import PackageIcon from '@components/../../assets/images/icons/customer_tickets/package.svg';

import { useFormikContext } from 'formik';
import FormikInput from '@components/shared/ui/FormikInput';
import SquareBoxRadioButtons from './SquareBoxRadioButtons';
import AppDatePicker from '@components/shared/AppDatePicker';

import { Select, Checkbox, Banner } from '@shopify/polaris';

const propTypes = {

}

const defaultProps = {

}

const AddressDataStep = (props) => {
  const formik = useFormikContext();

  const handleRetrievalMethodChange = (value) => {
    formik.setFieldValue("retrievalMethod", value);
  }

  const handleRetrievalDateChange = (dates) => {
    formik.setFieldValue("retrievalDate", dates.start.toISOString());
  }

  if(formik.values.customerTicket){ // Means finished and loading next step
    return null;
  }

  const addressOptions = [
    { label: "Direcciones", value: "" },
    ...formik.values.formAddresses.map((address, index) => ({ label: address, value: String(index) }))
  ]

  // Useless Select component (on current version) doesn't work without value prop (selection doesn't change)
  // The workaround is to save on address the textual value and the index on formAddressIndex (to keep the Select component working)
  const handleAddressChange = (optionIndex) => {
    const selectedAddress = formik.values.formAddresses[optionIndex];
    formik.setValues({
      ...formik.values,
      address: selectedAddress,
      formAddressIndex: optionIndex,
      useCustomAddress: false, // Hide custom address input if selecting from list
    })
  };

  const handleCustomAddressChange = (checked) => {
    formik.setValues({
      ...formik.values,
      useCustomAddress: checked,
      formAddressIndex: 0,
      address: "",
    })
  }

  const retrievalMethodValue = formik.values.retrievalMethod;

  return (
    <div>
      <div className='flex alignItemsCenter mb12'>
        <hr className="flex1" />
        <p className='mh20 uppercase text-neutral-gray-200 fs12'>¿Como quisieras regresar el producto?</p>
        <hr className="flex1" />
      </div>

      <SquareBoxRadioButtons
        className="mb20"
        value={retrievalMethodValue}
        onClick={handleRetrievalMethodChange}
        options={[
          { description: I18n.t("app.customer_tickets.form.retrieval_method_selection.unimart"), icon: TruckIcon, className: "gradient-casino", value: "UNIMART" },
          { description: I18n.t("app.customer_tickets.form.retrieval_method_selection.mailing_cr"), icon: PackageIcon, className: "gradient-profile", value: "MAILING_CR" }
        ]}
        error={!!formik.touched.retrievalMethod && formik.errors.retrievalMethod}
      />

      { !!retrievalMethodValue &&
        <div className="mb20">
          <Banner title="Importante" status='warning'>
            { retrievalMethodValue == "UNIMART" &&
              <p> Solo disponible dentro de GAM</p>
            }
            { retrievalMethodValue == "MAILING_CR" &&
              <ul className='p0'>
                <li>Asegurate de incluir el número de orden en el paquete antes de entregarlo a Correos de CR para que podamos identificarlo.</li>
                <li>NO debes entregar el paquete hasta que aprobemos la solicitud.</li>
                <li>El costo de envío lo cubrimos nosotros, solo debés mencionar en Correos de CR que el paquete se envía a Unimart Alfa S.A.</li>
              </ul>
            }
          </Banner>
        </div>
      }

      { retrievalMethodValue === "UNIMART" &&
        <>
          <p className="heading-4 mb20">
            ¿Adonde lo retiramos?
          </p>

          { addressOptions.length > 1 &&
            <>
              <div className="mb20">
                <Select
                  label='Lista de direcciones'
                  options={addressOptions}
                  onChange={handleAddressChange}
                  value={formik.values.formAddressIndex}
                />
              </div>

              <div className="mb20">
                <Checkbox
                  label="Otra dirección"
                  checked={formik.values.useCustomAddress}
                  onChange={ handleCustomAddressChange }
                />
              </div>
            </>
          }
          
          {/* Use custom address when selecting checkbox or when there aren't any preloaded options */}
          { (formik.values.useCustomAddress || addressOptions.length == 1) && 
            <FormikInput 
              name="address"
              label={ I18n.t("activerecord.attributes.customer_ticket.address") }
              placeholder="..."
              className="mt8 mb20"
            />
          }

          <div className="mb20">
            <label className="mb4 fs12 block">
              { I18n.t("activerecord.attributes.customer_ticket.retrieval_date") }
            </label>
            
            {/* Weekends are disabled by css */}
            <div className='polaris-date-picker-disabled-weekends'>
              <AppDatePicker
                onChange={handleRetrievalDateChange}
                disableDatesBefore={new Date}
              />
            </div>
          </div>

          <FormikInput 
            name="retrievalNotes"
            label={ I18n.t("activerecord.attributes.customer_ticket.retrieval_notes") }
            placeholder="..."
            className='polaris-input-multiline-container mb20'
            multiline
          />
        </>
      }

      <Checkbox 
        label="Entiendo una solicitud puede ser aprobada o rechazada y la solicitud no es una garantía de reintegro o cambio."
        checked={formik.values.terms}
        onChange={ (value) => formik.setFieldValue("terms", value) }
        error={!!formik.touched.terms && formik.errors.terms}
      />
    </div>
  )
};

AddressDataStep.propTypes = propTypes;
AddressDataStep.defaultProps = defaultProps;

export default AddressDataStep;