import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AppProvider, Frame, TopBar, Button, Navigation, Badge } from '@shopify/polaris';
import axiosRails from "utils/axios-rails";
const propTypes = {
  currentLocation: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  links: PropTypes.array,
  logoURL: PropTypes.string.isRequired,
  userMenu: PropTypes.array,
}

const defaultProps = {
  userMenu: []
}

const AppTopBar = ({ user, ...props }) => {
  // console.log("h",props)
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [navigationSidebar, setNavigationSidebar] = useState(false);
  const linksLength= props.links.length

  // TODO optimize this double map into 1
  const secondaryMenu = props.links.map((link, index)=> {
    return <a className="menu-link" key={index} href={link.url} {...link.data} >
      {link.name}
      { link.count> 0 &&
      <Badge size="small" status="attention">{link.count} </Badge>
      }
    </a>

  })

  const mobileNavigationLinks = [
    { url: "/", label: "Dashboard" },
    ...props.links.map(( link, index ) => (
      { url: link.url, label: link.name }
    ))
  ]


  const theme = {
    colors: {
      topBar: {
        background: '#000',
      },
    },
    logo: {
      width: 228,
      topBarSource:
        props.logoURL,
      url: '/',
      accessibilityLabel: 'Unimart Dispatches',
    },
  };

  const handleNavigationToggle = (a) => {
    setNavigationSidebar(!navigationSidebar)
  }
  const logOut=()=>{
    axiosRails.delete("/users/sign_out").then(res=>{
      console.log(res.data)
      location.reload();
    })
  }
  const userMenuMarkup = (
    <TopBar.UserMenu
      name={ `${user.first_name} ${user.last_name || ""}` }
      actions={[ 
        {
          items:props.userMenu
        },
        {
          items: [{content:<a href="/users/sign_out" data-method="delete">Cerrar Sesión </a>}]
        },
      ]}
      initials={ `U` }
      open={isUserMenuOpen}
      onToggle={ () => setIsUserMenuOpen(!isUserMenuOpen) }
    />
  );

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      onNavigationToggle={handleNavigationToggle}
      secondaryMenu={secondaryMenu}
    />
  );

  const mobileNavigation = (
    <Navigation location={ props.currentLocation }>
      <Navigation.Section
        items={mobileNavigationLinks}
      />
    </Navigation>
  )

  return (
    <AppProvider
      theme={theme}
    >
      <div id="HeaderFrameContainer">
        <Frame
          topBar={topBarMarkup}
          navigation={ mobileNavigation }
          showMobileNavigation={ navigationSidebar }
          onNavigationDismiss={ handleNavigationToggle }
        >
  
        </Frame>
      </div>
    </AppProvider>
  )
};

AppTopBar.propTypes = propTypes;
AppTopBar.defaultProps = defaultProps;

export default AppTopBar;