import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, Stack, DisplayText, List, Tooltip, Icon } from '@shopify/polaris';
import { QuestionMarkMinor } from '@shopify/polaris-icons';

const propTypes = {
  sampleData: PropTypes.shape({
    sample_1: PropTypes.shape({
      name: PropTypes.string,
      average: PropTypes.number,
      count: PropTypes.number,
      wday: PropTypes.string.isRequired,
    }),

    sample_2: PropTypes.shape({
      name: PropTypes.string,
      average: PropTypes.number,
      count: PropTypes.number,
      wday: PropTypes.string.isRequired,
    }),
  }),
}

const defaultProps = {

}

const DeliveryDetailAverageSamples = (props) => {
  const { sample_1, sample_2 } = props.sampleData;

  return (
    <Card title="Promedio de entrega de ordenes" sectioned>

      <Stack distribution="fill">
        <div>
          
          <p className='flex mb12'>
            <strong>Sample 1</strong>
            <Tooltip content={
              <List>
              <List.Item>{ sample_1.wday }</List.Item>
              <List.Item>Muestra: { sample_1.count }</List.Item>
              <List.Item>Fully allocated</List.Item>
              <List.Item>Lunes</List.Item>
              <List.Item>2 a 3 días hábiles</List.Item>
              <List.Item>Paid</List.Item>
            </List>
            } active={false}>
              <Icon source={QuestionMarkMinor} />
            </Tooltip>
          </p>

          <DisplayText size="medium">
            { sample_1.average || 0.0 }
          </DisplayText>
        </div>

        <div>
          
          <p className='flex mb12'>
            <strong>Sample 2</strong>
            <Tooltip content={
              <List>
                <List.Item>{ sample_2.wday }</List.Item>
                <List.Item>Muestra: { sample_2.count }</List.Item>
                <List.Item>No stock allocated or partially allocated</List.Item>
                <List.Item>Lunes</List.Item>
                <List.Item>3 a 5 días hábiles</List.Item>
                <List.Item>Paid</List.Item>
              </List>
            } active={false}>
              <Icon source={QuestionMarkMinor} />
            </Tooltip>
          </p>


          <DisplayText size="medium">
            { sample_2.average || 0 }
          </DisplayText>
        </div>
      </Stack>
    </Card>
  )
};

DeliveryDetailAverageSamples.propTypes = propTypes;
DeliveryDetailAverageSamples.defaultProps = defaultProps;

export default DeliveryDetailAverageSamples;