import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import withPagination from '@shared/withFilteredPagination';
import { dashboardTeamsColumns, dashboardTaskColumns } from '@utils/columns';
import FilterTable from "@shared/FilterTable";
import { Card, Layout, DataTable, Button, TextStyle, Select, Popover, ActionList, DatePicker, Tabs } from '@shopify/polaris';
import BarChart from 'components/shared/AppBarChart';
import { calculateDays } from "utils";
import DashboardETA from 'components/views/home/DashboardETA'
import { selectOptionsProp } from 'utils/shapes';

const propTypes = {
  teamStats: PropTypes.array.isRequired,
  generalStatsGraph: PropTypes.shape({
    generalStats: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          value: PropTypes.number.isRequired,
        }).isRequired,
      ),
      total_delivered: PropTypes.number.isRequired,
    }).isRequired,
    graph: PropTypes.array.isRequired,
  }).isRequired,

  initialDateFilter: PropTypes.shape({
    range: PropTypes.string.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
  }),

  availableDateFilters: selectOptionsProp.isRequired,
  etaDashboard: PropTypes.shape({ // If not present, ETA Dashboard is not shown
    customerTagsId: PropTypes.array,
    teamListId: PropTypes.array,
    etaFilterURL: PropTypes.string,
    bpURLFetchCatalogs: PropTypes.string,
  })
}

const DashboardIndex = (props) => {
  const [active, setActive] = useState(false)
  const toggleActive = useCallback(() => setActive((active) => !active), []);
  // Fixed date filters!
  const handleDateFilter = useCallback((filterRange) => {
    props.handleFilterChange(filterRange, 'range'); // Send range to filter
    setActive(false);
  }, [])


  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => setSelected(selectedTabIndex), [],
  );

  const buildTabs = () => {
    let tabs = [
      {
        id: 'dashboard',
        content: 'Dashboard',
        accessibilityLabel: 'Dashboard',
        panelID: 'dashboard',
      }
    ];

    if (!!props.etaDashboard) {
      tabs = [
        ...tabs,
        {
          id: 'estimados_de_entregas',
          content: 'Estimados de Entregas',
          panelID: 'estimados_de_entregas',
        }
      ]
    }

    return tabs;
  };

  const tabs = buildTabs();
  //////////////-----Date Time Picker--/////////////////////////
  const dateTime = new Date()
  const beginDate = dateTime.setHours(0, 0, 0, 0)
  const [{ month, year }, setDate] = useState({ month: dateTime.getUTCMonth(), year: dateTime.getUTCFullYear() });

  const [selectedDates, setSelectedDates] = useState({
    start: new Date(beginDate),
    end: new Date(beginDate),
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const updateDatesPicker = (values) => {
    console.log("Pum");
    setSelectedDates(values);
    props.setFilterState({
      range: "CUSTOM_RANGE",
      start: values.start,
      end: values.end,
    });
  }

  const dateBase64Params = btoa(JSON.stringify({
    range: props.filterState.range,
    start: props.filterState.start,
    end: props.filterState.end,
  }));

  // Fixed date ranges. CUSTOM_RANGE is handled on calendar
  const dateActionListItems = props.availableDateFilters.map(({ label, value }) => ({
    content: label,
    onAction: () => handleDateFilter(value)
  }));

  const generalStatRows = props.generalStatsGraph.generalStats.data.map((rowData) => [
    rowData.title,
    rowData.value,
  ]);

  return (
    <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
      <div>
        {/* Main Dashboard - Delivery */}
        {selected == 0 &&
          <FilterTable
            pageProps={{
              title: "Dashboard",
            }}
            columnsInfo={dashboardTeamsColumns}
            resourceTableClassName={"resource-table-dashboard-teams"}
            resourceTableProps={{
              urlParams: `?date_filter=${dateBase64Params}`,
            }}
            hideCardSection
            optionFilterDate={(props) =>
              <div id={"filter-date"}>
                <Popover
                  active={active}
                  activator={(
                    <Button onClick={toggleActive} disclosure>
                      {props.filterState.range != "CUSTOM_RANGE" ?
                        I18n.t(`date.date_filter.${props.filterState.range}`)
                        : calculateDays(props.filterState.start, props.filterState.end)
                      }
                    </Button>
                  )}
                  onClose={toggleActive}
                  fluidContent={true}>
                  <Popover.Section>
                    <ActionList
                      items={dateActionListItems}
                    />
                  </Popover.Section>
                  <Popover.Section>
                    <DatePicker
                      month={month}
                      year={year}
                      onChange={updateDatesPicker}
                      onMonthChange={handleMonthChange}
                      selected={selectedDates}
                      allowRange={true}
                    />
                  </Popover.Section>
                </Popover>
              </div>
            }
            dashboardTaskColumns={dashboardTaskColumns}
            {...props}
            headerComponent={(renderProps) => (
              <Card>
                <Layout>
                  <Layout.Section oneHalf >
                    <div className="polaris-no-border-card-sections polaris-stats-tables-container">
                      <Card.Section title="Resumen">
                        <DataTable
                          columnContentTypes={[]}
                          headings={[]} 
                          rows={generalStatRows}
                        />
                      </Card.Section>
                    </div>
                  </Layout.Section>
                  <Layout.Section oneHalf>
                    <Card.Section title={I18n.t("dashboard.delivered")} >
                      <div className="mt8">
                        <div className="flex flexColumn alignItemsEnd">
                          {/* <div className="mb4">
                          <TextStyle variation="subdued">
                            {I18n.t(`date.filter_date.${props.filterState.date}`)}
                          </TextStyle>
                        </div> */}
                          <TextStyle variation="subdued">
                            {` ${props.generalStatsGraph.generalStats.total_delivered} Ordenes`}
                          </TextStyle>
                        </div>
                        <BarChart data={props.generalStatsGraph.graph} />
                      </div>
                    </Card.Section>
                  </Layout.Section>
                </Layout>
              </Card>
            )}
          />
        }

        {/* ETA Dashboard */}
        {/* TODO all etaDashboard fields should be sent at once */}
        {selected == 1 && !!props.etaDashboard &&
          <DashboardETA
            availableDateFilters={props.availableDateFilters}
            {...props.etaDashboard}
          />
        }
      </div>

    </Tabs>
  )
}

DashboardIndex.propTypes = propTypes

export default withPagination(DashboardIndex, {
  mapPropsToInitialFilters: (props) => ({
    range: props.initialDateFilter.range,
    start: props.initialDateFilter.start && new Date(props.initialDateFilter.start),
    end: props.initialDateFilter.end && new Date(props.initialDateFilter.end),
  }),
  mapPropsToInitialRecords: (props) => props.teamStats,
  mapPropsToInitialGeneralStatsGraph: (props) => props.generalStatsGraph,
  mapPropsToPagy: (props) => "",
  paramsName: "dashboard_filters",
  filterURL: "/filter_dashboard",
  taskTableData: (props) => props.taskTableData,
});



