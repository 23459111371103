import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Select, Banner, List } from '@shopify/polaris';

const propTypes = {

}

const defaultProps = {

}

const TeamRequirementsBanner = (props) => {
  return (
    <Banner
      title="Para poder crear un Team se requiere uno o más usuarios que cumplan las siguientes condiciones:"
      action={{ content: 'Crear usuario', url: "/admin/users/new" }}
      status="warning">
    <List>
      <List.Item>
        No debe tener rol de admin.
      </List.Item>
      <List.Item>
        No debe ser mensajero.
      </List.Item>
      <List.Item>
        No debe tener otro Team asignado.
      </List.Item>
    </List>
    </Banner>
  )
};

TeamRequirementsBanner.propTypes = propTypes;
TeamRequirementsBanner.defaultProps = defaultProps;

export default TeamRequirementsBanner;