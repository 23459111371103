import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  errors: PropTypes.array,
}

const InputErrors = (props) => (
  <div>
    { props.errors.map(( e,i ) => 
      <span key={i}>{e}</span>
    )}
  </div>
)

InputErrors.propTypes = propTypes;

export default InputErrors;
