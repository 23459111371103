import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Card, DescriptionList, Badge, Link } from '@shopify/polaris';
import { polarisBuildListItems } from 'utils/index';

import snakeCase from 'lodash/snakeCase';

import { valueToBadgeProgress, valueToBadgeStatus } from 'utils/columns';

const propTypes = {
  customerTicket: PropTypes.object.isRequired,
}

const defaultProps = {

}

// relatedDeliveryRecord & Task
const CustomerDataSection = (props) => {
  const { customerTicket } = props;

  const buildRelatedRecordItems = () => {
    if(!customerTicket.relatedDeliveryRecord){
      return [];
    }
    else if(customerTicket.relatedDeliveryRecord.__typename == 'Order'){
      return polarisBuildListItems([
        { name: "relatedDeliveryRecord", title: "Ref", accessor: (relatedDeliveryRecord) => 
          <Link url={relatedDeliveryRecord.adminUrl} external>
            { relatedDeliveryRecord.orderNumber }
          </Link>
        },
        { name: "relatedDeliveryRecord", title: I18n.t("activerecord.models.courier.one"), accessor: (relatedDeliveryRecord) => relatedDeliveryRecord.courier?.name },
        { name: "relatedDeliveryRecord", title: I18n.t("activerecord.models.team.one"), accessor: (relatedDeliveryRecord) => relatedDeliveryRecord.courier?.team?.name },
      ], customerTicket);
    }
    else if(customerTicket.relatedDeliveryRecord.__typename == 'DispatchOrder'){
      return polarisBuildListItems([
        { name: "relatedDeliveryRecord", title: I18n.t("activerecord.models.dispatch_control.one"), accessor: (relatedDeliveryRecord) => relatedDeliveryRecord.dispatchControl.name },
        { name: "relatedDeliveryRecord", title: "#", accessor: (relatedDeliveryRecord) => relatedDeliveryRecord.orderNumber },
        { name: "relatedDeliveryRecord", title: I18n.t("activerecord.models.team.one"), accessor: (relatedDeliveryRecord) => relatedDeliveryRecord.dispatchControl.team.name },
      ], customerTicket);    
    }
    else{
      throw("Unrecognized related record type")
    }
  }

  const relatedRecordItems = buildRelatedRecordItems();

  const taskDataItems = !customerTicket.task ? [] : polarisBuildListItems([
    { name: "task", title: I18n.t("activerecord.attributes.task.name"), accessor: (task) => 
      <Link url={ task?.adminUrl } external>
        { task?.name }
      </Link>
    },
    { name: "task", title: I18n.t("activerecord.attributes.task.status"), accessor: (task) => {
      const snakeStatus = snakeCase(task.status);
      return(
        <Badge progress={valueToBadgeProgress[snakeStatus]} status={valueToBadgeStatus[snakeStatus]} >
          {I18n.t(`enum.status.${snakeStatus}`)}
        </Badge>
      )
    }},
    { name: "task", title: I18n.t("activerecord.models.courier.one"), accessor: (task) => task?.courier?.name },
    { name: "task", title: I18n.t("activerecord.models.team.one"), accessor: (task) => task?.courier?.team?.name },
    { name: "task", title: I18n.t("activerecord.attributes.task.created_at"), accessor: (task) => I18n.l("time.formats.app_datetime", task.createdAt) },
  ], customerTicket);


  return (
    <>
      { !!customerTicket.relatedDeliveryRecord &&
        <Card.Section title="Registro Asociado">
          <DescriptionList
            items={relatedRecordItems}
          />
        </Card.Section>
      }

      { !!customerTicket.task &&
        <Card.Section title="Tarea">
          <DescriptionList
            items={taskDataItems}
          />
        </Card.Section>
      }
    </>
  )
};

CustomerDataSection.propTypes = propTypes;
CustomerDataSection.defaultProps = defaultProps;

export default CustomerDataSection;