import React, { useState, useEffect, useCallback } from 'react';

/**
 * NOTE: Requires google maps places lib to be loaded on context
 * Load google maps SearchBox component and return SearchBox OBJECT. Also set event listener for places_changed
 * @param {HTMLElement} input - Input to load places.SearchBox on
 */
export default function useGoogleMapsSearchBox(input, { onPlacesChanged }) {
  const [searchBox, setSearchBox] = useState();

  useEffect(() => {
    if(!input || !window.google){
      return;
    }

    const googleMapsSearchBox = new window.google.maps.places.SearchBox(input, {
      componentRestrictions: {country: "cr"}
    });
    const placesChangedListener = googleMapsSearchBox.addListener("places_changed", () => {
      onPlacesChanged(googleMapsSearchBox); // callback with the searchBox object
    });
    setSearchBox(googleMapsSearchBox);

    return () => {
      placesChangedListener.remove();
    }
  }, [input]);

  return searchBox;
};
