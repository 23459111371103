import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { useFormikContext } from 'formik';
import { gql, useQuery, useMutation } from '@apollo/client';

import { Button, ButtonGroup, Form, Checkbox, TextStyle, Select } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';

import { useFormik, FormikProvider } from 'formik';
import { graphqlEnumToOptions, userErrorsToFormik } from 'api/index';

import GeneralModal from 'components/shared/GeneralModal';
import FormikInput from 'components/shared/ui/FormikInput';

import pick from 'lodash/pick';

const propTypes = {
  customerTicket: PropTypes.object.isRequired,
  onToast: PropTypes.func.isRequired,
  onRefetch: PropTypes.func.isRequired,
}

const defaultProps = {

}

const TICKET_MODAL_FORMS = {
  APPROVE: {
    key: "APPROVE",
    title: "Aprobar",
    cta: "Aprobar",
  },
  REJECT: {
    key: "REJECT",
    title: "Rechazar",
    cta: "Rechazar",
    destructive: true,
  },
  RESOLUTION: {
    key: "RESOLUTION",
    title: "Elegir reintegro",
    cta: "Guardar"
  }
}

const CustomerTicketActions = (props) => {
  const customerTicket = props.customerTicket;
  const modalRef = useRef(null);

  const { data: enumData } = useQuery(ENUM_RESOLUTION_ACTIONS);

  const [approveOrReject, { loading: loadingApproveOrReject }] = useMutation(APPROVE_OR_REJECT, {
    onCompleted: (dataApproveOrReject) => {
      const customerTicketUpdated = dataApproveOrReject.customerTicketApproveOrReject.customerTicket;
      if(customerTicketUpdated?.id){
        props.onToast(I18n.t("messages.update_successful"));
        modalRef.current?.close(); // Close modal
        props.onRefetch(); // Reload data
      }
      else{
        console.log("Errors", dataApproveOrReject)
        props.onToast(I18n.t("messages.one_error"));
        formik.setErrors(userErrorsToFormik(dataApproveOrReject.customerTicketApproveOrReject.errors));
      }
      formik.setSubmitting(false);
    },
    onError: (error) => {
      console.log("Error", error);
      props.onToast(I18n.t("messages.request_error"));
      formik.setSubmitting(false);
    }
  });

  const [resolveTicket, { loading: loadingResolveTicket }] = useMutation(RESOLVE_TICKET, {
    onCompleted: (dataResolveTicket) => {
      const customerTicketUpdated = dataResolveTicket.customerTicketResolve.customerTicket;
      if(customerTicketUpdated?.id){
        props.onToast(I18n.t("messages.update_successful"));
        modalRef.current?.close(); // Close modal
        props.onRefetch(); // Reload data
      }
      else{
        console.log("Errors", dataResolveTicket)
        props.onToast(I18n.t("messages.one_error"));
        formik.setErrors(userErrorsToFormik(dataResolveTicket.customerTicketResolve.errors));
      }
      formik.setSubmitting(false);
    },
    onError: (error) => {
      console.log("Error", error);
      props.onToast(I18n.t("messages.request_error"));
      formik.setSubmitting(false);
    }
  });

  const [recordUpdate, { loading: loadingUpdate }] = useMutation(RECORD_UPDATE, {
    onCompleted: (dataUpdate) => {
      const customerTicketUpdated = dataUpdate.customerTicketUpdate.customerTicket;
      if(customerTicketUpdated?.id){
        props.onToast(I18n.t("messages.update_successful"));
        props.onRefetch(); // Reload data
      }
      else{
        props.onToast("An error ocurred updating");
      }
    }
  });

  const handleEscalate = () => {
    recordUpdate({
      variables: {
        id: customerTicket.id,
        input: { status: "ESCALATED" }
      }
    })  
  }

    // ============== Formik ================
  // For approve/reject
  const formik = useFormik({
    initialValues: {
      modalKey: null,
      // Approve/Reject form
      approved: false,
      requireItems: false,
      rejectReason: "",
      // Resolution action form
      resolutionAction: "",
    },
    enableReinitialize: true,
    onSubmit: (values, formikBag) => {
      if(values.modalKey == TICKET_MODAL_FORMS.RESOLUTION.key){
        resolveTicket({
          variables: {
            id: customerTicket.id,
            input: pick(values, ["resolutionAction"])
          }
        }); // resolveTicket
      }
      else{
        approveOrReject({
          variables: {
            id: customerTicket.id,
            input: pick(values, ["approved", "requireItems", "rejectReason"])
          }
        }); // approveOrReject
      }
    },
  });
  // =====================================

  const handleApprovedAction = () => {
    formik.setValues({
      modalKey: TICKET_MODAL_FORMS.APPROVE.key,
      approved: true,
      requireItems: true,
      rejectReason: "",
    })
    modalRef.current?.open();
  }

  const handleRejectedAction = () => {
    formik.setValues({
      modalKey: TICKET_MODAL_FORMS.REJECT.key,
      approved: false,
      requireItems: false,
      rejectReason: "",
    })
    modalRef.current?.open();
  };

  const handleResolutionAction = () => {
    formik.setValues({
      modalKey: TICKET_MODAL_FORMS.RESOLUTION.key,
      resolutionAction: "",
    })
    modalRef.current?.open();
  }

  // Modal Contents
  const approveForm = (
    <>
      <Checkbox 
        label="Producto requerido"
        checked={formik.values.requireItems}
        onChange={ (value) => formik.setFieldValue("requireItems", value) }
      />
    </>
  );

  const rejectForm = (
    <>
      <FormikInput 
        name="rejectReason"
        label="Razón de rechazo"
        placeholder="..."
        className='polaris-input-multiline-container mb8'
        multiline
      />

      <TextStyle variation='strong'>
        <span className='fs12'>Este comentario es visible para el cliente!</span>
      </TextStyle>
    </>
  );

  const resolutionActionForm = (
    <>
      <Select
        name="resolutionAction"
        value={formik.values.resolutionAction}
        options={graphqlEnumToOptions({ 
          enumValues: enumData?.resolutionActionEnum?.enumValues || [],
          enumName: "resolution_action",
          prependPlaceholder: true,
          blacklistValues: ["PENDING"]
        })}
        onChange={ (value) => formik.setFieldValue("resolutionAction", value) }
        disabled={ formik.isSubmitting }
      />
    </>
  )
  // End Modal Contents

  const currentModal = TICKET_MODAL_FORMS[formik.values.modalKey]; // Might be null

  return (
    <>
      <FormikProvider value={formik}>
        {/* Approve/Reject */}
        <GeneralModal
          ref={ modalRef }
          title={ currentModal?.title }
          primaryAction={{
            content: currentModal?.cta || "MISSING CTA",
            onAction: formik.handleSubmit,
            destructive: currentModal?.destructive,
            loading: formik.isSubmitting,
          }}
          secondaryActions={[
            { content: "Cancelar", onAction: () => modalRef.current?.close() },
          ]}
        >
          <Form>
            {/* General error display */}
            { formik.errors.base &&
              <div className='flex'>
                <div className='mr4'>
                  <AlertMinor color="#bf0711" fill="#bf0711" height={20} width={20} />
                </div>
                <p>
                  <TextStyle variation='negative'>
                    {formik.errors.base || "Dev message"}
                  </TextStyle>
                </p>
              </div>
            }
            { formik.values.modalKey == TICKET_MODAL_FORMS.APPROVE.key && approveForm }
            { formik.values.modalKey == TICKET_MODAL_FORMS.REJECT.key && rejectForm }
            { formik.values.modalKey == TICKET_MODAL_FORMS.RESOLUTION.key && resolutionActionForm }
          </Form>
        </GeneralModal>
        <div>
          <ButtonGroup>
            {/* Rejectable */}
            { customerTicket.rejectable && 
              <Button 
                destructive 
                onClick={handleRejectedAction}
                disabled={loadingApproveOrReject}
              >
                Rechazar
              </Button>
            }

            {/* Escalable */}
            { customerTicket.escalable && 
              <Button 
                onClick={handleEscalate}
                loading={loadingUpdate}
                disabled={loadingUpdate}
              >
                Escalar
              </Button>
            }

            {/* Approvable */}
            { customerTicket.approvable && 
              <Button primary onClick={handleApprovedAction} disabled={loadingApproveOrReject}>Aprobar</Button> 
            }

            {/* Resolution is always visible (unless readOnly) but only enabled when ticket can be actioned */}
            { !customerTicket.readOnly && 
              <Button 
                primary 
                disabled={!customerTicket.resolutionActionable}
                onClick={handleResolutionAction}
              >
                Elegir reintegro
              </Button> 
            }
          </ButtonGroup>
        </div>
      </FormikProvider>
    </>
  )
};

const APPROVE_OR_REJECT = gql`
  mutation($id: ID!, $input: CustomerTicketApproveOrRejectInput!){
    customerTicketApproveOrReject(id: $id, input: $input) {
      customerTicket {
        id
        status
      }
      errors {
        key
        message
      }
    }
  }
`;

const ENUM_RESOLUTION_ACTIONS = gql`
query{
  resolutionActionEnum: __type(name: "CustomerTicketResolutionActionEnum") {
    enumValues {
      name
    }
  }
}
`;

const RESOLVE_TICKET = gql`
mutation($id: ID!, $input: CustomerTicketResolveInput!){
  customerTicketResolve(id: $id, input: $input) {
    customerTicket {
      id
    }
    errors {
      key
      message
    }
  }
}
`;

const RECORD_UPDATE = gql`
mutation($id: ID!, $input: CustomerTicketUpdateInput!){
  customerTicketUpdate(id: $id, input: $input) {
    customerTicket {
      id
      status
    }
    errors {
      key
      message
    }
  }
}
`;

CustomerTicketActions.propTypes = propTypes;
CustomerTicketActions.defaultProps = defaultProps;

export default CustomerTicketActions;