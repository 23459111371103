import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { AppProvider, Card, Button, TextStyle, Toast, Frame, Link } from '@shopify/polaris';
import { AlertMinor } from '@shopify/polaris-icons';
import polarisFilterEs from '@utils/polarisFiltersEs';

import { useFormik, FormikProvider } from 'formik';
import * as yup from 'yup';

import CustomerIdentificationStep from './components/CustomerIdentificationStep';
import ItemIdentificationStep from './components/ItemIdentificationStep';
import TicketDataStep from './components/TicketDataStep';
import AddressDataStep from './components/AddressDataStep';
import TicketSuccessSummary from './components/TicketSuccessSummary';

import UnimartLogo from '@components/../../assets/images/icons/logo.svg';
// import FormBackground from '@components/../../assets/images/icons/background@2x';

import { ApolloProvider, gql, useLazyQuery, useMutation } from '@apollo/client';
import { publicClient, userErrorsToFormik } from 'api/index';
import pick from 'lodash/pick';

const propTypes = {

}

const defaultProps = {

}

// As apollo must be at the top level, we need to wrap the component.
const CustomerTicketPublicFormWithApollo = (props) => {
  return (
    <ApolloProvider client={publicClient}>
      <CustomerTicketPublicForm {...props} />
    </ApolloProvider>
  )
}

// Params for creation
const whitelistParams = [
  // STEP 1
  'customerKind', 'customerEmail', 'customerName', 'providerCustomerName', 'providerCustomerId',  
  'providerName', 'providerOrderId', 
  // STEP 2
  'providerLineItemId', 'providerProductId', 'productTitle', 'productSku', 'productImageUrl', 'ticketKind',
  'productOriginalTotal', 'currencyCode',
  // STEP 3
  'reason', 'description', 'media', 'resolutionPreference', 
  // STEP 4
  'retrievalMethod', 'retrievalDate', 'retrievalNotes', 'address', 'relatedDeliveryRecordId', 'relatedDeliveryRecordType'
];

const CustomerTicketPublicForm = (props) => {
  const [toast, setToast] = useState(null);
  const [createCustomerTicket] = useMutation(TICKET_CREATE);
  const [queryCustomerIdentification] = useLazyQuery(CUSTOMER_IDENTIFICATION_QUERY);
  const [queryItemIdentification] = useLazyQuery(ITEM_IDENTIFICATION_QUERY);

  const [stepKey, setStepKey] = useState(FORM_STEPS.CUSTOMER_IDENTIFICATION.value);
  // const [stepKey, setStepKey] = useState(FORM_STEPS.ADDRESS_DATA.value);

  const currentStep = FORM_STEPS[stepKey];
  
  const handleStepSubmit = async (values, formikBag) => {
    try {
      const nextStepKey = currentStep.nextStep;

      switch(stepKey){
        // =============== STEP 1 ===============
        case FORM_STEPS.CUSTOMER_IDENTIFICATION.value:
          const step1Result = await queryCustomerIdentification({
            variables: {
              input: {
                email: values.customerEmail,
                phone: values.customerEmail,
                orderNumber: values.formOrderNumber,
                customerKind: values.customerKind,
              }
            }
          })
          const indentificationData = step1Result.data.rmaCustomerIdentification
          console.log("Query result", indentificationData);
          if(!indentificationData.error){
            const customerData = indentificationData.payload.customer;
            console.log("CDATA", customerData);
            formikBag.setValues({
              ...values,
              // ==== Data for creation input ====
              // Customer info
              customerName: customerData.name,
              providerName: customerData.providerName,
              providerCustomerId: customerData.providerId,
              // Order info
              providerOrderId: indentificationData.payload.order.providerId,

              // === Form information ===
              formOrder: indentificationData.payload.order, // Includes lineItems
              formAddresses: customerData.addresses,
              useCustomAddress: customerData.addresses.length === 0,
            })
            setStepKey(nextStepKey);
          }
          else{
            formikBag.setErrors({ base: indentificationData.error });
          }
          break;
        // =============== STEP 2 ===============
        case FORM_STEPS.ITEM_IDENTIFICATION.value:
          const step2Result = await queryItemIdentification({
            variables: {
              input: {
                ticketKind: values.ticketKind,
                customerId: values.providerCustomerId,
                customerKind: values.customerKind,
                customerEmail: values.customerEmail,
                orderId: values.formOrder.providerId,
                lineItemId: values.providerLineItemId,
              }
            },
            fetchPolicy: 'network-only'
          })

          console.log("query", step2Result);
          const itemData = step2Result.data.rmaItemIdentification; // { valid: Boolean, , error: String, relatedDeliveryRecord: { id: String, type: String } }
          console.log("Query result", itemData);
          if(itemData.valid){
            formikBag.setValues({ // Add relatedDeliveryRecord information IF any
              ...values,
              relatedDeliveryRecordId: itemData.relatedDeliveryRecord?.id,
              relatedDeliveryRecordType: itemData.relatedDeliveryRecord?.type,
            })
            setStepKey(nextStepKey);
          }
          else{ // ItemIdentification error is more sofisticated (hardly!) because there is a certain error that requires special html, actually the other errors should follow this structure to be more flexible
            const userError = itemData.error;
            formikBag.setErrors({ [userError.key]: userError.message });
          }
          break;
        // =============== STEP 3 ===============
        // Only fetch more information (validated by schema)
        case FORM_STEPS.TICKET_DATA.value:
          setStepKey(nextStepKey);
          break;
        // =============== STEP 4 ===============
        case FORM_STEPS.ADDRESS_DATA.value:
          const createInput = pick(values, whitelistParams);
          console.log("The values", createInput);
          const createResult = await createCustomerTicket({
            variables: {
              input: createInput,
            },
            fetchPolicy: 'network-only'
          })

          console.log("Create result", createResult)

          const customerTicket = createResult.data.rmaCustomerTicketCreate.customerTicket;
          if(!!customerTicket){ // Sucesss
            formikBag.setValues({
              customerTicket: customerTicket
            });
            setStepKey(nextStepKey);
          }
          else{
            const formErrors = userErrorsToFormik(createResult.data.rmaCustomerTicketCreate.errors);
            formikBag.setErrors(formErrors);
          }
          break;
        default: // Dev only. Summary shouldn't have a next button
          setStepKey(FORM_STEPS.CUSTOMER_IDENTIFICATION.value);
      }
  
      // Reset scroll
      window.scrollTo(0, 0);
    } catch (error) {
      console.error("Catch error", error)
      setToast("Ocurrio un error de conexión.")
    }
  }

  // form_xxx fields are used only to query on API and are not stored on final save
  const formik = useFormik({
    initialValues: {
      // =========== Step 1 ===========
      customerEmail: '', // testpayments@unimart.com
      formOrderNumber: '', // #399418
      customerKind: '',
      // Calculated after successful step1
      customerName: '',
      providerName: '',
      providerOrderId: '',
      providerCustomerId: '',

      formOrder: {},
      formAddresses: [],
      useCustomAddress: false,

      // =========== Step 2 ===========
      ticketKind: '',
      providerLineItemId: '',
      providerProductId: '',
      productTitle: '',
      productSku: '',
      productImageUrl: '',
      productOriginalTotal: '',
      currencyCode: '',

      // Calculated after successful step2
      relatedDeliveryRecordType:'',
      relatedDeliveryRecordId:'',

      // =========== Step 3 ===========
      description: '',
      reason: '',
      resolutionPreference: '',
      media: [],
      // =========== Step 4 ===========
      retrievalMethod: '',
      retrievalDate: undefined,
      retrievalNotes: '', // optional
      address: '',
      terms: false,
    },
    validationSchema: currentStep.schema || undefined,
    onSubmit: handleStepSubmit
  });

  useEffect(() => {
    // Reset touched after each step change (submit mark all as touched on each "NEXT")
    formik.setTouched({});
  }, [stepKey])
  
  console.log("Values", formik.values);
  console.log("Errors", formik.errors);
  
  return (
    <AppProvider i18n={polarisFilterEs}>
      <Frame>
        <div id="customer-ticket-form-container" className='flex flexColumn justifyCenter alignItemsCenter pv24 ph16' style={{ minHeight: 846 }}>

          <img src={UnimartLogo} alt="Unimart Logo" className="mb40" />

          { !!toast && <Toast content={toast} onDismiss={() => setToast(null)} /> }

          <div className="form-wrapper">
            <FormikProvider value={formik}>
              <Card sectioned>

                <h3 className="text-center heading-3 mb8">
                  {currentStep.title}
                </h3>

                <p className="text-neutral-gray-200 text-center step-description mb20 lg:mb40">
                  {currentStep.description}
                </p>

                {/* Step 1 */}
                { stepKey === FORM_STEPS.CUSTOMER_IDENTIFICATION.value && (
                  <CustomerIdentificationStep 
                    customerKinds={CUSTOMER_KINDS} 
                  />
                )}
    
                {/* Step 2 */}
                { stepKey === FORM_STEPS.ITEM_IDENTIFICATION.value && (
                  <ItemIdentificationStep />
                )}

                {/* Step 3 */}
                { stepKey === FORM_STEPS.TICKET_DATA.value && (
                  <TicketDataStep />
                )}

                {/* Step 4 */}
                { stepKey === FORM_STEPS.ADDRESS_DATA.value && (
                  <AddressDataStep />
                )}

                {/* Summary */}
                { stepKey === FORM_STEPS.SUCCESS_SUMMARY.value && (
                  <TicketSuccessSummary />
                )}

                { (formik.errors.base || formik.errors.warranty_excluded_brand) &&
                  <div className='text-center mt20 flex justifyCenter'>
                    <div className='mr4'>
                      <AlertMinor color="#bf0711" fill="#bf0711" height={20} width={20} />
                    </div>
                    <p>
                      <TextStyle variation='negative'>
                        { formik.errors.base || formik.errors.warranty_excluded_brand }
                      </TextStyle>

                      {/* Extra bit for the excluded brand error */}
                      { !!formik.errors.warranty_excluded_brand &&
                        <TextStyle variation='negative'>
                          {". "}Podés encontrar los datos de contacto <a target='_blank' href="https://www.unimart.com/pages/centro-de-ayuda?a=Como-funcionan-las-garantias---id--shUwTHVpR8GubmXToTWMxw">aquí</a>
                        </TextStyle>
                      }
                    </p>
                  </div>
                }

                { (!!currentStep.prevStep || !!currentStep.nextStep) &&
                  <div className="mt20 flex flexColumn lg:flexRow">
                    { !!currentStep.nextStep &&
                      <div className='lg:order-2 flex1'>
                        <Button
                          primary fullWidth
                          onClick={formik.handleSubmit}
                          disabled={ formik.isSubmitting || !formik.isValid }
                          loading={ formik.isSubmitting }
                        >
                          { formik.isSubmitting ? "" : (currentStep.cta || "Siguiente") }
                        </Button>
                      </div>
                    }

                    { !!currentStep.prevStep &&
                      <div className="mt12 lg:mt0 lg:mr12 lg:order-1 flex1">
                        <Button
                          outline fullWidth
                          onClick={ () => setStepKey(currentStep.prevStep) }
                          disabled={ formik.isSubmitting }
                          loading={ formik.isSubmitting }
                        >
                          { formik.isSubmitting ? "" : "Regresar" }
                        </Button>
                      </div>
                    }
                  </div>
                }
              </Card>
            </FormikProvider>
          </div>
        </div>
      </Frame>
    </AppProvider>
  )
};

const { string } = yup;

// https://github.com/jquense/yup/blob/master/src/locale.ts
yup.setLocale({
  // use constant translation keys for messages without values
  mixed: {
    default: 'field_invalid',
    required: "es requerido",
  },
});

const CUSTOMER_IDENTIFICATION_SCHEMA = yup.object({
  customerKind: string().required(),
  customerEmail: string().required(),
  formOrderNumber: string().required(),
});

const ITEM_IDENTIFICATION_SCHEMA = yup.object({
  ticketKind: string().required(),
  providerLineItemId: string().required(),
});

const TICKET_DATA_SCHEMA = yup.object({
  description: string().required(),
  reason: string().required(),
  resolutionPreference: string().required(),
  media: yup.array().min(1, "Debes subir al menos una imagen o video"),
});

const ADDRESS_DATA_SCHEMA = yup.object({
  retrievalMethod: string().required(),
  terms: yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
});

const FORM_STEPS = {
  CUSTOMER_IDENTIFICATION: {
    title: "Devoluciones & Garantías",
    description: "¿Querés devolver un producto o hacer un reclamo de garantía? ¡Es fácil! El primer paso es crear una solicitud aquí",
    value: "CUSTOMER_IDENTIFICATION",
    schema: CUSTOMER_IDENTIFICATION_SCHEMA,
    nextStep: "ITEM_IDENTIFICATION",
  },
  ITEM_IDENTIFICATION: {
    title: "Tipo de solicitud",
    description: "¿Como desea procesar la solicitud?",
    value: "ITEM_IDENTIFICATION",
    schema: ITEM_IDENTIFICATION_SCHEMA,
    nextStep: "TICKET_DATA",
    prevStep: "CUSTOMER_IDENTIFICATION",
  },
  TICKET_DATA: {
    title: "Datos para solicitud",
    description: "Completar estos datos con la mayor cantidad de detalle es importante para que tu solicitud sea aprobada",
    value: "TICKET_DATA",
    schema: TICKET_DATA_SCHEMA,
    nextStep: "ADDRESS_DATA",
    prevStep: "ITEM_IDENTIFICATION",
  },
  ADDRESS_DATA: {
    title: "Información para regresar producto",
    value: "ADDRESS_DATA",
    cta: "Crear Solicitud",
    schema: ADDRESS_DATA_SCHEMA,
    nextStep: "SUCCESS_SUMMARY",
  },
  SUCCESS_SUMMARY: {
    title: "Solicitud recibida, ¡Gracias!",
    value: "SUCCESS_SUMMARY",
  },
};

const CUSTOMER_KINDS = {
  CUSTOMER: "B2C",
  BUSINESS: "B2B",
};

const CUSTOMER_IDENTIFICATION_QUERY = gql`
  query($input: TicketCustomerIdentificationInput!) {
    rmaCustomerIdentification(input: $input) {
      payload {
        customer {
          name
          email
          providerId
          providerName
          addresses
        }
        order {
          orderNumber
          providerId
          lineItems {
            providerId
            productProviderId
            productType
            productOriginalTotal
            currencyCode
            title
            sku
            imageUrl
          }
        }
      }
      error
    }
  }
`;

const ITEM_IDENTIFICATION_QUERY = gql`
  query($input: TicketItemIdentificationInput!) {
    rmaItemIdentification(input: $input) {
      valid
      relatedDeliveryRecord {
        id
        type
      }
      error{
        key
        message
      }
    }
  }
`;

const TICKET_CREATE = gql`
  mutation($input: CustomerTicketInput!) {
    rmaCustomerTicketCreate(input: $input) {
      customerTicket{
        name
        productTitle
        ticketKind
      }
      errors{
        key
        message
      }
    }
  }
`;

CustomerTicketPublicForm.propTypes = propTypes;
CustomerTicketPublicForm.defaultProps = defaultProps;

export default CustomerTicketPublicFormWithApollo;