import React from 'react';
import PropTypes from 'prop-types';
import { dispatchControlShape } from 'utils/shapes';
import { FormLayout, Button } from '@shopify/polaris';
import DispatchOrders from './DispatchOrders';

const propTypes = {
  teamOptions: PropTypes.array,
  values: dispatchControlShape.isRequired,
  errors: PropTypes.array, // Assuming errors is an array
  loading: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  handleOrderTrackingChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const defaultProps = {};

const DispatchControlSharedUI = ({ values, errors, ...props }) => {

  return (
    <div className="mt16">
      <FormLayout>
        <FormLayout.Group>
          <DispatchOrders 
            orders={values.orders} 
            editTracking={values.editTracking}  
            handleOrderChange={props.handleOrderTrackingChange}
            uber={props.uber}
          />
        </FormLayout.Group>
        {errors && errors.length > 0 && (
          <FormLayout.Group>
            <div style={{ color: 'red' }}>
              <ul>
                {errors.map((error, index) => (
                  <li key={index}>{error}</li>
                ))}
              </ul>
            </div>
          </FormLayout.Group>
)}
        <FormLayout.Group>
          <Button
            loading={props.loading}
            onClick={props.handleSubmit}
            primary
          >
            {I18n.t("actions.create")}
          </Button>
        </FormLayout.Group>
      </FormLayout>
    </div>
  );
};

DispatchControlSharedUI.propTypes = propTypes;
DispatchControlSharedUI.defaultProps = defaultProps;

export default DispatchControlSharedUI;
