import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { FormLayout, Select, Form, Icon, Card, OptionList, Checkbox, TextStyle } from '@shopify/polaris';

import BasicTextField from '@shared/BasicTextField';
import Autocomplete from '@shared/Autocomplete';
import { ta } from 'utils';
import axios from 'utils/axios-rails';

import { taskCatalogs } from 'utils/shapes';
import { SearchMajorMonotone } from '@shopify/polaris-icons';

import TaskDeliveryDetails from './TaskDeliveryDetails';
import debounce from 'lodash/debounce';

const propTypes = {
  renderProps: PropTypes.object.isRequired,
  
  queryBrightPearlURL: PropTypes.string.isRequired,
  queryCouriersURL: PropTypes.string.isRequired,
  // onSelect: PropTypes.func.isRequired,

  catalogs: taskCatalogs.isRequired,
  initialCouriers: PropTypes.array.isRequired,

  contactSearch: PropTypes.shape({
    // options: PropTypes.array.isRequired,
    // onSelect: PropTypes.func.isRequired,
    // onTextChange: PropTypes.func,
    // textValue: PropTypes.func,
  }),
}

const defaultProps = {

}

const TaskForm = ({ renderProps, ...props }) => {

  const [loading, setLoading] = useState(false);
  const [addresses, setAddresses] = useState([]);
  const [customerOrderItems, setCustomerOrderItems] = useState([]);
  
  const [allowCustomAddress, setAllowCustomAddress] = useState(false);

  const { values } = renderProps;
  const { kinds, teams } = props.catalogs;
  const contactSearch = props.contactSearch;
  console.log("Current values", values)

  const isCustomerTask = values.kind != "supplier_withdrawal";
  const requiresOrderData = ["warranty", "refund", "technical_service"].includes(values.kind);

  // Methods exposed from Autocomplete
  const autocompleteRef = useRef(null);
  const clearAutocompleteOptions = () => {
    autocompleteRef.current.clearOptions();
  }

  // =========== Shared between tasks =======================
  const checkCustomAddress = (newChecked) => {
    if(newChecked){ // clear input
      renderProps.handleChange("", "address") 
    }
    else{
      // Pick again the first value of addresses if possible, else initialize value again to ""
      const pickedValue = addresses.length > 0 ? addresses[0].value : "";
      renderProps.handleChange(pickedValue, "address") // clear input
    }
    setAllowCustomAddress(newChecked);
  }

  const onSelectContact = async (ids, item) => {
    setLoading(true);
    try {
      let response = await axios.post(props.queryBrightPearlURL, { query: { value: ids[0], kind: "address" } });
      const contact = response.data && response.data.contact
      if(contact){
        console.log("C", response.data);
        const addresses = response.data.addresses_list;
        setAddresses(addresses);
        
        renderProps.handleChange(contact.id, "contact_id");
        renderProps.handleChange(contact.name, "contact_name");
        renderProps.handleChange(contact.phone, "contact_phone");
        renderProps.handleChange(addresses[0] && addresses[0].value, "address");
      }
    } catch (error) {
      console.log("Meh", error)      
    }
    setLoading(false);
  }

  const resetContactValues = () => {
    renderProps.handleChange("", "contact_id");
    renderProps.handleChange("", "contact_name");
    renderProps.handleChange("", "contact_phone");
    renderProps.handleChange("", "address");

    // Customer extras
    renderProps.handleChange("", "virtual_customer_order_number");
    renderProps.handleChange("", "virtual_customer_order_status");
    setAddresses([]);
  }

  const onChangeKind = (val, name) => {
    clearAutocompleteOptions();
    resetContactValues();
    renderProps.handleChange(val, name);
  }

  //  ================== (Customer Task) - Order  =============
  const queryOrder = async (value, contact_id) => {
    setLoading(true);
    try {
      const response = await axios.post(props.queryBrightPearlURL, {
        query: { kind: "order", value: value, contact_id: contact_id }
      })
      const order = response.data.order
      if(order){
        renderProps.handleChange(order.reference, "virtual_customer_order_number");
        renderProps.handleChange(order.orderStatus.name, "virtual_customer_order_status");
      }
      else{
        renderProps.pushToast(response.data.message);
        renderProps.handleChange("", "virtual_customer_order_number");
        renderProps.handleChange("", "virtual_customer_order_status");
      }
      console.log("Query order", response.data)

      if(response.data.items){
        setCustomerOrderItems(response.data.items);
      }
    } catch (error) {
      renderProps.pushToast(I18n.t("messages.request_error"))
    }
    setLoading(false);
  }

  const deboundedQueryOrder = useCallback( debounce(queryOrder, 700),[]);

  const handleCustomerOrderIdChange = (val, name) => {
    renderProps.handleChange(val, name);
    deboundedQueryOrder(val, values.contact_id)
  }

  const pickOrderItem = (ids) => renderProps.handleChange(ids, "item_ids");
  // ================================================================

  return (
    <Card>
      <Card.Section title="Información de contacto">
        <FormLayout>
          <hr />
          <Select
            id="kind"
            label={ta("task.kind")}
            options={ kinds}
            value={values.kind}
            {...renderProps}
            onChange={ onChangeKind }
          />
    
          <Autocomplete
            ref={ autocompleteRef }
            textFieldProps={{ disabled: loading }}
            onSelect={ onSelectContact }
            selected={[]}
            queryURL={ props.queryBrightPearlURL }
            label={ isCustomerTask ? "Cliente" : "Supplier" }
            extraParams={{
              isCustomer: isCustomerTask,
              kind: "contact"
            }}
          />
          {/* Fields based on Contact selection */}
          <FormLayout.Group>
            <BasicTextField
              name="contact_name"
              readOnly
              label={ta("general.name")}
              {...renderProps}
            />
            
            <BasicTextField
              name="contact_phone"
              placeholder=" "
              readOnly
              label={ta("general.phone")}
              {...renderProps}
            />
          </FormLayout.Group>
          <Checkbox
            label="Otra dirección"
            disabled={ !values.contact_id }
            checked={ allowCustomAddress }
            onChange={ checkCustomAddress }
          />
          { allowCustomAddress ? (
            <BasicTextField
              name="address"
              label={ta("general.address")}
              {...renderProps}
            />
            ) : (
            <Select
              disabled={ !values.contact_id }
              id="address"
              label={ta("general.address")}
              options={ addresses }
              {...renderProps}
              value={ values.address }
              onChange={renderProps.handleChange}
            /> 
          )}
        </FormLayout>
      </Card.Section>

      { requiresOrderData ? (
        <Card.Section title="Información de la tarea">
          <FormLayout>
            <BasicTextField
              type="number"
              disabled={ !values.contact_id || loading }
              name="provider_order_id"
              placeholder=" "
              label={ta("task.provider_order_id")}
              {...renderProps}
              value={ values.provider_order_id }
              prefix={ <Icon source={SearchMajorMonotone} color="inkLighter" /> }
              onChange={ handleCustomerOrderIdChange }
            />
            <FormLayout.Group>
              <BasicTextField
                name="virtual_customer_order_number"
                placeholder=" "
                readOnly
                label={ "#" }
                {...renderProps}
              />
              
              <BasicTextField
                name="virtual_customer_order_status"
                placeholder=" "
                readOnly
                label={ta("general.status")}
                {...renderProps}
              />
            </FormLayout.Group>
            { customerOrderItems.length > 0 &&
              <OptionList
                title="Productos"
                allowMultiple
                onChange={ pickOrderItem }
                options={ customerOrderItems }
                selected={ values.item_ids }
              />
            }
          </FormLayout>
        </Card.Section>
        ) : ( null )
      }
          
      <Card.Section title="Información de envío">
        <TaskDeliveryDetails 
          initialCouriers={ props.initialCouriers }
          queryCouriersURL={ props.queryCouriersURL }
          teams={ teams }
          disabled={ props.readOnly }

          polarisFormProps={ renderProps }
        />
      </Card.Section>
    </Card>
  )
};

TaskForm.propTypes = propTypes;
TaskForm.defaultProps = defaultProps;

export default TaskForm;