import React, { Component } from 'react';
import PropTypes from 'prop-types';

import TagsInput from 'react-tagsinput';

const propTypes = {
  tags: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
}
const defaultProps = {
  placeholder: ""
}

const MultipleTagsInput = (props) => (
  <div>
    { props.label &&
      <label className='mb4 display-block'><strong>{ props.label }</strong></label>
    }
    <TagsInput
      value={ props.tags }
      onlyUnique
      addKeys={[188, 9, 13]}
      inputProps={{
        placeholder: props.placeholder
      }}
      addOnPaste
      pasteSplit={
        (data) => {
          return data.split(',').map(d => d.trim())
        }}
      onChange={ props.handleChange } />
  </div>
);

MultipleTagsInput.propTypes = propTypes;
MultipleTagsInput.defaultProps = defaultProps;

export default MultipleTagsInput;
