import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import debounce from 'lodash/debounce';
import omit from 'lodash/omit';

import { AppProvider, Modal, Frame, Toast } from '@shopify/polaris';

import axiosRails from '@utils/axios-rails';
import { pagyShape } from 'utils/shapes';

// Actually passed on config 
const propTypes = {
  filterByCreation: PropTypes.bool, // Filter by creation date

  filterURL: PropTypes.string.isRequired, // Endpoint to submit the filter params
  paramsName: PropTypes.string.isRequired, // params name to send all params withing. i.e. filterParams which will nest filterParams.user_id, filterParams.search_t, etc.

  initialFilters: PropTypes.shape({
    tabs: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })),
  }).isRequired, // Initial value for filters
  initialRecords: PropTypes.array.isRequired, // Initial records list.
  mapPropsToInitialGeneralStatsGraph: PropTypes.object,
  initialTotalPages: PropTypes.number.isRequired, // Initial total of pages
  pagy: pagyShape.isRequired,
  paginationClass: PropTypes.string
};

const defaultProps = {
  filterByCreation: false,
  initialFilters: {
    // user_id: 0,
    // status: "completed"
    // statusTabIndex: 0
    // startDate
    // endDate
  },
}

function withFilteredPagination(WrappedComponent, {
  // Config "props"
  mapPropsToInitialRecords,
  mapPropsToInitialFilters,
  mapPropsToInitialGeneralStatsGraph,
  mapPropsToPagy,
  paramsName,
  filterURL,
  paginationClass,
  // pagy, // using ruby pagy
}) {
  return class extends React.Component {
    constructor(props) {
      super(props);

      if(!mapPropsToInitialFilters || !mapPropsToPagy || !paramsName){ console.warn("withFilteredPagination missing required props"); }

      const initialFilters = mapPropsToInitialFilters(props);
      const initialRecords = initialRecords || mapPropsToInitialRecords(props);
      const pagy = mapPropsToPagy(props);
      const paginationFilter = paginationClass? paginationClass(props) :null;
      const generalStatsGraph = !!mapPropsToInitialGeneralStatsGraph ? mapPropsToInitialGeneralStatsGraph(props) : []
      this.state = {
        // waiting
        loading: false,
        // messaging
        toastActive: false,
        toastWithErrorClass: false,
        toastMessage: '',
        // for deletion part
        modalActive: false,
        deleteItem: null,
        deleteURL: null,

        filterState: initialFilters,
        records: initialRecords,
        generalStatsGraph: generalStatsGraph,
        filterURL,
        paramsName: paramsName,
        showDatepicker: false,
        pagy: pagy,

        filterByCreation: props.filterByCreation || false,
        tabs: (!!initialFilters && initialFilters.tabs) || undefined, // small hack
        paginationClass: paginationFilter,

        taskTableData: props.taskTableData,
      }

      this.delayedPaginate = debounce(() => this.paginate('filter'), 1000);
    }

    onToastStart = (message, isError = false) => {
      this.setState({
        toastActive: true,
        toastMessage: message,
        toastWithErrorClass: isError,
      });
    }

    onToastDismiss = () => {
      this.setState({
        toastActive: false,
        toastMessage: '',
        toastWithErrorClass: false,
      });
    }

    onDeletionDiscarded = () => {
      this.setState({
        modalActive: false,
        deleteItem: null,
        deleteURL: null,
      });
    }

    render() {
      // const props = this.props;
      const { filterState, records, loading, pagy } = this.state;

      const renderProps = {
        filterState,
        paginate: this.paginate,
        updateFilter: this.updateFilter,
        delayedPaginate: this.delayedPaginate,
        handleUserChange: this.handleUserChange,
        handleFilterChange: this.handleFilterChange,
        setFilterState: this.setFilterState,
        toggleDatepicker: this.toggleDatepicker,
        onStatusChange: this.onStatusChange,
        startLoading: this.startLoading,
        stopLoading: this.stopLoading,
        setRecords: this.setRecords,
        nextPage: this.nextPage,
        previousPage: this.previousPage,

        hasNext: !!pagy.next,
        hasPrevious: !!pagy.prev,
        deleteElement: this.deleteElement,

        ...this.state
      }
      
      console.log("withFilteredPagination renderProps", renderProps)
      console.log("withFilteredPagination state", this.state)

      // The WrappedComponent has <AppProvider> too. Can it become an issue?
      return (
        <AppProvider>
          { this.state.toastActive &&
            <Frame>
              <Toast content={this.state.toastMessage} error={this.state.toastWithErrorClass} onDismiss={this.onToastDismiss} />
            </Frame>
          }

          { this.state.modalActive &&
            <Modal
              small
              open={true} //this.state.modalActive
              onClose={this.onDeletionDiscarded}
              title={I18n.t('messages.irreversible_action')}
              primaryAction={{
                content: I18n.t('true'),
                onAction: this.onDeletionApproved,
              }}
              secondaryActions={[
                {
                  content: I18n.t('false'),
                  onAction: this.onDeletionDiscarded,
                },
              ]}
            >
            </Modal>
          }
        
          <WrappedComponent
            {...this.props}
            {...renderProps}
          />
        
        </AppProvider>
      )
    };

    startLoading = () => this.setState({ loading: true });
    stopLoading = () => this.setState({ loading: false });

    // Update pagy.page +-1 and callback paginate
    nextPage = () => this.setState( (state) => ({ pagy: { ...state.pagy, page: state.pagy.page + 1 }} ), this.paginate );
    previousPage = () => this.setState( (state) => ({ pagy: { ...state.pagy, page: state.pagy.page - 1 }} ), this.paginate );

    setRecords = (records) => this.setState({ records: records })

    handleUserChange = async (user_id) => {
      this.setState((state) => ({ filterState: { ...state.filterState, user_id }, }), this.paginate);
    }

    handleFilterChange = async(value, name) =>{
     this.setState((state)=>({filterState: { ...state.filterState, [name]: value }}), this.paginate )
    }

    // Receive multiple values to update at once as an object
    setFilterState = async(newValues) =>{
      this.setState((state)=>({filterState: { ...state.filterState, ...newValues }}), this.paginate )
     }
    
    onStatusChange = async (tabIndex) => {
      const tab = this.state.tabs[tabIndex];
      this.setState((state) => ({
        filterState: {
          ...state.filterState,
          status: tab.value, statusTabIndex: tabIndex
        },
        pagy:{
          ...state.pagy,
          page: 1
        }
      }), this.paginate)
    }

    updateFilter = (value, name, trigger = 'none') => {
      this.setState((state) => ({
        filterState: {
          ...state.filterState,
          [name]: value
        },
        pagy: {
          ...state.pagy,
          page:1
        }
      }),
      // Callback
      () => {
        switch (trigger) {
          case 'filter': this.paginate(); break;
          case 'delayed': this.delayedPaginate(); break;
          default: // nanimo
        }
      }
      );
    }

    toggleDatepicker = async (val) => {
      this.setState({ showDatepicker: val }, () => {
        if (!val) {
          this.paginate();
        }
      })
    }

    // Execute pagination with filterState and current pagy page
    paginate = async () => {
      const { paramsName, filterURL, pagy, paginationClass } = this.state;

      console.group("withFilteredPagination");

      await this.setState({ loading: true })

      try {
        let filterParams = this.state.filterState;
        if (!(this.state.filterByCreation && this.state.showDatepicker)) {
          // Exclude dates if not enabled
          filterParams = omit(filterParams, ['start_date', 'end_date'])
        }

        let response = await axiosRails.post(filterURL, {
          page: pagy.page,
          is_pagination: true,
          pagination_class:  paginationClass,
          [paramsName]: filterParams,
        });

        const { data } = response; // MUST return at least records and pagy object.

        if(!!data.error){
          this.onToastStart(data.error, true);
          
          this.setState({
            loading: false,
          })
        }
        else{
          this.setState({
            loading: false,
            records: data.records,
            pagy: data.pagy,
            generalStatsGraph: data.generalStatsGraph,
            paginationClass: data.paginationClass,
            taskTableData: data.taskTableData,
          });
        }
      } catch (error) {
        //alert(I18n.t('messages.one_error'));
        this.onToastStart(I18n.t('messages.one_error'), true);
        console.error("Pagination error", error);
        this.setState({ loading: false })
      }

      console.groupEnd("withFilteredPagination");
    }

    // Delete an Element from Resource List or Resource Item
    deleteElement = (item, deleteURL) => {
      this.setState({ modalActive: true, deleteItem: item, deleteURL: deleteURL });
    }
    
    onDeletionApproved = async () => {
      await this.setState({
        loading: true,
        modalActive: false,
      });

      const { pagy } = this.state;
      const response = await axiosRails.delete(this.state.deleteURL, {params: { page: pagy.page} });
      const { data } = response;

      if(data.success) {
        this.onToastStart(I18n.t('messages.delete_success'));
        //alert(I18n.t('messages.delete_success'));
        
        // this.setState({
        //   records: data.records,
        //   loading: false,
        //   pagy: data.pagy,
        // })

        // NEW: erase the record of this.state without loading the set of records again.
        let newRecords = this.state.records.filter((elem)=>{
          return elem.id != this.state.deleteItem.id;
        });

        // when pagy re-calculates the first 50 items of this page,
        // the first item of the next page is included in this current page.
        // We retrieve it manually.
        if(data.newRecord) {
          newRecords.push(data.newRecord);
        }
        
        this.setState({
          records: newRecords,
          loading: false,
          deleteItem: null,
          deleteURL: null,
        });

      } else {
        this.onToastStart(I18n.t('messages.one_error'), true);
        //alert(I18n.t('messages.one_error'));
        this.setState({
          loading: false,
          deleteItem: null,
          deleteURL: null,
        });
      }
    }
  }
}


withFilteredPagination.propTypes = propTypes;
withFilteredPagination.defaultProps = defaultProps;

export default withFilteredPagination;
