import React, { useCallback, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { resourceTransferColumns } from '@utils/columns';

import withPagination from '@shared/withFilteredPagination';
import FilterTable from '@shared/FilterTable';
import GeneralModal from 'components/shared/GeneralModal';
import MultipleTagsInput from '@shared/MultipleTagsInput';

import { AppProvider, Frame, TextField, Card, Button, List, TextStyle, Form } from '@shopify/polaris';

import { List as ImmutableList } from 'immutable'

import axiosRails from '@utils/axios-rails';


const propTypes = {
  records: PropTypes.array.isRequired,
  newUrl: PropTypes.string,
  reportURL: PropTypes.string,
  updateURL: PropTypes.string.isRequired,
  statusTabs: PropTypes.arrayOf(PropTypes.shape({ // Polaris tabs
    id: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
}

const defaultProps = {

}

const ResourceTransferIndex = (props) => {
  console.log("ResourceTransferIndex props", props);
  
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState({
    notes: "",
    focusedItem: null,
  });
  const rejectModalRef = useRef(null);
  const [gonIds, setGonIds] = useState([]); // To bulk accept
  const [bulkErrors, setBulkErrors] = useState([]); // To bulk accept

  const handleUpdateRecords = ({ updatedRecord, unassign = false }) => {
    const currentRecords = ImmutableList(props.records);
    let [recordIndex, recordValues] = currentRecords.findEntry((record) => record.id === updatedRecord.id);

    let newValues = {
      ...recordValues, 
      status: updatedRecord.status,
      notes: updatedRecord.notes,
      resolvable_by_user: false,
    };

    if(unassign){ // If unassign nullify team_name
      newValues = {
        ...newValues,
        resource: {
          ...recordValues.resource,
          team_name: null, // Unassigned!
        }
      }
    }
    const newRecords = currentRecords.set(recordIndex, newValues)
    props.setRecords(newRecords.toJS());
    alert(`Traslado resuelto`);
  }

  const handleAccept = async (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    
    if(confirm("Esta seguro de aceptar la traslado?")){
      const itemURL = props.updateURL.replace(":id", item.id);
      try {
        const response = await axiosRails.put(itemURL, { resource_transfer: { status: "accepted" } })
        console.log("response", response)
        if(response.data.success){
          handleUpdateRecords({ updatedRecord: response.data.record, unassign: true });
        }
        else{
          alert(`Error al actualizar el registro`);
          console.warn("Validation error", response.data.errors);
        }
      } catch (error) {
        console.error("Raised!", error);
        alert(I18n.t("messages.request_error"))
      }
    }
  };

  // TODO send current filter values
  const handleBulkAccept = async (event, item) => {
    if(confirm("Esta seguro de aceptar la traslado?")){
      setLoading(true);
      setBulkErrors([]);
      try {
        const response = await axiosRails.put(props.bulkAcceptURL, { gon_ids:  gonIds  })
        console.log("response", response)
        if(response.data.success){
          props.setRecords(response.data.records); // Set records from backend with default filters
          props.updateFilter("pending", "status"); // Reset Tab filter
        }
        else{
          alert(`Error al actualizar el registros`);
          console.warn("Validation error", response.data.errors);
          setBulkErrors(response.data.errors);
          // TODO display errors somewhere
        }
      } catch (error) {
        console.error("Raised!", error);
        alert(I18n.t("messages.request_error"))
      }
      finally{
        setLoading(false);
      }
    }
  };

  const handleRejectIntent = (event, item) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData({ focusedItem: item, notes: "", });
    rejectModalRef.current.open();
  };

  const submitReject = async () => {
    const itemURL = props.updateURL.replace(":id", modalData.focusedItem.id);
    try{
      const response = await axiosRails.put(itemURL, { resource_transfer: { status: "rejected", notes: modalData.notes } })
      if(response.data.success){
        handleUpdateRecords({ updatedRecord: response.data.record });
        handleCloseModal();
      }
      else{
        alert(`Error al actualizar el registro`);
        console.warn("Validation error", response.data.errors);
      }
    }
    catch(error){
      console.error("Raised!", error);
      alert(I18n.t("messages.request_error"))
    }
  };

  const handleCloseModal = () => {
    rejectModalRef.current.close();
    setModalData({ focusedItem: null, notes: "", });
  }

  const updateNotes = (value) => {
    setModalData({ ...modalData, notes: value });
  };

  // I think process button should go below input as it can grow up and take more than one line
  const headerComponent = () => (
    <Card sectioned>
      <label><strong>Ingresar IDs</strong></label>
      <Form onSubmit={ handleBulkAccept }>
        <div className='flex mt4'>
          <div className='mr8' style={{ minWidth: 400 }}>
            <MultipleTagsInput
              tags={ gonIds }
              handleChange={ setGonIds }
              placeholder='Escanear IDs para aceptar traslados de forma masiva'
            />
          </div>
          <Button
            primary
            loading={ loading }
            onClick={ handleBulkAccept }
            disabled={ gonIds.length === 0 }
          >
            { I18n.t("actions.process") }
          </Button>
        </div>
      </Form>

      { bulkErrors.length > 0 &&
        <div className="mt8">
          <List>
            { bulkErrors.map((error, index) => (
              <TextStyle key={ index } variation='negative'>
                <List.Item>
                  { error }
                </List.Item>
              </TextStyle>
            )) }
          </List>
        </div>
      }
    </Card>
  );

  return(
    <AppProvider>
      <Frame>
        <FilterTable
          pageProps={{
            title: I18n.t("activerecord.models.resource_transfer", { count: 2 }),
          }}
          resourceTableProps={{
            accessorUtils: {
              handleAccept,
              handleReject: handleRejectIntent,
            }
          }}
          columnsInfo={ resourceTransferColumns }
          
          headerComponent={ headerComponent }
          tabs={ props.tabs }
          {...props}
        />
        <GeneralModal
          ref={ rejectModalRef }
          primaryAction={{
            content: "Rechazar",
            destructive: true,
            onAction: submitReject,
            disabled: !modalData.notes,
          }}
          title="Rechazar traslado"
          secondaryActions={[{ content: "Cancelar", onAction: handleCloseModal }]}
        >
          <p>Notas</p>
          <TextField
            multiline={2}
            placeholder='No se recibio paquete, falta información, etc.'
            value={ modalData.notes }
            onChange={ updateNotes }
          />
        </GeneralModal>
      </Frame>
    </AppProvider>
  )
}


ResourceTransferIndex.propTypes = propTypes;
ResourceTransferIndex.defaultProps = defaultProps;

export default withPagination(ResourceTransferIndex, {
  mapPropsToInitialFilters: (props) => ({
    tabs: props.statusTabs,
    statusTabIndex: 0,
    status: "pending",
  }),
  mapPropsToInitialRecords: (props) => props.records,
  mapPropsToPagy: (props) => props.pagy,
  paramsName: "filters",
  filterURL: "/resource_transfers/filter",
});