import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import esTranslations from '@shopify/polaris/locales/es.json';
import {AppProvider, Form, Card, Stack, Frame, Button, Icon, TextField, Toast, Select, Layout, Checkbox } from '@shopify/polaris';
import { LocationMajorMonotone } from '@shopify/polaris-icons';
import axios from 'utils/axios-rails';
import GoogleMapOneMarker from 'components/shared/GoogleMapOneMarker';

import useGoogleMapsSearchBox from 'components/hooks/useGoogleMapsSearchBox';
import { selectOptionsProp } from 'utils/shapes';

import { useFormik } from 'formik';

import UnimartLogo from "@components/../../assets/images/site-logo.png";
import LocationIcon from '@components/../../assets/images/icons/location.svg';

const propTypes = {
  customerAddress: PropTypes.shape({
    id: PropTypes.number,
    address1: PropTypes.string.isRequired,
    province: PropTypes.string.isRequired,
    canton: PropTypes.string.isRequired,
    district: PropTypes.string.isRequired,

    lat: PropTypes.string,
    lon: PropTypes.string,
  }),

  initialLatitude: PropTypes.string,
  initialLongitude: PropTypes.string,

  availabilityOptions: selectOptionsProp.isRequired,
  recipientSubjectOptions: selectOptionsProp.isRequired,
  source: PropTypes.string.isRequired, // Used only on first update of address


  submitURL: PropTypes.string.isRequired,
}

const defaultProps = {}

const CustomerAddressMapForm = (props) => {
  const { customerAddress, initialLatitude, initialLongitude, submitURL, source } = props;

  const searchBoxInputRef = useRef(null); // Using google maps search box dropdown
  const [searchBoxText, setSearchBoxText] = useState(""); // Controlled state for searchbox input so that ref gets updated and useGoogleMapsSearchBox fires up setup
  const [ loading, setLoading ] = useState(false);
  const [ toast, setToast ] = useState(null);

  const [ moveMapCenter, setMoveMapCenter ] = useState(false);

  // Map marker location coordinates and center
  const [markerLoc, setMarkerLoc] = useState({
    lat: parseFloat(props.initialLatitude),
    lng: parseFloat(props.initialLongitude),
  });

  const formik = useFormik({
    initialValues: {
      ...props.customerAddress,
      form_referral: source,
    },
    onSubmit: async (values, fbag) => {
      setLoading(true);
      if (!values.lat || !values.lon) {
        setToast(I18n.t("errors.not_empty_marker"));
      } 
      else {
        try{
          const response = await axios.put(props.submitURL, {
            customer_address: values
          });
          setToast(response.data.message);
          if(response.data.errors){
            fbag.setErrors(response.data.errors);
          }
        }
        catch(error){
          setToast("Ocurrió un error con el servidor, por favor intente nuevamente en unos segundos.");
        }
      }
      setLoading(false);
    },
  });

  // SearchBox google maps setup
  const handleSearchBoxPlacesChanged = (gmapSearchBox) => {
    // console.log(gmapSearchBox);
    const place = gmapSearchBox.getPlaces()[0]; // only one item in the array (the selected one)
    const placeLocation = !!place.geometry && place.geometry.location;
    // console.log("Pl", placeLocation);
    if(!!placeLocation){
      setMoveMapCenter(true);
      setMarkerLoc( {lat: placeLocation.lat(), lng: placeLocation.lng()} );
    }
  };
  const searchBoxObject = useGoogleMapsSearchBox(searchBoxInputRef.current, { onPlacesChanged: handleSearchBoxPlacesChanged });
  const handleSearchBoxText = (event) => setSearchBoxText(event.target.value);
  // End SearchBox google maps setup

  const onMarkerSelection = (lat, lng) => {
    setMoveMapCenter(false); // must be first to disallow changing a possible movement of the map by the user or else it would reset to the stored map center.
    setMarkerLoc({lat: lat, lng: lng});
  }

  // Get the current position of the user or display an error if not possible.. navigator comes from the Browser API
  const handleGetCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition( // getCurrentPosition receives success and error callback
      (position) => { 
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;

        setMoveMapCenter(true); // must be first to allow coded movement of the map

        setMarkerLoc({lat: lat, lng: lng});
      }, 
      (error) => {
        switch(error.code) {
          case error.PERMISSION_DENIED:
            setToast("No has compartido tu ubicación");
            break;
          case error.POSITION_UNAVAILABLE:
            setToast("No pudimos obtener tu ubicación");
            break;
          case error.TIMEOUT:
            setToast("La petición tomó demasiado tiempo y falló");
            break;
          case error.UNKNOWN_ERROR:
            setToast("Un error que nos es desconocido ocurrió y no te logramos ubicar");
            break;
        }
      }
    );  
  }

  const onToastDismiss = () => setToast(null);

  // When lat/long changes on Map, update those fields on formik! Skip if they are the same as the props initial coordinates
  useEffect(() => {
    if((markerLoc.lat != Number(props.initialLatitude) && markerLoc.lng != Number(props.initialLongitude)) && (customerAddress.lat != markerLoc.lat || customerAddress.lon != markerLoc.lng) ){
      formik.setValues({...formik.values, lat: markerLoc.lat, lon: markerLoc.lng});
    }
  }, [markerLoc]);

  const handleCheckboxChange = (field, value, checked) => {
    checked ? formik.setFieldValue(field, value) : formik.setFieldValue(field, null);
  }

  return(
    <AppProvider i18n={esTranslations}>
      <div id="customer-address-map">
        <Card>
          <Card.Section><img src={ UnimartLogo } className="unimart-logo" /></Card.Section>
          <Layout>
            <Layout.Section oneHalf>
              <Card.Section>
                <h1 className='mb16'>Compartinos la ubicación de esta dirección:</h1>
                <h2 className='mb12 weight-normal mb48'>
                  { customerAddress.address1 }
                  <br/>
                  { `${customerAddress.province}, ${customerAddress.canton}, ${customerAddress.district}` }
                </h2>

                { navigator.geolocation &&
                  <div className="button-black-container">
                    <Button onClick={handleGetCurrentPosition} fullWidth>
                      <div className="flex">
                        <img className='mr8' src={ LocationIcon } />
                        <strong className='fs16'>Usar mi ubicación actual</strong>
                      </div>
                    </Button>
                  </div>
                }

                <div className='or-divider flex justifyBetween alignItemsCenter mv8'>
                  <div className='grey-line'></div>
                  <span className='fs18 mh12'>O</span>
                  <div className='grey-line'></div>
                </div>
          
                {/* Polaris doesn't support refs and the easiest way to use stupid google maps autocomplete input with JS SDK is "initializing" an input */}
                <div className="Polaris-TextField mt12">
                  <input
                    id="maps-autocomplete"
                    className='Polaris-TextField__Input'
                    type="text" ref={ searchBoxInputRef }
                    placeholder="Buscar un lugar"
                    value={ searchBoxText }
                    onChange={ handleSearchBoxText }
                  />
                  <div className="Polaris-TextField__Backdrop"></div>
                </div>
                <div className='mt24' style={{ height: '312px' }}>
                  <GoogleMapOneMarker
                    markerLoc={markerLoc}
                    mapCenterLoc={ markerLoc }
                    moveMapCenter={moveMapCenter}
                    onClick={onMarkerSelection}
                  />
                </div>
              </Card.Section>
            </Layout.Section>

            <Layout.Section oneHalf>
              <Card.Section>
                <div id="personal-preferences">
                  <h1 className='mb16'>Preferencias de entrega</h1>
                  
                  <CAFormControl
                    label={I18n.t("activerecord.attributes.customer_address.availability")}
                    containerClass="mb48"
                  >
                    { props.availabilityOptions.map((item) => (
                      <Checkbox
                        key={ item.value }
                        label={ item.label }
                        checked={ item.value == formik.values.availability }
                        onChange={ (checked) => handleCheckboxChange("availability", item.value, checked) }
                      />
                    ))}
                  </CAFormControl>

                  <CAFormControl
                    label={I18n.t("activerecord.attributes.customer_address.recipient_subject")}
                    containerClass="mb48"
                  >
                    { props.recipientSubjectOptions.map((item) => (
                        <div key={ item.value }>
                          <Checkbox
                            label={ item.label }
                            checked={ item.value == formik.values.recipient_subject }
                            onChange={ (checked) => handleCheckboxChange("recipient_subject", item.value, checked) }
                          />
                          { formik.values.recipient_subject == "customer_relative" && item.value == "customer_relative" &&
                            <div className='recipient-name-container'>
                              <TextField
                                placeholder='Personas autorizadas'
                                onChange={ (value) => formik.setFieldValue("recipient_name", value) }
                                value={ formik.values.recipient_name }
                                error={ formik.errors.recipient_name }
                              />
                            </div>
                          }
                        </div>
                      ))}
                  </CAFormControl>

                  <CAFormControl
                    label={I18n.t("activerecord.attributes.customer_address.delivery_instructions")}
                  >
                    <TextField
                      placeholder="Tus instrucciones nos ayudan a entregar a esta dirección, sin embargo, no siempre podemos seguir todas las instrucciones"
                      multiline={ 3 }
                      onChange={ (value) => formik.setFieldValue("delivery_instructions", value) }
                      value={ formik.values.delivery_instructions }
                      error={ formik.errors.delivery_instructions }
                    />
                  </CAFormControl>
                </div>
              </Card.Section>
            </Layout.Section>
          </Layout>

          <Card.Section>
            <div className='mt12 mobile-mt0 flex justifyCenter'>
              <div className='polaris-button-accent' style={{ width: "320px" }}>
                <Button
                  fullWidth
                  size="large"
                  loading={ loading }
                  disabled={ (!formik.values.lat || !formik.values.lon) }
                  onClick={ formik.handleSubmit }
                >
                  Guardar
                </Button>
              </div>
            </div>
          </Card.Section>
        </Card>
        
        <div style={{position: 'absolute'}}>
          { !!toast &&
            <Frame>
              <Toast
                content={ toast }
                onDismiss={ onToastDismiss } />
            </Frame>
          }
        </div>

      </div>
    </AppProvider>
  );
};

const CAFormControl = ({ label, containerClass, ...otherProps }) => (
  <div className={ containerClass }>
    <h2 className='mb8'>{ label }</h2>
    <div className='flex flexColumn'>
      { otherProps.children }
    </div>
  </div>
);

CustomerAddressMapForm.propTypes = propTypes;
CustomerAddressMapForm.defaultProps = defaultProps;

export default CustomerAddressMapForm;