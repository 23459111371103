import React, { useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AppProvider, Page, Card, Layout, FormLayout,
  Spinner, Button,
  Select, Popover, ActionList, DatePicker
} from '@shopify/polaris';
import { l } from 'i18n-js';
import esTranslations from '@shopify/polaris/locales/es.json';
import { PolarGrid, Tooltip } from 'recharts';
import { calculateDays } from "utils";
import { useFormik } from 'formik';
import axios from 'utils/axios-rails';
import IndexResourceList from '@shared/IndexResourceList';

const propTypes = {
  dateFilters: PropTypes.shape({
    start: PropTypes.date,
    end: PropTypes.date,
  }),
  
  customerTagsId: PropTypes.array.isRequired,
  shipmentTypeOptions: PropTypes.array.isRequired,
  teamListId: PropTypes.array.isRequired,

  etaFilterURL: PropTypes.string.isRequired, // URL to fetch data
  bpURLFetchCatalogs: PropTypes.string.isRequired, // URL to fetch catalogs (once)
}
const dateTime = new Date()
const beginDate = dateTime.setHours(0, 0, 0, 0)
const beginMonth = new Date(dateTime.getFullYear(), dateTime.getMonth(), 1);

const initialDeliveryDayStats = {
  avg_delivery_time: '0',
  avg_steps_delivery_time: [
    { confirmed: 0 },
    { packing: 0 },
    { packed: 0 },
    { assigning_route: 0 },
    { assigned_to_messenger: 0 },
    { delivered: 0 }
  ],
  infoTableETA: []
}

const dashboardETAColumns = [
  { size: 4, header: "Días para Entrega", accessor: "days" },
  { size: 4, header: "Ordenes Entregadas", accessor: "orders_delivered" },
  { size: 4, header: "%", accessor: "percentage_orders" },
]

const defaultProps = {
  dateFilters: { start: new Date(beginMonth), end: new Date(beginDate) },
}
const DashboardETA = (props) => {

  const formik = useFormik({
    initialValues: {
      dateFilters: props.dateFilters,
      shipping_method_id: '',
      sales_channel_id: '',
      customer_tag_ids: '',
      shipment_type_id: '',
      team_id: '',
      range: 'CUSTOM_RANGE'
    },
  });
  const [deliveryDayStats, setDeliveryDayStats] = useState(initialDeliveryDayStats)
  const [loading, setLoading] = useState(false)
  const [popoverActive, setPopoverActive] = useState(false);
  const [catalogs, setCatalogs] = useState({
    shippingMethods: [],
    channels: []
  }); // shippingMethods and channels are remotely fetched
  const [catalogsLoaded, setCatalogsLoaded] = useState(false)

  // DATE FILTER
  const [{ month, year }, setDate] = useState({ month: dateTime.getUTCMonth(), year: dateTime.getUTCFullYear() });
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const updateValuesFormik = (id, v) => {
    formik.setFieldValue(id, v)
  }

  const updateDatesPicker = (dateValues) => {
    updateValuesFormik("dateFilters", dateValues)
    updateValuesFormik("range", "CUSTOM_RANGE")
  }

  const updateRangePicker = (value) => {
    updateValuesFormik('range', value)
    togglePopoverActive()
  }

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleSelectChange = (value, id) => {
    updateValuesFormik(id, value)
  }

  const dateActionListItems = props.availableDateFilters.map(({ label, value }) => ({
    content: label,
    onAction: () => updateRangePicker(value)
  }));

  const onSubmitFilter = async () => {
    const input = {
      dashboard_eta: {
        ...formik.values,
        start: formik.values.dateFilters.start,
        end: formik.values.dateFilters.end,
      }
    }
    try {
      const { data } = await axios.post(props.etaFilterURL, input)
      {
        if (data.success) {
          setDeliveryDayStats(data.records)
        } else {
          console.log("Data Errors conecction")
        }
      }
    } catch (errors) {
      console.log("server error")
    }
    setLoading(false)
  }

  const fetchBrightpearlCatalogs = async () => {
    try {
      const { data } = await axios.get(props.bpURLFetchCatalogs)
      {
        if (data.success) {
          setCatalogsLoaded(false)
          setCatalogs(data.catalogs)
        } else {
          console.log("Data Errors connection")
        }
      }
    } catch (errors) {

      console.log("server error")
    }
  }

  // Filter each time a value change
  useEffect(() => {
    onSubmitFilter()
  }, [formik.values])

  useEffect(() => {
    if (!catalogsLoaded) {
      fetchBrightpearlCatalogs()
    }
  }, []);

  return (
    < AppProvider i18n={esTranslations} >
      <Page
        fullWidth
      >
        <div className='flex justifyEnd mb12 dashboardETA'>
          <FormLayout >
            <FormLayout.Group>
              {/* Only load placeholder while loading as default "all" value clashes with placeholder prop key if both used */}
              <Select
                id={'shipping_method_id'}
                options={catalogs.shippingMethods}
                onChange={handleSelectChange}
                value={formik.values.shipping_method_id}
                placeholder={catalogs.shippingMethods.length > 0 ? undefined : "Método de Envío"}
              />

              <Select
                id={'customer_tag_ids'}
                options={props.customerTagsId}
                onChange={handleSelectChange}
                value={formik.values.customer_tag_ids}
              />

              <Select
                id='shipment_type_id'
                options={props.shipmentTypeOptions}
                onChange={handleSelectChange}
                value={formik.values.shipment_type_id}
              />

              <Select
                id={'sales_channel_id'}
                key={'sales_channel_id'}
                options={catalogs.channels}
                onChange={handleSelectChange}
                value={formik.values.sales_channel_id}
                placeholder={catalogs.shippingMethods.length > 0 ? undefined : "Canales"}
              />

              <Select
                id={'team_id'}
                options={props.teamListId}
                onChange={handleSelectChange}
                value={Number(formik.values.team_id)}
              />

              <Popover
                fixed
                active={popoverActive}
                activator={(
                  <Button onClick={togglePopoverActive} disclosure>

                    {formik.values.range != "CUSTOM_RANGE" ?
                      I18n.t(`date.date_filter.${formik.values.range}`)
                      : calculateDays(formik.values.dateFilters.start, formik.values.dateFilters.end)
                    }
                  </Button>
                )}
                onClose={togglePopoverActive}
                fluidContent={true}
              >
                <Popover.Section>
                  <ActionList
                    items={dateActionListItems}
                  />
                </Popover.Section>
                <Popover.Section>
                  <DatePicker
                    month={month}
                    year={year}
                    onChange={updateDatesPicker}
                    onMonthChange={handleMonthChange}
                    selected={formik.values.dateFilters}
                    allowRange
                  />
                </Popover.Section>
              </Popover>

            </FormLayout.Group>
          </FormLayout>
        </div>

        {/* Added extra padding for some steps that got more vertical space than visible */}
        <Card>
          <Card.Section>
            <div className="pv20">
              <Layout>
                <Layout.Section secondary >
                  <div className="flex alignItemsCenter flexColumn lh-normal">
                    <h1 className=" fs20 weight-bold">Tiempo de Entrega - Mediana</h1>
                    <p className='fs64 weigh-bold'>{deliveryDayStats.avg_delivery_time}</p>
                    <span className="fs20">Días</span>
                  </div>
                </Layout.Section>
                <Layout.Section >
                  <div className="flex alignItemsCenter flexColumn lh-normal">
                    <h1 className="mb40 fs20 weight-bold">Tiempo entre Pasos - Mediana</h1>
                    <div className="timeline-package-wrapper dashboardETA">
                      <div className="timeline-package-info mb32">
                        <div className="orderTimeline flex justifyCenter mt30 dashboardETA">
                          {deliveryDayStats.avg_steps_delivery_time.map((value, i) => {
                            let objectKey = Object.keys(value)
                            objectKey = objectKey[0]
                            return (
                              <div key={i} className="dot-container flex alignItemsCenter false">
                                {i != "0" && <div className="dot-line"><span>{value[objectKey]} {!!value[objectKey] ? `h` : `-`}</span></div>}
                                <div className="dot"></div>
                                <p className="dot-text"> {I18n.t(`shopify.order.timeline.${objectKey}`)}<span className="mb0 date-text display-block"></span></p>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </Layout.Section>
              </Layout>
            </div>
          </Card.Section>
        </Card>

        <Card>
          <div className='resource-table-dashboard-teams'>
            <IndexResourceList
              items={deliveryDayStats.infoTableETA}
              itemURL={null}
              columnsInfo={dashboardETAColumns}
              loading={loading}
            />
          </div>
        </Card>

      </Page>
    </AppProvider >
  )
}
DashboardETA.propTypes = propTypes;
DashboardETA.defaultProps = defaultProps;
export default DashboardETA;