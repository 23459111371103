import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { DescriptionList ,ResourceItem, Avatar, TextStyle, Collapsible, TextContainer, OptionList,
  Button, Badge } from '@shopify/polaris';
  
import { statusVariations, ta } from 'utils';
const propTypes = {
  task: PropTypes.object.isRequired,
  extraActions: PropTypes.oneOfType([PropTypes.func, PropTypes.arrayOf(PropTypes.node),]),
}

const defaultProps = {

}

const taskFields = ["addressFullName", "addressLine1", "addressLine3", "telephone"];

const TaskItem = ({ task, ...props }) => {
  const [open, setOpen] = useState(false);
  const onItemClick = () => setOpen(!open);

  let descriptionItems = [
    { term: "Nombre", description: task.brightpearl_data.name },
    { term: "Dirección", description: task.address },
    { term: "Número de teléfono", description: task.brightpearl_data.phone },
  ];

  if(task.estimated_at){
    descriptionItems = [
      ...descriptionItems,
      { term: ta("task.estimated_at"), description: I18n.l("time.formats.date_only", task.estimated_at) }
    ]
  }

  if(!!task.brightpearl_data?.order_id){
    descriptionItems = [
      ...descriptionItems,
      { term: "Número de Orden", description: task.brightpearl_data.order_reference },
      { term: "ID de Orden", description: task.brightpearl_data.order_id },
      { term: "Productos", description: (
        <OptionList
          title=""
          readOnly
          allowMultiple
          onChange={ () => true }
          options={ task?.brightpearl_data?.order_items || [] }
          selected={ task.item_ids }
        />
      ) },
    ]
  }

  return (
    <ResourceItem
      id={`${task.typename}-${task.id}`}
      onClick={ onItemClick }
    >
      <div className="flex justifyBetween">
        {/* When compact. Everything gets into two flex columns. All info left and Right only status. Non compact has extra left column only for order number */}
        <div>
          <h3>
            <TextStyle variation="strong">
              { task.name }
            </TextStyle>
          </h3>
          <p>
            <TextStyle variation={ statusVariations[task.status] }>
              {I18n.t(`enum.status.${task.status}`)}
            </TextStyle>
          </p>
          <p>
            <TextStyle variation={ "subdued" }>
              {I18n.t(`enum.kind.${task.kind}`)}
            </TextStyle>
          </p>
        </div>
        <div>
          <p>{ task.team && task.team.name }</p>
          <p>{ task.courier && task.courier.name }</p>
        </div>
      </div>

      {/* Collapsible */}
      <Collapsible open={open} id="basic-collapsible">
        <div>
          { descriptionItems.length > 0 ?
              <DescriptionList
                items={descriptionItems}
              />
            :
            <TextContainer>
              Dirección vacía.
            </TextContainer>
          }

          { props.extraActions && props.extraActions(task.id, task) }
        </div>
      </Collapsible>
    </ResourceItem>
  )
};

TaskItem.propTypes = propTypes;
TaskItem.defaultProps = defaultProps;

export default TaskItem;