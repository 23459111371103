import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Card, ResourceItem, Avatar, TextStyle } from '@shopify/polaris';
import OrdersList from '@views/orders/components/OrdersList';

import { ta } from 'utils';

const propTypes = {
  errors: PropTypes.array,
  courier: PropTypes.object.isRequired,
  editURL: PropTypes.string,
  orders: PropTypes.array,
  ordersLoading: PropTypes.bool,
}

const defaultProps = {
  orders: [],
  ordersLoading: false,
}

const CourierCard = ({ courier, ...props }) => (
  <Card
    title={ I18n.t("activerecord.models.courier.one") }
    actions={[
      props.editURL ? { content: I18n.t("actions.view_details"), url: props.editURL, external: true } : {}
    ]}
  >
    <ResourceItem
      id={courier.id}
      external
      media={<Avatar customer size="medium" name={courier.user && courier.user.name} />}
      accessibilityLabel={`View details for ${'courier'}`}
    >
      <h3>
        <TextStyle variation="strong">{courier.user && courier.user.name}</TextStyle>
      </h3>
      <h5>
        { ta("courier.phone") }: { courier.phone }
      </h5>
      <h5>
        { ta("courier.national_id") }: { courier.national_id }
      </h5>
      <h5>
        { ta("courier.uid") }: { courier.uid }
      </h5>
    </ResourceItem>
    { props.orders.length > 0 &&
      <div>
        <Card.Section title="Últimas órdenes">
        </Card.Section>
        <OrdersList items={props.orders} loading={ props.ordersLoading } itemProps={{ compact: true }} />
      </div>
    }
    {/* Last 5 orders */}
  </Card>
)

CourierCard.propTypes = propTypes;
CourierCard.defaultProps = defaultProps;

export default CourierCard;
